import { AgGridReact } from 'ag-grid-react';
import { useFetchingLoader } from '../../store/app/actions';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMealTypeReservations } from '../../store/kitchens/actions';
import agGridLocales from '../../translations/ag-grid';
import { setLayoutOptions } from '../../store/app/actions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
// import { Dialog } from '../../components/Dialogs/SwalDialogs';
// import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import next from '../../assets/images/next.png';
import search from '../../assets/icons/search.png';
import ViewReservationdetails from './viewReservationdetails';
import { useLocation } from 'react-router-dom';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import Pagination from 'react-bootstrap/Pagination';
import http from '../../services/http';
import Toast from 'react-bootstrap/Toast';
import Excel from 'exceljs';
import cancel from '../../assets/images/close.svg';
import { Modal, Box, styled, Typography, Paper } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
  minHeight: '82.5vh',
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ByMealTypeReservationManagement = ({
  kitchen,
  reservations,
  getMealTypeReservations,
  totalReservationsCount,
  useFetchingLoader,
  // setLayoutOptions,
  currentLanguage,
  getPaymentPreferences,
  companyName,
}) => {
  const { t, i18n } = useTranslation();

  const location = useLocation<any>();
  //const state = location.state as CustomizedState;

  const [reserData, setReserData] = useState([{}]);
  const [, setMaxDate] = useState<any>(false);
  // const [viewData, setViewData] = useState(false);
  const [gridApi, setGridApi] = useState<any>();
  const [, setColumnApi] = useState<any>();
  // const [jsonData, setJsondata] = useState();
  const [tableColumn, setTableColumn] = useState<any>();
  const [details, setDetails] = useState<any>();
  const [viewDetails, setViewDetails] = useState<any>();
  const [dummy] = useState<any>();
  const { mealDate } = location?.state ?? {};

  const [fromDate, setFromdate] = useState<any>(
    mealDate ? new Date(mealDate) : new Date()
  );
  const [fromDateFormat, setfromDateFormat] = useState(
    mealDate ? new Date(mealDate) : new Date()
  );
  const [toDateFormat, settoDateFormat] = useState(
    mealDate ? new Date(mealDate) : new Date()
  );
  const [toDate, setToDate] = useState<any>(
    mealDate ? new Date(mealDate) : new Date()
  );
  const [filterOrder, setFilterData] = useState<any>();
  const [showPaymentOption, setShowPaymentOption] = useState();

  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs] = useState(25); // Records per page
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [columnAndOrder, setColumnAndOrder] = useState<any>({});
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<any>();
  const [isSeparateFiles, setIsSeparateFiles] = useState<boolean>(false);

  const [isOpenReportsHistory, setIsOpenReportHistory] = useState(false);
  const [reportsHistory, setReportsHistory] = useState([]);

  const fetchDataHistory = async () => {
    const data = await http.get('activityLogs/getByParams', {
      companyId: 1,
      tableName: 'reservationByMeal',
      kitchenId: kitchen.id,
    });
    setReportsHistory(data);
  };
  useEffect(() => {
    fetchDataHistory();
  }, [isOpenReportsHistory]);

  useEffect(() => {
    if (kitchen) {
      // useFetchingLoader(async () => {
      const data = getPaymentPreferences(kitchen?.id, '');
      setShowPaymentOption(data.selectedPaymentOptions);
      // });
    }
  }, [kitchen]);

  useEffect(() => {
    if (reservations) {
      setpageCountsLogs(Math.ceil(totalReservationsCount / newPageSizeLogs));
    }
  }, [reservations]);

  useEffect(() => {
    if (reservations) {
      setpageCountsLogs(Math.ceil(totalReservationsCount / newPageSizeLogs));
    }
  }, []);

  useEffect(() => {
    if (reservations && !filterOrder) {
      gridApi?.setRowData(reservations || []);
      setReserData(reservations || []);
      if (searchText) {
        gridApi?.setQuickFilter(searchText);
      }
    } else {
      let filter = reservations.filter(
        (el: any) =>
          el?.orderStatusId === 1 ||
          el?.orderStatusId === 2 ||
          el?.orderStatusId === 3
      );
      setReserData(filter);
      gridApi?.setRowData(filter);
      if (searchText) {
        gridApi?.setQuickFilter(searchText);
      }
    }
  }, [reservations, dummy]);

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };

  const handleFilterChanged = (e) => {
    const value = e.target.value;
    setSearchText(value);
    gridApi.setQuickFilter(value);
  };
  const lang = localStorage.getItem('language');
  const formatTimeObject = (obj) => {
    const units = {
      years: 'year',
      months: 'month',
      weeks: 'week',
      days: 'day',
      hours: 'hour',
      minutes: 'minute',
      seconds: 'second',
    };
    const result = Object.entries(units)
      .map(([key, label]) => {
        const value = obj[key];
        if (value > 0) {
          return `${value} ${label}${value > 1 ? 's' : ''}`;
        }
        return null;
      })
      .filter(Boolean)
      .join(' ');

    return result;
  };
  const calcTimeBetween = (diffInMs) => {
    const formattedDiff = moment.duration(diffInMs) as any;
    return formatTimeObject(formattedDiff?._data);
  };
  const columnTotalMealTypeDefs = [
    {
      field: 'display',
      headerName: `${t('mealTypeName')}`,
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'inventory',
      headerName: ` ${t('totalOfInventory')}`,
      sortable: true,
      comparator: () => {},
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'ordered',
      headerName: `${t('ofreservations')}`,
      sortable: true,
      comparator: () => {},
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'realized',
      headerName: `${t('realized')}`,
      sortable: true,
      comparator: () => {},
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'timeToMeal',
      headerName: t('timeToMeal'),
      sortable: false,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.averageDiffMs
          ? `${calcTimeBetween(Number(params?.data?.averageDiffMs))}`
          : '--';
      },
    },
    {
      field: 'reservedInventory',
      headerName: ` ${t('perceReservedInventory')}`,
      resizable: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.reservedInventory} %`;
      },
    },
    {
      field: 'realizedInventory',
      headerName: ` ${t('ofRealisedInventory')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.realizedInventory} %`;
      },
    },
    {
      field: 'realizedReserved',
      headerName: ` ${t('ofRealizedReserved')}`,
      resizable: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.realizedReserved} %`;
      },
    },
    {
      field: 'creditPoints',
      headerName: ` ${t('totalCreditPointRequest')}`,
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'pending',
      headerName: ` ${t('pendingCreditPointRequest')}`,
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'approval',
      headerName: ` ${t('approvedCreditPointReqquest')}`,
      resizable: true,
      suppressMovable: true,
    },
    {
      field: 'percent',
      headerName: ` ${t('creditPointPercentage')}`,
      resizable: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.percent} %`;
      },
    },
  ];

  const mobcolumnTotalMealTypeDefs = [
    {
      field: 'display',
      headerName: `${t('mealTypeName')}`,
      suppressMovable: true,
    },
    {
      field: 'inventory',
      headerName: ` ${t('totalOfInventory')}`,
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [columnAndOrder, currentPageAllLogs]);

  const handleChange = async (name: any, value: any) => {
    setFilterData(false);
    if (name === 'fromdate') {
      if (!value) {
        setFromdate(null);
      }
      let maxDate = new Date();
      setMaxDate(moment(maxDate).format('YYYY-MM-DD'));
      setfromDateFormat(value);
      setFromdate(new Date(value));
      fetchData(
        moment(value).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD')
      );
    } else if (name === 'todate') {
      if (!value) {
        setToDate(null);
      }
      const tempToDate = new Date(value);
      settoDateFormat(value);
      setToDate(tempToDate);
      fetchData(
        moment(fromDate).format('YYYY-MM-DD'),
        moment(tempToDate).format('YYYY-MM-DD')
      );
    }
  };

  const fetchData = async (fromDateParam?: any, toDateParam?: any) => {
    useFetchingLoader(async () => {
      await getMealTypeReservations(
        kitchen?.id,
        fromDateParam ??
          moment(fromDate).format('YYYY-MM-DD') ??
          new Date().toISOString().split('T')[0],
        toDateParam ??
          moment(toDate).format('YYYY-MM-DD') ??
          new Date().toISOString().split('T')[0],
        currentPageAllLogs,
        newPageSizeLogs,
        'field' in columnAndOrder ? columnAndOrder?.field : '',
        'order' in columnAndOrder ? columnAndOrder?.order : ''
      );
    });
  };
  /***********Export CSV file */
  const onBtnExport = async (exportCsvFileNames) => {
    try {
      if (disableButton || reservations.length === 0) {
        console.log('Disabled or empty table', exportCsvFileNames);
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        const arr = [
          'mealTypeName',
          'totalOfInventory',
          'ofreservations',
          'realized',
          'timeToMeal',
          'perceReservedInventory',
          'ofRealisedInventory',
          'ofRealizedReserved',
          'totalCreditPointRequest',
          'pendingCreditPointRequest',
          'approvedCreditPointReqquest',
          'creditPointPercentage',
        ];
        const resArr = arr.map((el) => t(el));
        const res = await http.post(
          `kitchens/${kitchen?.id}/getReportMealsTypeReservationsFile/download`,
          {
            isReport: true,
            headers: resArr,
            filename: exportCsvFileNames,
            isFile: false,
            isSeparateFiles: isSeparateFiles,
          },
          {
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            page: currentPageAllLogs,
            limit: newPageSizeLogs,
          }
        );
        if (isSeparateFiles) {
          Object.entries(res.data).forEach(async (elem: Array<any>) => {
            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet('Sheet1');
            worksheet.addRows(elem[1]);
            const uint8Array = await workbook.xlsx.writeBuffer();
            const blob = new Blob([uint8Array], {
              type: 'application/octet-binary',
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${exportCsvFileNames}-byDate-${elem[0]}.xlsx`;
            a.click();
            a.remove();
          });
        } else {
          const workbook = new Excel.Workbook();
          const worksheet = workbook.addWorksheet('Sheet1');
          worksheet.addRows(res.data);
          const uint8Array = await workbook.xlsx.writeBuffer();
          const blob = new Blob([uint8Array], {
            type: 'application/octet-binary',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${exportCsvFileNames}.xlsx`;
          a.click();
          a.remove();
        }

        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  /*const handleViewData = (id: any) => {
    setViewData(!viewData);
    setJsondata(id);
  };*/

  const gridOptions = {
    onFilterChanged: () => {},
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setTableColumn(mobcolumnTotalMealTypeDefs);
    } else {
      setTableColumn(columnTotalMealTypeDefs);
    }
  }, [showPaymentOption]);

  const handleviewmore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };
  return (
    <>
      <div className="reservation-management global-data-table reservation-updated-page">
        <div className="container">
          <div className="add-employee-btn">
            <div className="left-filter left-filter-mob w-75">
              <div className="filter-section">
                <div className="search-filter">
                  <div className="xyz">
                    <div className="from-date">
                      <label>{t('fromDate')}</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={
                          fromDateFormat
                            ? fromDateFormat
                            : mealDate
                              ? new Date(mealDate)
                              : new Date()
                        }
                        name="fromDate"
                        onChange={(event) => handleChange('fromdate', event)}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                    <div className="from-date">
                      <label>{t('toDate')}</label>

                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={
                          toDateFormat
                            ? toDateFormat
                            : mealDate
                              ? new Date(mealDate)
                              : new Date()
                        }
                        name="toDate"
                        onChange={(event) => handleChange('todate', event)}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-input searchBtn">
                <label>{t('search')}</label>
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={t('freeTextSearch')}
                  onInput={handleFilterChanged}
                />
                <img src={search} alt="search" />
              </div>
            </div>

            <div>
              <div className="active-button mw-172px">
                <div className="total-data">
                  <p>
                    {t('totalData')}: <span>{totalReservationsCount}</span>
                  </p>
                </div>
                <div className="actions">
                  <i
                    style={{ cursor: 'pointer' }}
                    className="las la-file-alt"
                    onClick={() => {
                      onBtnExport(
                        `${companyName}-${kitchen.name}-${t(
                          'totalByMeals'
                        )}-${moment(fromDate).format('YYYY-MM-DD')}-${moment(
                          toDate
                        ).format('YYYY-MM-DD')}`
                      );
                    }}
                  ></i>

                  <i
                    style={{ cursor: 'pointer' }}
                    className="las la-book"
                    onClick={() => {
                      setIsOpenReportHistory(true);
                    }}
                  ></i>
                </div>
              </div>
              <div className="checkboxes-wrapper mb-0 mt-0">
                <FormControlLabel
                  className="default-flex-class-for-checkbox"
                  control={
                    <Checkbox
                      checked={isSeparateFiles}
                      onChange={() => setIsSeparateFiles((prev) => !prev)}
                      name="separateFiles"
                    />
                  }
                  label={t('separateFiles')}
                />
              </div>
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={
                reserData.length
                  ? reserData.map((reservation) => ({
                      ...reservation,
                      kitchen,
                    }))
                  : []
              }
              onSortChanged={() => printSortStateToConsole()}
              columnDefs={tableColumn}
              localeText={agGridLocales[i18n.language]}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onGridColumnsChanged={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              frameworkComponents={{
                ViewMore: (e) => (
                  <ViewMore handleviewmore={handleviewmore} e={e} />
                ),
              }}
              reactUi={true}
              enableRtl={lang === '"he"' ? true : false}
              pagination={false}
              paginationPageSize={50}
              overlayLoadingTemplate={'<p></p>'}
              paginationNumberFormatter={function (params) {
                return params.value.toLocaleString();
              }}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                <p>
                  {newPageSizeLogs * (currentPageAllLogs - 1) + 1} to{' '}
                  {newPageSizeLogs * currentPageAllLogs} of{' '}
                  {totalReservationsCount}
                </p>
              </div>
              <Pagination>
                <Pagination.First
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(1);
                  }}
                />
                <Pagination.Prev
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs > 1
                      ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                      : '';
                  }}
                />

                <Pagination.Item active>
                  Page {currentPageAllLogs} of{' '}
                  {Math.ceil(totalReservationsCount / newPageSizeLogs)}
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs <
                    Math.ceil(totalReservationsCount / newPageSizeLogs)
                      ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                      : '';
                  }}
                />
                <Pagination.Last
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(
                      Math.ceil(totalReservationsCount / newPageSizeLogs)
                    );
                  }}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOpenReportsHistory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={'view-more-popup view-employee-popup'}
        onClose={() => setIsOpenReportHistory(false)}
      >
        <Box sx={style}>
          <div className="view-detail-head">
            <div className="reservation-title">
              <h5>{t('reportsHistory')}</h5>
            </div>
            <div className="close-btn-view">
              <img
                src={cancel}
                alt="close"
                onClick={() => {
                  setIsOpenReportHistory(false);
                }}
              />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <div className={`view-more-popup`}>
              {reportsHistory && reportsHistory.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className="audit-table-container"
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{`${t('date')} ${t('time')}`}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('user')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('message')}</strong>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportsHistory.map((el: any) => (
                        <StyledTableRow
                          key={el.id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell className="p-2">
                            <p className="audit-details-text">
                              {moment(el?.createdAt).format('YYYY-MM-DD')}
                            </p>
                            <p className="audit-details-text">
                              {moment(el?.createdAt).format('hh:mm:ss  A')}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">
                              {`${el?.userfirstName} ${el?.userlastName}`}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">{el.message}</p>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                  <Typography className="history-text-styles fs-5">
                    {t('emptyHistoryMess')}
                  </Typography>
                </div>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>
      <ViewReservationdetails
        open={viewDetails}
        details={details}
        handleClose={handleviewmore}
        reportType={'MealType'}
        handleOpen={() => {}}
        reserData={reserData}
        setDetails={setDetails}
        handleCancelOrder={() => {}}
        currentLanguage={currentLanguage}
        setChangePaymentStatus={() => {}}
        setChangedPaymentParams={() => {}}
        setRefresh={() => {}}
      />
      <Toast
        onClose={() => setOpenDownload(false)}
        show={openDownload}
        style={{
          position: 'sticky',
          right: '20px',
          bottom: '70px',
        }}
      >
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
            onClick={() => setOpenDownload(false)}
          />
        </Toast.Header>
        <Toast.Body>{t('downloadFile')}</Toast.Body>
      </Toast>
    </>
  );
};
const ViewMore = ({ e, handleviewmore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleviewmore(e.data)} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
  currentLanguage: state.languages.current,
  currentUser: state.user,
  totalReservationsCount: state.kitchens.totalReservations,
  companyName: state.companyConfig.companyName,
});

const mapDispatchToProps = {
  getMealTypeReservations,
  setLayoutOptions,
  useFetchingLoader,
  getPaymentPreferences,
};

// export default ReservationManagement;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ByMealTypeReservationManagement);
