import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  styled,
  Typography,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { ActiveButton } from '../../components/WE-Button';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import http from '../../services/http';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import { validateSlotType, checkDate } from '../../services/validators';
import cancel from '../../assets/images/close.svg';

const style = {
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px',
  p: 4,
  borderRadius: '5px',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(event) => cb(event.target.value)}
        required
        type={type}
      />
    </>
  );
};
const GetTextareaField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'transparent',
        fontSize: '1em',
        color: '#333333',
      }}
    >
      <Typography
        style={{
          fontSize: 12,
          color: '#23283c',
          lineHeight: '18px',
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>

      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '4px',
          transition: 'border-color 0.3s ease',
          maxHeight: '110px',
          '&:focus-within': {
            border: '2px solid #14cd31',
          },
        }}
      >
        <TextareaAutosize
          name={placeholder}
          placeholder={placeholder}
          minRows={1}
          maxRows={3}
          style={{
            maxHeight: '100px',
            width: '100%',
            padding: '16px 14px',
            border: 'none',
            outline: 'none',
            margin: '0',
          }}
          value={value}
          onChange={(event) => cb(event.target.value)}
        />
      </Box>
    </Box>
  );
};
const MealTypeReminder = ({
  open,
  handleClose,
  t,
  mealTypeReminderValues,
  useFetchingLoader,
  mealTypeId,
  isEditReminder,
  setDummy,
}) => {
  const [mealTypesReminderParam, setMealTypesReminderParam] = useState(
    mealTypeReminderValues
  );
  useEffect(() => {
    if (open) {
      setMealTypesReminderParam(mealTypeReminderValues);
    }
    console.log('mealTypeReminderValues', mealTypeReminderValues);
  }, [open]);
  const handleChange = (name, value) => {};
  const handleItem = () => {};

  const handleFormSubmit = async (params) => {
    try {
      await http.post('mealTypes/createReminder', {
        ...mealTypesReminderParam,
        mealTypeId,
      });
      setTimeout(() => {
        Dialog({
          title: t('addedSuccessfully'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: async () => {
            setDummy((prev) => !prev);
            handleClose();
          },
        });
      }, 150);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const handleUpdate = async (params) => {
    console.log('mealTypesReminderParam', params);
    try {
      await http.put(`mealTypes/updateReminder/${params.id}`, {
        ...params,
        mealTypeId,
      });
      setTimeout(() => {
        Dialog({
          title: t('updatedMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: async () => {
            setDummy((prev) => !prev);
            handleClose();
          },
        });
      }, 150);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  return (
    <Modal
      show={open}
      onHide={() => handleClose()}
      centered
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div className="close-btn" style={{ padding: 0 }}>
          <img
            src={cancel}
            alt="close"
            onClick={() => handleClose()}
            style={{ top: 5 }}
          />
        </div>
        <div
          className="top-header"
          style={{ marginBottom: 25, fontWeight: 600, fontSize: 20 }}
        >
          {t(isEditReminder ? 'updateReminder' : 'createReminder')}
        </div>
        <div id="modal-modal-description">
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
          >
            <div className="item" style={{ gap: 15 }}>
              <div className="input-field">
                <label>{t('isActiveReminder')}</label>
                <div
                  className="language-input toggle-mealtype"
                  style={{ marginTop: 10 }}
                >
                  <button
                    type="button"
                    className={`toggle-btn ${
                      mealTypesReminderParam?.isJobEnabled
                        ? 'toggle-btn-on'
                        : 'toggle-btn-off'
                    }`}
                    onClick={() =>
                      setMealTypesReminderParam((prev) => ({
                        ...prev,
                        isJobEnabled: !prev?.isJobEnabled,
                      }))
                    }
                  >
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="item" style={{ gap: 15 }}>
              <div
                className="input-field"
                style={{ alignItems: 'center', flex: 1 }}
              >
                <GetInputField
                  placeholder={t('time')}
                  label={t('time')}
                  name="maxReservations"
                  type="number"
                  value={mealTypesReminderParam?.time}
                  cb={(value) => {
                    setMealTypesReminderParam((prev) => ({
                      ...prev,
                      time: value,
                    }));
                  }}
                  required
                />
              </div>
              <div className="input-field">
                <label>{t('isSendToEmail')}</label>
                <div
                  className="language-input toggle-mealtype"
                  style={{ marginTop: 10 }}
                >
                  <button
                    type="button"
                    className={`toggle-btn ${
                      mealTypesReminderParam?.isSendToEmail
                        ? 'toggle-btn-on'
                        : 'toggle-btn-off'
                    }`}
                    onClick={() =>
                      setMealTypesReminderParam((prev) => ({
                        ...prev,
                        isSendToEmail: !prev?.isSendToEmail,
                      }))
                    }
                  >
                    <span></span>
                  </button>
                </div>
              </div>
              <div className="input-field">
                <label>{t('isSendBySMS')}</label>
                <div
                  className="language-input toggle-mealtype"
                  style={{ marginTop: 10 }}
                >
                  <button
                    type="button"
                    className={`toggle-btn ${
                      mealTypesReminderParam?.isSendBySMS
                        ? 'toggle-btn-on'
                        : 'toggle-btn-off'
                    }`}
                    onClick={() =>
                      setMealTypesReminderParam((prev) => ({
                        ...prev,
                        isSendBySMS: !prev?.isSendBySMS,
                      }))
                    }
                  >
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="input-field">
              <GetTextareaField
                placeholder={t('message')}
                label={t('message')}
                name="message"
                type="number"
                value={mealTypesReminderParam?.message}
                cb={(value) => {
                  setMealTypesReminderParam((prev) => ({
                    ...prev,
                    message: value,
                  }));
                }}
                required
              />
            </div>
          </div>
          <div className="addft-btn">
            {!isEditReminder ? (
              <div
                onClick={() => handleFormSubmit(mealTypesReminderParam)}
                className="add-btn"
              >
                <ActiveButton label={t('add')} arrow={false} />
              </div>
            ) : (
              <div
                onClick={() => handleUpdate(mealTypesReminderParam)}
                className="add-btn"
              >
                <ActiveButton label={'Update'} arrow={false} />
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default MealTypeReminder;
