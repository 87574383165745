import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { DisabledButton } from '../../components/WE-Button';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import JSONFormatter from 'json-formatter-js';
import moment from 'moment';
import http from '../../services/http';
import { useFetchingLoader } from '../../store/app/actions';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
  minHeight: '82.5vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ViewReservationDetails = ({
  kitchen,
  kitchens,
  reservations,
  MealTypes,
  Languages,
  open,
  details,
  handleClose,
  kitchenParam = '',
  reportType,
  handleOpen,
  reserData,
  setDetails,
  handleCancelOrder,
  currentUser,
  currentLanguage,
  useFetchingLoader,
  setRefresh = {},
  refresh = false,
  fromDate = '',
  toDate = '',
  reservationType = '',
  attendanceEnabled = true,
  mealTypes = [],
  fieldNamesSettings,
  PaymentOption = [],
  OrderStatuses = [],
  receipts = [],
  setChangePaymentStatus,
  setChangedPaymentParams,
  getReceipts = () => {},
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [viewData, setViewData] = useState(false);
  const [jsonData, setJsondata] = useState();
  const [tranId, setTranId] = useState(undefined);
  const [isExp, setIsExp] = useState(false);
  const [kitchenFilter, setKitchenFilter] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [dishesForTable, setDishesForTable] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [phoneNumberFromPayment, setPhoneNumberFromPayment] = useState('');
  const [realizeCreateDiff, setRealizeCreateDiff] = useState('');
  const [pickupCreateDiff, setPickupCreateDiff] = useState('');
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  useEffect(() => {
    const fetchData1 = async () => {};
    if (details?.id) {
      fetchData1();
    }
  }, [open]);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  useEffect(() => {
    if (
      open &&
      reservationType !== 'Credit points' &&
      reportType === 'DetailedReservations'
    ) {
      setDishesForTable(
        details.mealDish[0].mealDish.dishes.map((el, id) => {
          return {
            quantity: el.quantity,
            price: el.price,
            name: el.translations[
              localStorage.getItem('languageId') ??
                Languages.find(
                  (el) => el.value === localStorage.getItem('defaultLang')
                )?.id
            ],
          };
        })
      );
    }
  }, [open]);
  const formatTimeObject = (obj) => {
    const units = {
      years: 'year',
      months: 'month',
      weeks: 'week',
      days: 'day',
      hours: 'hour',
      minutes: 'minute',
      seconds: 'second',
    };
    const result = Object.entries(units)
      .map(([key, label]) => {
        const value = obj[key];
        if (value > 0) {
          return `${value} ${label}${value > 1 ? 's' : ''}`;
        }
        return null;
      })
      .filter(Boolean)
      .join(' ');

    return result;
  };
  const calcTimeBetween = (firstTime, secondTime) => {
    const diffInMs = moment(firstTime, 'YYYY-MM-DD HH:mm:ss').diff(
      moment(secondTime, 'YYYY-MM-DD HH:mm:ss')
    );
    const formattedDiff = moment.duration(diffInMs);
    return formatTimeObject(formattedDiff?._data);
  };
  useEffect(() => {
    console.log('details', details);

    const fetchData = async () => {
      await useFetchingLoader(async () => {
        await getReceipts('orderId', details?.id);
        const data = await http.get('audit/getByParams', {
          filter: {
            entity:
              reservationType !== 'Credit points' ? 'order' : 'collectPoints',
            actionEntityId: details?.id,
          },
        });
        const data2 = await http.get('activityLogs/getByParamsForOrders', {
          companyId: 1,
          tableName: 'transactions',
          kitchenId: kitchen.id,
          orderId: details.id,
        });

        setTransactionHistory(
          data2
            .filter(
              (el) =>
                JSON.parse(el.reqParam)?.orderId === details.id ||
                JSON.parse(el.reqParam)?.orderID === details.id
            )
            .sort((a, b) => a?.id - b?.id)
        );
        setHistoryData(data.data);
        const phoneFromPayment = details?.chargeResponse
          ? JSON.parse(details?.chargeResponse)?.phone_number
          : '';
        setPhoneNumberFromPayment(phoneFromPayment);
      });
    };
    if (details?.id) {
      console.log('details', details);
      setRealizeCreateDiff(
        calcTimeBetween(
          details?.realizeDate ??
            details?.mealDish[0].mealDish.meal.date +
              ' ' +
              details?.mealDish[0].mealDish.meal.mealType.to +
              ':00',
          details?.createdAt
        )
      );
      if (details?.pickupTime) {
        setPickupCreateDiff(
          calcTimeBetween(
            details?.mealDish[0].mealDish.meal.date +
              ' ' +
              details?.pickupTime +
              ':00',
            details?.createdAt
          )
        );
      }
      fetchData();
    }
  }, [details]);
  useEffect(() => {
    let filter = reserData?.filter((el) => el.id === details?.id);
    setDetails(filter[0]);
  }, [reserData]);
  useEffect(() => {
    if (kitchenParam) {
      setKitchenFilter(kitchens.options.find((el) => el.id == kitchenParam));
    }
  }, [kitchens, kitchenParam, details]);
  useEffect(() => {
    if (details && Object.keys(details).length !== 0 && details?.mealDish) {
      const dateNow = new Date();
      const expDate = new Date(
        details?.mealDish[0]?.mealDish?.meal?.date +
          'T' +
          details?.mealDish[0]?.mealDish?.meal?.mealType?.to
      );
      setIsExp(expDate < dateNow);
    }
  }, [details]);
  useEffect(() => {
    let data;
    if (
      details?.chargeResponse &&
      'additionalSettlementInfo' in JSON.parse(details?.chargeResponse)
    ) {
      setTranId(
        JSON.parse(JSON.parse(details?.chargeResponse).additionalSettlementInfo)
          ?.tranId
      );
    } else {
      if (details?.chargeResponse) {
        setTranId(JSON.parse(details?.chargeResponse)?.tranId);
      }
    }
  }, [details]);

  const getMealDish = (params) => {
    let dataArray = params;
    let finalData = '';
    if (dataArray?.length > 0) {
      finalData = dataArray?.map((ele) => {
        return `${ele.translations[currentLanguage.id]}`;
      });
      return finalData?.toString();
    }
  };

  const handleViewData = (id) => {
    setViewData(!viewData);
    setJsondata(id);
  };

  const filterMealType = MealTypes.filter(
    (el) => el.kitchenId == kitchenParam ?? kitchen.id
  );

  const deleteCreditPoint = async (param) => {
    try {
      const getResponseInfo = await useFetchingLoader(
        async () => await http.delete(`users/deleteCreditPointByAdmin`, param)
      );
      if (getResponseInfo.status === 200) {
        setTimeout(() => {
          SwalDialogs.Dialog({
            title: `${t('cancellOrder')}`,
            type: 'success',
            buttons: {
              showCancelButton: false,
              showConfirmButton: true,
            },
            onConfirm: () => {
              handleClose();
              setRefresh(!refresh);
            },
          });
        }, 150);
      }
    } catch (err) {
      setTimeout(() => {
        Alert({ error: err });
      }, 150);
    }
  };
  const handleMarkAsPickup = async (orderId) => {
    try {
      const getResponseInfo = await useFetchingLoader(async () => {
        await useFetchingLoader(
          async () => await http.put(`orders/markAsPickup/${orderId}`)
        );
      });
      setTimeout(() => {
        SwalDialogs.Dialog({
          title: `${t('statusWasChanged')}`,
          type: 'success',
          buttons: {
            showCancelButton: false,
            showConfirmButton: true,
          },
          onConfirm: () => {
            handleClose();
            setRefresh(!refresh);
          },
        });
      }, 150);
    } catch (err) {
      setTimeout(() => {
        Alert({ error: err });
      }, 150);
    }
  };
  const handleUnMarkAsPickup = async (orderId) => {
    try {
      const getResponseInfo = await useFetchingLoader(async () => {
        await useFetchingLoader(
          async () => await http.put(`orders/unmarkAsPickup/${orderId}`)
        );
      });
      setTimeout(() => {
        SwalDialogs.Dialog({
          title: `${t('statusWasChanged')}`,
          type: 'success',
          buttons: {
            showCancelButton: false,
            showConfirmButton: true,
          },
          onConfirm: () => {
            handleClose();
            setRefresh(!refresh);
          },
        });
      }, 150);
    } catch (err) {
      setTimeout(() => {
        Alert({ error: err });
      }, 150);
    }
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className={'view-more-popup view-employee-popup'}
      onHide={() => {
        setTabIndex(0);
      }}
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <div className="reservation-title">
            <h5>
              {t('reservation')}
              {details?.id ? t('reservationNumber') : ''}
              {details?.id}
            </h5>
            <h6
              onClick={() => {
                details?.user?.email ||
                (details?.user?.lastName && details?.user?.firstName)
                  ? history.push(
                      `/userManagement?userEmail=${
                        details?.user?.email
                          ? details?.user?.email
                          : details?.user?.firstName +
                            ' ' +
                            details?.user?.lastName
                      }`
                    )
                  : '';
              }}
              class={details?.user ? 'link-css' : ''}
            >
              {details?.user
                ? details?.user?.firstName && details?.user?.lastName
                  ? details?.user?.firstName + ' ' + details?.user?.lastName
                  : details?.user?.firstName ||
                    details?.user?.lastName ||
                    details?.user?.email ||
                    details?.user?.phone ||
                    details?.user?.employeeId
                : phoneNumberFromPayment || ''}
            </h6>
          </div>
          <div className="close-btn-view">
            <img
              src={cancel}
              alt="close"
              onClick={() => {
                setTabIndex(0);
                handleClose();
              }}
            />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div
            className={`view-more-popup ${
              reportType === 'DetailedReservations'
                ? ''
                : 'view-reservation-page'
            }`}
          >
            {reportType === 'DetailedReservations' && (
              <>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={tabIndex}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                    >
                      <Tab label={t('mainInfo')} {...a11yProps(0)} />
                      {reservationType !== 'Credit points' && (
                        <Tab label={t('dishes')} {...a11yProps(1)} />
                      )}
                      <Tab label={t('history')} {...a11yProps(2)} />
                      {reservationType !== 'Credit points' && (
                        <Tab
                          label={t('transactionHistory')}
                          {...a11yProps(3)}
                        />
                      )}
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabIndex} index={0}>
                    <div className="user-detail-container reservation-content">
                      <div className="user-details-row">
                        <div className="user-details-block">
                          <h6>{t('orderDate')}:</h6>
                          <p>
                            {moment(details?.createdAt).format(
                              'DD/MM/YYYY HH:mm'
                            )}
                          </p>
                        </div>
                        {/* <div className="user-details-block">
                      <h6>{t('name')}:</h6>
                      <p>
                        {details?.user
                          ? details?.user?.firstName +
                            ' ' +
                            details?.user?.lastName
                          : '--'}
                      </p>
                    </div> */}
                        <div className="user-details-block">
                          <h6>{t('mealDate')}:</h6>
                          <p>
                            {details?.meal?.date
                              ? details?.meal?.date
                              : details?.mealDish?.[0]?.mealDish?.meal?.date
                                ? details?.mealDish?.[0]?.mealDish?.meal?.date
                                : '--'}
                          </p>
                        </div>
                        <div className="user-details-block">
                          <h6>{t('kitchenName')}:</h6>
                          <p>
                            {details?.kitchen?.id &&
                            kitchens.options.find(
                              (el) => el?.id === details?.kitchen?.id
                            )
                              ? kitchens.options.find(
                                  (el) => el?.id === details?.kitchen?.id
                                ).name
                              : '--'}
                          </p>
                        </div>
                        <div className="user-details-block">
                          <h6>{t('mealType')}:</h6>
                          {details?.point ? (
                            <p>
                              {mealTypes?.find(
                                (mealType) =>
                                  mealType.id === details?.mealType?.id
                              ) &&
                              'mealtype|reportName|translation' in
                                mealTypes?.find(
                                  (mealType) =>
                                    mealType.id === details?.mealType?.id
                                ) &&
                              mealTypes?.find(
                                (mealType) =>
                                  mealType.id === details?.mealType?.id
                              )['mealtype|reportName|translation']?.text
                                ? mealTypes?.find(
                                    (mealType) =>
                                      mealType.id === details?.mealType?.id
                                  )['mealtype|reportName|translation']?.text
                                : mealTypes?.find(
                                    (mealType) =>
                                      mealType.id === details?.mealType?.id
                                  )?.display}
                            </p>
                          ) : (
                            <p>
                              {mealTypes?.find(
                                (mealType) =>
                                  mealType.id ===
                                  details?.mealDish?.[0]?.mealDish?.meal
                                    ?.mealTypeId
                              ) &&
                              'mealtype|reportName|translation' in
                                mealTypes?.find(
                                  (mealType) =>
                                    mealType.id ===
                                    details?.mealDish?.[0]?.mealDish?.meal
                                      ?.mealTypeId
                                ) &&
                              mealTypes?.find(
                                (mealType) =>
                                  mealType.id ===
                                  details?.mealDish?.[0]?.mealDish?.meal
                                    ?.mealTypeId
                              )['mealtype|reportName|translation']?.text
                                ? mealTypes?.find(
                                    (mealType) =>
                                      mealType.id ===
                                      details?.mealDish?.[0]?.mealDish?.meal
                                        ?.mealTypeId
                                  )['mealtype|reportName|translation']?.text
                                : MealTypes?.find(
                                    (mealType) =>
                                      mealType.id ===
                                      details?.mealDish?.[0]?.mealDish?.meal
                                        ?.mealTypeId
                                  )?.display || ''}
                            </p>
                          )}
                        </div>
                        <div className="user-details-block">
                          <h6>{t('dishName')}:</h6>
                          <p>
                            {details?.point
                              ? t('pendingCollectedPoint')
                              : getMealDish(
                                  details?.mealDish
                                    ? details?.mealDish[0]?.mealDish?.dishes
                                    : []
                                )}
                          </p>
                        </div>
                        <div className="user-details-block">
                          <h6>{t('isChefMealDish')}:</h6>
                          <p>
                            {details?.orderMealDishes
                              ? Boolean(
                                  details?.orderMealDishes.find(
                                    (el) => el.mealDish.isChefMealDish
                                  )
                                )
                                ? t('yes')
                                : t('no')
                              : '--'}
                          </p>
                        </div>
                        <div className="user-details-block">
                          <h6>{t('price')}:</h6>
                          <p>
                            {details?.point
                              ? details?.point
                              : details?.paymentMethod === 'Wallet'
                                ? details?.orderPrice
                                : '₪' + details?.orderPrice}
                          </p>
                        </div>
                        {reservationType !== 'Credit points' && (
                          <div className="user-details-block">
                            <h6>{t('quantity')}:</h6>
                            <p>
                              {details?.mealDish &&
                              Array.isArray(details?.mealDish)
                                ? details?.mealDish[0].mealDish.dishes.reduce(
                                    (acc, curr) => {
                                      return acc + curr.quantity;
                                    },
                                    0
                                  )
                                : ' '}
                            </p>
                          </div>
                        )}
                        <div className="user-details-block">
                          <h6>{t('contractorName')}:</h6>
                          <p>
                            {details?.user?.contractors.length !== 0
                              ? details?.user?.contractors
                                  .map((elem) => elem.name)
                                  .join(', ')
                              : '--'}
                          </p>
                        </div>
                        {(details?.paymentOptionId === 3 ||
                          details?.paymentMethod === 'Credit Card') && (
                          <>
                            <div className="user-details-block">
                              <h6>{t('cardLastDigits')}:</h6>
                              <p>
                                {details?.last4Digits
                                  ? details?.last4Digits
                                  : details?.chargeResponse &&
                                    JSON.parse(
                                      details?.chargeResponse
                                    )?.pan?.substr(-4)}
                              </p>
                            </div>
                            {tranId && (
                              <div className="user-details-block">
                                <h6>{t('transactionId')}:</h6>
                                <p>{tranId}</p>
                              </div>
                            )}
                          </>
                        )}
                        {(details?.paymentOptionId === 6 ||
                          details?.paymentMethod === 'Cibus') && (
                          <div className="user-details-block">
                            <h6>{t('cardLastDigits')}:</h6>
                            <p>
                              {details?.user?.magneticStripe
                                ? details?.user?.magneticStripe?.substr(-4)
                                : '--'}
                            </p>
                          </div>
                        )}
                        <div className="user-details-block">
                          <h6>{t('paymentMethod')}:</h6>
                          <p>
                            {' '}
                            {details?.paymentOptionId === 5
                              ? t('wallet')
                              : details?.paymentOptionId === 4
                                ? t('paymentOption-salary')
                                : details?.paymentOptionId === 3
                                  ? t('paymentOption-creditCard')
                                  : details?.paymentOptionId === 6
                                    ? t('paymentOption-cibus')
                                    : details?.paymentMethod === 'Invoice'
                                      ? t('invoice')
                                      : '--'}
                          </p>
                        </div>
                        {details?.paymentOptionId === 6 ? (
                          <div className="user-details-block">
                            <h6>{t('paymentOrderId')}:</h6>
                            <p>
                              {details?.chargeResponse
                                ? JSON.parse(details?.chargeResponse)?.deal_id
                                : '--'}
                              {details?.chargeResponse
                                ? JSON.parse(details?.chargeResponse)?.orderID
                                : '--'}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {details?.paymentOptionId === 6 ? (
                          <div className="user-details-block">
                            <h6>{t('paymentOrderMessage')}:</h6>
                            <p>
                              {details?.chargeResponse &&
                              JSON.parse(details?.chargeResponse)?.mess
                                ? JSON.parse(details?.chargeResponse)?.mess
                                : '--'}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {details?.paymentOptionId === 6 ? (
                          <div className="user-details-block">
                            <h6>{t('paymentStatus')}:</h6>
                            <p>
                              {details?.paymentStatus
                                ? t('paid')
                                : t('notPaid')}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {details?.phoneNumber ? (
                          <div className="user-details-block">
                            <h6>{t('phonePH')}:</h6>
                            <p>
                              {details?.phoneNumber
                                ? details?.phoneNumber
                                : '--'}
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="user-details-block">
                          <h6>{t('realizedDate')}:</h6>
                          {details?.point && details?.status ? (
                            <p>
                              {details?.updatedAt
                                ? moment(details?.updatedAt).format(
                                    'DD/MM/YYYY HH:mm'
                                  )
                                : '--'}
                            </p>
                          ) : (
                            <p>
                              {details?.realizeDate
                                ? moment(details?.realizeDate).format(
                                    'DD/MM/YYYY HH:mm'
                                  )
                                : '--'}
                            </p>
                          )}
                        </div>
                        <div className="user-details-block">
                          <h6>{t('timeSpan')}:</h6>
                          {details?.pickupTime ? (
                            <p>{pickupCreateDiff}</p>
                          ) : (
                            '--'
                          )}
                        </div>
                        <div className="user-details-block">
                          <h6>{t('timeToMeal')}:</h6>
                          {details?.point && details?.status ? (
                            <p>
                              {details?.updatedAt ? realizeCreateDiff : '--'}
                            </p>
                          ) : (
                            <p>{realizeCreateDiff || '--'}</p>
                          )}
                        </div>
                        <div className="user-details-block">
                          <h6>{t('realizedMethod')}:</h6>
                          <p>
                            {details?.realizedMethod
                              ? details?.realizedMethod === 'Manual'
                                ? t('manual')
                                : details?.realizedMethod === 'QR'
                                  ? t('qr')
                                  : details?.realizedMethod === 'Card'
                                    ? t('card')
                                    : '--'
                              : '--'}
                          </p>
                        </div>
                        <div className="user-details-block">
                          <h6>{t('realizedByUserName')}:</h6>
                          <p>
                            {details?.realizedMethod === 'Card' ||
                            details?.realizedMethod === 'QR'
                              ? '--'
                              : details?.realizedUserName
                                ? details?.realizedUserName
                                : '--'}
                          </p>
                        </div>
                        <div className="user-details-block">
                          <h6>{t('realizedByDispenserName')}:</h6>
                          <p>
                            {details?.orderStatusId === 2 &&
                            (details?.realizedMethod === 'Card' ||
                              details?.realizedMethod === 'QR')
                              ? details?.kitchenDevice?.displayName
                                ? details?.kitchenDevice?.displayName
                                : '--'
                              : '--'}
                          </p>
                        </div>
                        <div className="user-details-block">
                          <h6>{t('realizedStatus')}:</h6>
                          <p>
                            {details?.point
                              ? t('pendingCollectedPoint')
                              : details?.orderStatusId === 2
                                ? t('realizedOrder')
                                : t('notRealized')}
                          </p>
                        </div>{' '}
                        {details?.point
                          ? ''
                          : details?.cancelledBy && (
                              <>
                                {' '}
                                <div className="user-details-block">
                                  <h6>{t('cancelledBy')}:</h6>
                                  <p>
                                    {details?.cancelledBy
                                      ? details?.cancelledBy
                                      : '--'}
                                  </p>
                                </div>
                                <div className="user-details-block">
                                  <h6>{t('cancelledOrderTime')}:</h6>
                                  <p>
                                    {details?.cancelledDate
                                      ? moment(details?.cancelledDate).format(
                                          'DD/MM/YYYY HH:mm'
                                        )
                                      : '--'}
                                  </p>
                                </div>
                              </>
                            )}
                        {details?.paymentOptionId === 4 ? (
                          details?.point ? (
                            <div className="user-details-block">
                              <h6>{t('isSalary')}:</h6>
                              <p>
                                {details?.status ? (
                                  details?.reported ? (
                                    <>
                                      {' '}
                                      <div className="order-status success">
                                        <span>{t('success')}</span>
                                        <span>
                                          (
                                          {moment(details?.updatedAt).format(
                                            'DD/MM/YYYY HH:mm'
                                          )}
                                          )
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="order-status success">
                                      <span>{t('isAproved')}</span>
                                      <span>
                                        {moment(details?.updatedAt).format(
                                          'DD/MM/YYYY'
                                        )}
                                      </span>
                                    </div>
                                  )
                                ) : details?.orderStatusId !== 3 ? (
                                  <div className="order-status pending">
                                    {/* <i className="fa fa-circle" /> */}
                                    <span>{t('pending')}</span>
                                  </div>
                                ) : (
                                  <div className="order-status failed">
                                    {/* <i className="fa fa-circle" /> */}
                                    <span>{t('cancelled')}</span>
                                  </div>
                                )}
                              </p>
                            </div>
                          ) : (
                            <div className="user-details-block">
                              <h6>{t('isSalary')}:</h6>
                              <p>
                                {details?.reported &&
                                details?.orderStatusId !== 3 ? (
                                  <div className="order-status success">
                                    {/* <i className="fa fa-circle" /> */}
                                    <span>{t('success')}</span>
                                    <span>
                                      (
                                      {moment(details?.updatedAt).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                      )
                                    </span>
                                  </div>
                                ) : details?.orderStatusId !== 3 && isExp ? (
                                  <div className="order-status success">
                                    <span>{t('isAproved')}</span>
                                    <span>
                                      {moment(details?.updatedAt).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </span>
                                  </div>
                                ) : details?.orderStatusId !== 3 ? (
                                  <div className="order-status pending">
                                    {/* <i className="fa fa-circle" /> */}
                                    <span>{t('pending')}</span>
                                  </div>
                                ) : (
                                  <div className="order-status failed">
                                    {/* <i className="fa fa-circle" /> */}
                                    <span>{t('canceled')}</span>
                                  </div>
                                )}
                              </p>
                            </div>
                          )
                        ) : (
                          ''
                        )}
                        <div className="user-details-block">
                          <h6>{t('pickupTime')}:</h6>
                          <p>
                            {details?.pickupTime ? details?.pickupTime : ''}
                          </p>
                        </div>
                      </div>
                    </div>
                    {currentUser.roleId !== 9 &&
                    currentUser.roleId !== 10 &&
                    currentUser.roleId !== 7 &&
                    details?.point ? (
                      <div className="modal-footer-btn">
                        {!details?.reported && (
                          <button
                            className="edit-btn"
                            onClick={() =>
                              deleteCreditPoint({
                                userId: details?.userId,
                                mealTypeId: details?.mealType?.id,
                                point: details?.mealType?.creditPoints,
                                kitchenId: details?.mealType?.kitchenId,
                                mealId: details?.mealId,
                                date: details?.addDate,
                              })
                            }
                          >
                            <i class="las la-trash"></i>
                            {t('cancelOrderBtnLabel')}
                          </button>
                        )}
                        {reservationType === 'Credit points' &&
                          currentUser.roleId !== 3 &&
                          currentUser.roleId !== 7 &&
                          currentUser.roleId !== 9 &&
                          currentUser.roleId !== 10 &&
                          attendanceEnabled && (
                            <button
                              className="edit-btn"
                              onClick={() => {
                                history.push(
                                  `/attendance?user=${
                                    details?.userId
                                  }&fromDate=${moment(fromDate).format(
                                    'YYYY-MM-DD'
                                  )}&toDate=${moment(toDate).format(
                                    'YYYY-MM-DD'
                                  )}`
                                );
                              }}
                            >
                              <i class="las la-address-card"></i>
                              {t('attendance')}
                            </button>
                          )}
                      </div>
                    ) : details?.orderStatusId === 2 ? (
                      <div className="modal-footer-btn">
                        {currentUser.roleId !== 9 &&
                          currentUser.roleId !== 10 &&
                          currentUser.roleId !== 7 && (
                            <button
                              className="edit-btn"
                              onClick={() => {
                                handleCancelOrder(details.id, details.userId);
                              }}
                            >
                              <i class="las la-trash"></i>
                              {t('cancelOrderBtnLabel')}
                            </button>
                          )}
                        {details?.orderStatusId === 1 ? (
                          ''
                        ) : (
                          <button
                            className="edit-btn"
                            onClick={async () => {
                              /*await handleOpen(
                                details.hash,
                                details.mealDish[0].mealDish.mealId,
                                details.id
                              );*/
                              const receiptContent = `
  <html>
    <head>
      <style>
        @page {
          size: 80mm 100mm;
          margin: 0; 
        }
        body {
          margin: 0;
          font-family: monospace;
          text-align: center;
        }
        h3 {
          margin: 0;
        }
        hr {
          border: none;
          border-top: 1px solid #000;
        }
        table {
          width: 100%;
          text-align: left;
          border-collapse: collapse;
        }
        th, td {
          padding: 5px;
        }
        p {
          margin: 5px 0;
        }
      </style>
    </head>
    <body>
      <h3>Hello</h3>
      <p>Kitchen name: ${details.kitchen.name}</p>
      <hr>
      <table>
        <tr>
          <th>Dish name</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
        ${details.mealDish[0].mealDish.dishes.map(
          (el) => `
            <tr>
          <td>${el.translations[2]}</td>
          <td>${el.quantity}</td>
          <td>${el.price}</td>
        </tr>
          `
        )}
      </table>
      <hr>
      <p style="text-align: right;">Total: ${details.orderPrice}</p>
    </body>
  </html>
`;
                              const printWindow = window.open('', '', '');
                              printWindow.document.write(receiptContent);
                              printWindow.document.close();
                              printWindow.focus();
                              printWindow.print();
                              printWindow.close();
                            }}
                          >
                            <i class="las la-receipt"></i>
                            {t('printReceipt')}
                          </button>
                        )}
                        {details?.paymentOptionId == 6 && (
                          <button
                            className="edit-btn"
                            onClick={() => {
                              setTabIndex(0);
                              handleClose();
                              setChangedPaymentParams((param) => ({
                                ...param,
                                action: details.paymentStatus
                                  ? 'cancel_payment'
                                  : 'payment',
                                orderID: details.id,
                              }));
                              setChangePaymentStatus(true);
                            }}
                          >
                            <i class="las la-edit"></i>
                            {t('editPaymentDetails')}
                          </button>
                        )}
                        {reservationType === 'Credit points' &&
                          currentUser.roleId !== 3 &&
                          currentUser.roleId !== 10 &&
                          currentUser.roleId !== 9 &&
                          (window.location.href.includes('localhost') ||
                            window.location.href.includes('vishay') ||
                            window.location.href.includes('develop-app')) && (
                            <button
                              className="edit-btn"
                              onClick={() => {
                                history.push(
                                  `/attendance?user=${
                                    details?.userId
                                  }&fromDate=${moment(fromDate).format(
                                    'YYYY-MM-DD'
                                  )}&toDate=${moment(toDate).format(
                                    'YYYY-MM-DD'
                                  )}`
                                );
                              }}
                            >
                              <i class="las la-address-card"></i>
                              {t('attendance')}
                            </button>
                          )}
                      </div>
                    ) : details?.orderStatusId === 3 ? (
                      <div className="modal-footer-btn">
                        <p>{t('canceled')}</p>
                      </div>
                    ) : currentUser.roleId !== 9 &&
                      currentUser.roleId !== 10 &&
                      currentUser?.roleId !== 7 ? (
                      <div className="modal-footer-btn">
                        {!details?.reported && (
                          <button
                            className="edit-btn"
                            onClick={async () => {
                              handleOpen(
                                details.hash,
                                details.mealDish[0].mealDish.mealId,
                                details.id
                              );
                            }}
                          >
                            <i class="las la-hourglass-half"></i>
                            {t('realizeOrder')}
                          </button>
                        )}
                        {details?.orderStatusId === 1 ? (
                          <button
                            className="edit-btn"
                            onClick={() => handleMarkAsPickup(details.id)}
                          >
                            <i class="las la-shopping-bag"></i>
                            {t('markAsPickup')}
                          </button>
                        ) : (
                          ''
                        )}
                        {details?.orderStatusId === 4 ? (
                          <button
                            className="edit-btn"
                            onClick={() => handleUnMarkAsPickup(details.id)}
                          >
                            <i class="las la-shopping-bag"></i>
                            {t('cancelPickup')}
                          </button>
                        ) : (
                          ''
                        )}
                        {details?.orderStatusId === 1 ? (
                          ''
                        ) : (
                          <button
                            className="edit-btn"
                            onClick={async () => {
                              /*await handleOpen(
                                details.hash,
                                details.mealDish[0].mealDish.mealId,
                                details.id
                              );*/
                              const receiptContent = `
  <html>
    <head>
      <style>
        @page {
          size: 80mm 100mm;
          margin: 0; 
        }
        body {
          margin: 0;
          font-family: monospace;
          text-align: center;
        }
        h3 {
          margin: 0;
        }
        hr {
          border: none;
          border-top: 1px solid #000;
        }
        table {
          width: 100%;
          text-align: left;
          border-collapse: collapse;
        }
        th, td {
          padding: 5px;
        }
        p {
          margin: 5px 0;
        }
      </style>
    </head>
    <body>
      <h3>Hello</h3>
      <p>Kitchen name: ${details.kitchen.name}</p>
      <hr>
      <table>
        <tr>
          <th>Dish name</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
        ${details.mealDish[0].mealDish.dishes.map(
          (el) => `
            <tr>
          <td>${el.translations[2]}</td>
          <td>${el.quantity}</td>
          <td>${el.price}</td>
        </tr>
          `
        )}
      </table>
      <hr>
      <p style="text-align: right;">Total: ${details.orderPrice}</p>
    </body>
  </html>
`;
                              const printWindow = window.open('', '', '');
                              printWindow.document.write(receiptContent);
                              printWindow.document.close();
                              printWindow.focus();
                              printWindow.print();
                              printWindow.close();
                            }}
                          >
                            <i class="las la-receipt"></i>
                            {t('printReceipt')}
                          </button>
                        )}
                        {details?.paymentOptionId == 6 && (
                          <button
                            className="edit-btn"
                            onClick={() => {
                              setTabIndex(0);
                              handleClose();
                              setChangedPaymentParams((param) => ({
                                ...param,
                                action: details.paymentStatus
                                  ? 'cancel_payment'
                                  : 'payment',
                                orderID: details.id,
                              }));
                              setChangePaymentStatus(true);
                            }}
                          >
                            <i class="las la-edit"></i>
                            {t('editPaymentDetails')}
                          </button>
                        )}
                        {currentUser.roleId !== 9 &&
                          currentUser.roleId !== 10 &&
                          currentUser.roleId !== 7 && (
                            <button
                              className="edit-btn"
                              onClick={() => {
                                handleCancelOrder(details.id, details.userId);
                              }}
                            >
                              <i class="las la-trash"></i>
                              {t('cancelOrderBtnLabel')}
                            </button>
                          )}
                        {reservationType === 'Credit points' &&
                          currentUser.roleId !== 3 &&
                          currentUser.roleId !== 10 &&
                          currentUser.roleId !== 9 &&
                          (window.location.href.includes('localhost') ||
                            window.location.href.includes('vishay') ||
                            window.location.href.includes('develop-app')) && (
                            <button
                              className="edit-btn"
                              onClick={() => {
                                history.push(
                                  `/attendance?user=${
                                    details?.userId
                                  }&fromDate=${moment(fromDate).format(
                                    'YYYY-MM-DD'
                                  )}&toDate=${moment(toDate).format(
                                    'YYYY-MM-DD'
                                  )}`
                                );
                              }}
                            >
                              <i class="las la-address-card"></i>
                              {t('attendance')}
                            </button>
                          )}
                      </div>
                    ) : (
                      ''
                    )}
                  </CustomTabPanel>
                  {reservationType !== 'Credit points' && (
                    <CustomTabPanel value={tabIndex} index={1}>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <strong>{t('dishes')}</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <strong>{t('quantity')}</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <strong>{t('price')}</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <strong>{t('totalPrice')}</strong>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dishesForTable.map((row) => (
                              <StyledTableRow
                                key={row.name}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell>{row.name}</StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.quantity}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {`${row.price / row.quantity} ₪`}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {`${row.price} ₪`}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}

                            <StyledTableRow
                              key={'Total'}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell>
                                <strong>{t('total')}</strong>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {dishesForTable.length !== 0
                                  ? dishesForTable.reduce(
                                      (acc, el) => acc + Number(el.quantity),
                                      0
                                    )
                                  : 0}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {`${
                                  dishesForTable.length !== 0
                                    ? dishesForTable.reduce(
                                        (acc, el) => acc + Number(el.price),
                                        0
                                      )
                                    : 0
                                } ₪`}
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomTabPanel>
                  )}
                  <CustomTabPanel
                    value={tabIndex}
                    index={reservationType === 'Credit points' ? 1 : 2}
                    class="overflow-hidden"
                  >
                    {historyData && historyData.length > 0 ? (
                      <TableContainer
                        component={Paper}
                        className="audit-table-container"
                      >
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell className="p-2" width={125}>
                                <strong>{`${t('date')} ${t('time')}`}</strong>
                              </StyledTableCell>
                              <StyledTableCell className="p-2" width={125}>
                                <strong>{t('user')}</strong>
                              </StyledTableCell>
                              <StyledTableCell className="p-2" width={125}>
                                <strong>{t('action')}</strong>
                              </StyledTableCell>
                              <StyledTableCell className="p-2">
                                <div className="d-flex m-0 w-100">
                                  <strong className="history-column-fieldname">{`${t(
                                    'fieldName'
                                  )}`}</strong>
                                  <strong className="history-column-values">{`${t(
                                    'oldValue'
                                  )}`}</strong>
                                  <strong className="history-column-values">{`${t(
                                    'newValue'
                                  )}`}</strong>
                                </div>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {historyData.map((row) => (
                              <StyledTableRow
                                key={row.name}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell className="p-2">
                                  <p className="audit-details-text">
                                    {moment(row?.createdAt).format(
                                      'YYYY-MM-DD'
                                    )}
                                  </p>
                                  <p className="audit-details-text">
                                    {moment(row?.createdAt).format(
                                      'hh:mm:ss  A'
                                    )}
                                  </p>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="p-2">
                                  <p className="audit-details-text">
                                    {row?.madeBy}
                                  </p>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="p-2">
                                  <p className="audit-details-text">
                                    {row.typeAction}
                                  </p>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="p-2">
                                  <div className="d-flex m-0 flex-column gap-2">
                                    {Object.keys(row.diff).length > 0
                                      ? Object.entries(row.diff)
                                          .filter(
                                            (el) =>
                                              el[0] !== 'chargeResponse' ||
                                              (el[0] === 'chargeResponse' &&
                                                ((JSON.parse(el[1].old) !==
                                                  null &&
                                                  'deal_id' in
                                                    JSON.parse(el[1].old)) ||
                                                  (JSON.parse(el[1].new) !==
                                                    null &&
                                                    'deal_id' in
                                                      JSON.parse(el[1].new))))
                                          )
                                          .map((el) => (
                                            <div>
                                              <StyledTableCell className="p-1 history-column-fieldname border-bottom-0">
                                                <p className="audit-details-text">{`${
                                                  t(
                                                    el[0] !== 'chargeResponse'
                                                      ? el[0]
                                                      : 'deal_id'
                                                  ) || '--'
                                                }:`}</p>
                                              </StyledTableCell>
                                              <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                                <p className="pl-1 pr-1 audit-details-text">{`${
                                                  el[1] &&
                                                  el[1]?.old &&
                                                  el[0] === 'kitchensId'
                                                    ? Kitchens.filter(
                                                        (obj1) =>
                                                          el[1]?.old === obj1.id
                                                      )
                                                        .map((el) => el.name)
                                                        .join(', ')
                                                    : el[1] &&
                                                        el[1]?.old &&
                                                        el[0] === 'mealTypeId'
                                                      ? mealTypes
                                                          .filter(
                                                            (obj1) =>
                                                              el[1]?.old ==
                                                              obj1.id
                                                          )
                                                          .map(
                                                            (el) => el.display
                                                          )
                                                          .join(', ')
                                                      : el[1] &&
                                                          el[1]?.old &&
                                                          el[0] ===
                                                            'paymentOptionId'
                                                        ? PaymentOption?.find(
                                                            (elem) =>
                                                              elem?.id ==
                                                              el[1]?.old
                                                          )?.display
                                                        : el[1] &&
                                                            el[1]?.old &&
                                                            el[0] ===
                                                              'dishesInOrder'
                                                          ? el[1]?.old
                                                              .map(
                                                                (el) =>
                                                                  el[
                                                                    `name${i18n.language}`
                                                                  ]
                                                              )
                                                              .join(', ')
                                                          : el[1] &&
                                                              el[1]?.old &&
                                                              el[0] ===
                                                                'kitchenId'
                                                            ? kitchens.options.find(
                                                                (elem) =>
                                                                  elem?.id ===
                                                                  el[1]?.old
                                                              )?.name
                                                            : el[1] &&
                                                                el[1]?.old &&
                                                                el[0] ===
                                                                  'orderStatusId'
                                                              ? OrderStatuses.find(
                                                                  (elem) =>
                                                                    elem?.id ===
                                                                    el[1]?.old
                                                                )?.display
                                                              : el[1] &&
                                                                  el[1]?.old &&
                                                                  el[0] ===
                                                                    'chargeResponse'
                                                                ? JSON.parse(
                                                                    el[1]?.old
                                                                  )[
                                                                    'deal_id'
                                                                  ] ?? ''
                                                                : (el[1]
                                                                      ?.old !==
                                                                      null &&
                                                                      el[1]
                                                                        ?.old !==
                                                                        undefined &&
                                                                      el[1]
                                                                        ?.old !==
                                                                        '') ||
                                                                    typeof el[1]
                                                                      ?.old ===
                                                                      'boolean'
                                                                  ? el[1]?.old
                                                                  : '--'
                                                }`}</p>
                                              </StyledTableCell>
                                              <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                                <p className="pl-1 pr-1 audit-details-text">{`${
                                                  el[1] &&
                                                  el[1]?.new &&
                                                  el[0] === 'kitchensId'
                                                    ? Kitchens.filter(
                                                        (obj1) =>
                                                          el[1]?.new === obj1.id
                                                      )
                                                        .map((el) => el.name)
                                                        .join(', ')
                                                    : el[1] &&
                                                        el[1]?.new &&
                                                        el[0] === 'mealTypeId'
                                                      ? mealTypes
                                                          .filter(
                                                            (obj1) =>
                                                              el[1]?.new ==
                                                              obj1.id
                                                          )
                                                          .map(
                                                            (elem) =>
                                                              elem.display
                                                          )
                                                          .join(', ')
                                                      : el[1] &&
                                                          el[1]?.new &&
                                                          el[0] ===
                                                            'paymentOptionId'
                                                        ? PaymentOption?.find(
                                                            (elem) =>
                                                              elem?.id ==
                                                              el[1]?.new
                                                          )?.display
                                                        : el[1] &&
                                                            el[1]?.new &&
                                                            el[0] ===
                                                              'dishesInOrder'
                                                          ? el[1]?.new
                                                              .map(
                                                                (el) =>
                                                                  el[
                                                                    `name${i18n.language}`
                                                                  ]
                                                              )
                                                              .join(', ')
                                                          : el[1] &&
                                                              el[1]?.new &&
                                                              el[0] ===
                                                                'kitchenId'
                                                            ? kitchens.options.find(
                                                                (elem) =>
                                                                  elem?.id ===
                                                                  el[1]?.new
                                                              )?.name
                                                            : el[1] &&
                                                                el[1]?.new &&
                                                                el[0] ===
                                                                  'orderStatusId'
                                                              ? OrderStatuses.find(
                                                                  (elem) =>
                                                                    elem?.id ===
                                                                    el[1]?.new
                                                                )?.display
                                                              : el[1] &&
                                                                  el[1]?.new &&
                                                                  el[0] ===
                                                                    'chargeResponse'
                                                                ? JSON.parse(
                                                                    el[1]?.new
                                                                  )[
                                                                    'deal_id'
                                                                  ] ?? ''
                                                                : (el[1]
                                                                      ?.new !==
                                                                      null &&
                                                                      el[1]
                                                                        ?.new !==
                                                                        undefined &&
                                                                      el[1]
                                                                        ?.new !==
                                                                        '') ||
                                                                    typeof el[1]
                                                                      ?.new ===
                                                                      'boolean'
                                                                  ? el[1]?.new
                                                                  : '--'
                                                }`}</p>
                                              </StyledTableCell>
                                            </div>
                                          ))
                                      : '--'}
                                    {'dishesInOrder' in row.diff ? (
                                      <div>
                                        <StyledTableCell className="p-1 history-column-fieldname border-bottom-0">
                                          <p className="audit-details-text">{`${t(
                                            'dishQuantity'
                                          )}:`}</p>
                                        </StyledTableCell>
                                        <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                          <p className="pl-1 pr-1 audit-details-text">
                                            {row.diff.dishesInOrder.old
                                              ? row.diff.dishesInOrder.old.reduce(
                                                  (acc, curr) =>
                                                    acc + curr.dishQuantity,
                                                  0
                                                )
                                              : '--'}
                                          </p>
                                        </StyledTableCell>
                                        <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                          <p className="pl-1 pr-1 audit-details-text">
                                            {row.diff.dishesInOrder.new
                                              ? row.diff.dishesInOrder.new.reduce(
                                                  (acc, curr) =>
                                                    acc + curr.dishQuantity,
                                                  0
                                                )
                                              : '--'}
                                          </p>
                                        </StyledTableCell>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                        <Typography className="history-text-styles fs-5">
                          {t('emptyHistoryMess')}
                        </Typography>
                      </div>
                    )}
                  </CustomTabPanel>

                  <CustomTabPanel
                    value={tabIndex}
                    index={reservationType === 'Credit points' ? 2 : 3}
                    class="overflow-hidden"
                  >
                    {transactionHistory && transactionHistory.length > 0 ? (
                      <TableContainer
                        component={Paper}
                        className="audit-table-container"
                      >
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell className="p-2" width={125}>
                                <strong>{`${t('date')} ${t('time')}`}</strong>
                              </StyledTableCell>
                              <StyledTableCell className="p-2" width={125}>
                                <strong>{t('user')}</strong>
                              </StyledTableCell>
                              <StyledTableCell className="p-2" width={125}>
                                <strong>{t('message')}</strong>
                              </StyledTableCell>
                              {details?.paymentOptionId === 3 &&
                              receipts &&
                              receipts.find(
                                (el) => el?.orderId === details?.id
                              ) ? (
                                <StyledTableCell
                                  className="p-2 center"
                                  width={125}
                                >
                                  <strong>{t('Invoice')}</strong>
                                </StyledTableCell>
                              ) : (
                                ''
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {transactionHistory.map((el, i) => (
                              <StyledTableRow
                                key={el.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell className="p-2">
                                  <p className="audit-details-text">
                                    {moment(el?.createdAt).format('YYYY-MM-DD')}
                                  </p>
                                  <p className="audit-details-text">
                                    {moment(el?.createdAt).format(
                                      'hh:mm:ss  A'
                                    )}
                                  </p>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="p-2">
                                  <p className="audit-details-text">
                                    {el?.userfirstName || el?.user?.firstName
                                      ? `${
                                          el?.userfirstName ||
                                          el?.user?.firstName
                                        } ${
                                          el?.userlastName || el?.user?.lastName
                                        }`
                                      : phoneNumberFromPayment || 'anonymous'}
                                  </p>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="p-2">
                                  <p className="audit-details-text">
                                    {el.message}
                                  </p>
                                </StyledTableCell>
                                {receipts
                                  .filter(
                                    (elem) => elem.orderId === details?.id
                                  )
                                  .find(
                                    (elem) => elem.receiptType === el.action
                                  ) ? (
                                  <StyledTableCell className="p-2">
                                    <a
                                      className="audit-details-text"
                                      target="_blank"
                                      href={
                                        receipts
                                          .filter(
                                            (elem) =>
                                              elem.orderId === details?.id
                                          )
                                          .find(
                                            (elem) =>
                                              elem.receiptType === el.action
                                          )?.S3FileUrl ??
                                        receipts
                                          .filter(
                                            (elem) =>
                                              elem.orderId === details?.id
                                          )
                                          .find(
                                            (elem) =>
                                              elem.receiptType === el.action
                                          )?.url ??
                                        receipts
                                          .filter(
                                            (elem) =>
                                              elem.orderId === details?.id
                                          )
                                          .find(
                                            (elem) =>
                                              elem.receiptType === el.action
                                          )?.tempUrl
                                      }
                                    >
                                      {receipts
                                        .filter(
                                          (elem) => elem.orderId === details?.id
                                        )
                                        .find(
                                          (elem) =>
                                            elem.receiptType === el.action
                                        )?.docNum ?? t('linkToInvoice')}
                                    </a>
                                  </StyledTableCell>
                                ) : (
                                  ''
                                )}
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                        <Typography className="history-text-styles fs-5">
                          {t('emptyHistoryMess')}
                        </Typography>
                      </div>
                    )}
                  </CustomTabPanel>
                </Box>
              </>
            )}
            {reportType === 'TotalUser' && (
              <>
                <div className="user-detail-container">
                  <div className="user-details-row">
                    <div className="user-details-block">
                      <h6>{t('orderId')}:</h6>
                      <p>{details?.userId ? details?.userId : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('firstName')}:</h6>
                      <p>{details?.firstName ? details?.firstName : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('lastName')}:</h6>
                      <p>{details?.lastName ? details?.lastName : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>
                        {fieldNamesSettings?.find((el) => el.value === 'cardId')
                          ? fieldNamesSettings
                              ?.find((el) => el.value === 'cardId')
                              ?.translations.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('cardId')}
                        :
                      </h6>
                      <p>{details?.cardNumber ? details?.cardNumber : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>
                        {fieldNamesSettings?.find(
                          (el) => el.value === 'employeeId'
                        )
                          ? fieldNamesSettings
                              .find((el) => el.value === 'employeeId')
                              .translations?.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('employeeId')}
                        :
                      </h6>
                      <p>
                        {details?.employeeNumber
                          ? details?.employeeNumber
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('userType')}:</h6>
                      <p>{details?.userType ? details?.userType : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('contractorName')}:</h6>
                      <p>
                        {details?.order?.user.contractors
                          .map((elem) => elem.name)
                          .join(', ')
                          ? details?.order?.user.contractors
                              .map((elem) => elem.name)
                              .join(', ')
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('timeToMeal')}:</h6>
                      <p>{details?.timeToMeal ?? '--'}</p>
                    </div>
                    {MealTypes.filter(
                      (el) => el.kitchenId == kitchenParam ?? kitchen.id
                    )?.map((el) => {
                      return (
                        <>
                          <div className="user-details-block">
                            <h6>
                              {el &&
                              'mealtype|reportName|translation' in el &&
                              el['mealtype|reportName|translation']?.text
                                ? el['mealtype|reportName|translation']?.text
                                : el?.display}{' '}
                              {t('reservations')}:
                            </h6>
                            <p>
                              {
                                details?.[
                                  el?.value?.split(' ').join('') + 'Reservation'
                                ]
                              }
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>
                              {el &&
                              'mealtype|reportName|translation' in el &&
                              el['mealtype|reportName|translation']?.text
                                ? el['mealtype|reportName|translation']?.text
                                : el?.display}{' '}
                              {t('realized')}:
                            </h6>
                            <p>
                              {
                                details?.[
                                  el?.value?.split(' ').join('') + 'Realized'
                                ]
                              }
                            </p>
                          </div>
                        </>
                      );
                    })}
                    <div className="user-details-block">
                      <h6>{t('totalReservation')}:</h6>
                      <p>
                        {details?.totalReservation
                          ? details?.totalReservation
                          : '0'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('totalRealized')}:</h6>
                      <p>
                        {details?.totalRealized ? details?.totalRealized : '0'}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {reportType === 'MealType' && (
              <>
                <div className="user-detail-container">
                  <div className="user-details-row">
                    <div className="user-details-block">
                      <h6>{t('mealTypeName')}:</h6>
                      <p>{details?.display ? details?.display : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('totalOfInventory')}:</h6>
                      <p>{details?.inventory ? details?.inventory : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('ofreservations')}:</h6>
                      <p>{details?.ordered ? details?.ordered : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('ofRealised')}:</h6>
                      <p>{details?.realized ? details?.realized : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('perceReservedInventory')}:</h6>
                      <p>
                        {details?.reservedInventory
                          ? details?.reservedInventory
                          : 0}
                        %
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('ofRealisedInventory')}:</h6>
                      <p>
                        {details?.realizedInventory
                          ? details?.realizedInventory
                          : 0}
                        %
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('ofRealizedReserved')}:</h6>
                      <p>
                        {details?.realizedReserved
                          ? details?.realizedReserved
                          : 0}
                        %
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('totalCreditPointRequest')}:</h6>
                      <p>{details?.creditPoints}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('pendingCreditPointRequest')}:</h6>
                      <p>{details?.pending ? details?.pending : 0}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('approvedCreditPointReqquest')}:</h6>
                      <p>{details?.approval ? details?.approval : 0}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('creditPointPercentage')}:</h6>
                      <p>{details?.percent ? details?.percent : 0}%</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <ConfirmationPopup
              open={viewData}
              jsonData={jsonData}
              handleClose={handleViewData}
              t={t}
              prefetchData={reserData}
            />
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

const ConfirmationPopup = ({
  open,
  handleClose,
  prefetchData,
  jsonData,
  t,
}) => {
  let jsondataValue = {};
  if (!prefetchData) {
    prefetchData = [];
  }

  jsondataValue = prefetchData?.filter((el) => el.id === jsonData);
  const formatter =
    jsondataValue && jsondataValue[0]
      ? new JSONFormatter(JSON.parse(jsondataValue[0]?.chargeResponse || '{}'))
      : '';
  let element = document.getElementById('json');

  if (element && formatter !== '') {
    element.innerHTML = '';
    element.appendChild(formatter.render());
  }

  return (
    // <Modal
    //   open={open}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    //   maxWidth="lg"
    //   className="add-employee-popup delete-popup log-json-data"
    // >
    //   <Box sx={style}>
    <>
      {open ? <div className="bg-shadow" /> : ''}
      <div
        className={`add-employee-popup delete-popup log-json-data reservation-popup ${
          open ? 'open-json-popup' : ''
        }`}
      >
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <h6>{t('updatedData')}</h6>
        <div id="modal-modal-description">
          <div className="copy-url">
            <div className="confirmation-btn" id="json"></div>
            <button className="copy-btn" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </>
    //   </Box>
    // </Modal>
  );
};

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
  currentUser: state.user,
  fieldNamesSettings: state.companyConfig.fieldNamesSettings,
});
const mapDispatchToProps = {
  useFetchingLoader,
};
// export default ReservationManagement;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewReservationDetails);
