import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { useHistory, useLocation, useParams } from 'react-router';
import http from '../../services/http';
import { ActiveButton } from '../../components/WE-Button';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
  getMealTypes,
} from '../../store/app/actions';
import WEIcon, { WEIconFontAwesome } from '../../components/WE-IconButton';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import { useTranslation } from 'react-i18next';
import { validateMealType, checkDate } from '../../services/validators';
import MealSlotPopup from './mealSlot';
import MealTypeReminder from './mealTypeReminder';
import icon from '../../assets/icons/add.png';
import WEBackButton from '../../components/WE-BackButton';
import cafe from '../../assets/images/check-mark.png';
import { Button } from 'react-bootstrap';
import AddMealType from './AddMealType';
import { Modal } from 'react-bootstrap';
import { getPaymentPreferences } from '../../store/kitchens/actions';

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const initialState = {
  mealtype: '',
  from: '',
  to: '',
  externalId: '',
  orderIndex: '',
};

const initialValues = {
  from: '',
  to: '',
  maxReservations: '',
};
const MealTypes = ({
  MealTypes,
  kitchen,
  CompanyId,
  setLayoutOptions,
  KitchenStatuses,
  Kitchens,
  Languages,
  CurrentLanguage,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getMealTypes,
}) => {
  let history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const KitchenStatusesMap = KitchenStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const mealTypeReminderInit = {
    isJobEnabled: false,
    reminderId: undefined,
    mealTypeID: null,
    time: 0,
    isSendToEmail: false,
    isSendBySMS: false,
    message: '',
  };
  const [toggleAdd, setToggleAdd] = useState(false);
  const [allData, setAllData] = useState([]);
  const [isActive, setIsActive] = useState();
  const [dataValue, setDataValue] = useState(initialState);
  const [isEdit, setIsEdit] = useState();
  const [dummy, setDummy] = useState(null);
  const [mealsdummy, setMealsDummy] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [mealSlot, setMealSlot] = useState(false);
  const [mealTypeSlot, setMealTypeSlot] = useState({});
  const [allMealtypeSlot, setAllMealtypeSlot] = useState({});
  const [errors, setErrors] = useState({});
  // const { kitchenId } = useParams();
  const [payload, setPayload] = useState(initialValues);
  const [transData, setTransData] = useState({});
  const [kitchenvalue, setKitchenValue] = useState();
  const [mealTypeId, setMealTypeId] = useState(null);
  const Endpoint = 'Meal Type';
  const { backText, backLink } = location.state ?? {};
  const [toggleBtn, setToggleBtn] = useState(false);
  const [addMeal, setAddMeal] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [moreDetailsdata, setMoreDetailsData] = useState(null);
  const [editMeal, setEditMeal] = useState(false);
  const [paymentMethodsID, setPaymentMethods] = useState([]);
  const [deletedElemId, setDeletedElemId] = useState();
  const [isEditReminder, setIsEditReminder] = useState(false);
  const [isDisplayMealTypeReminder, setIsDisplayMealTypeReminder] =
    useState(false);
  const [reminderValues, setReminderValues] = useState(mealTypeReminderInit);

  useEffect(() => {
    const fetchData1 = async () => {
      let dd = localStorage.getItem('kitchen');
      if (dd) {
        setKitchenValue(Number(dd));
        setDummy(true);
      }
      // GET PAYMENT METHOD BY KITCHEN ID
      const data = await getPaymentPreferences(kitchen?.id, '');
      setPaymentMethods(data?.selectedPaymentOptions);
    };
    fetchData1();
  }, [kitchen]);
  const resetDataValue = () => {
    setDataValue(initialState);
  };
  useEffect(() => {
    const fetchData1 = async () => {
      if (!kitchenvalue) {
        return;
      }

      let AllData;
      let filter = {
        kitchenId: kitchenvalue,
      };

      let { data } = await useFetchingLoader(
        async () => await http.get(`mealTypes`, { filter })
      );
      data = data.filter((el) => el.id != deletedElemId);
      data.sort((a, b) => a.orderIndex - b.orderIndex);
      data = await Promise.all(
        data?.map(async (kitchen) => await http.get(`mealTypes/${kitchen.id}`))
      );
      data.sort((a, b) => a.orderIndex - b.orderIndex);
      setAllData(data);
    };
    fetchData1();
  }, [dummy, kitchenvalue]);
  const handleChange = (name, value) => {
    let valueformat = value;
    if (name === 'from' || name === 'to') {
      valueformat = valueformat && valueformat.format('HH:mm');
      if (name === 'to' && dataValue.from.length) {
        let isValid = checkDate(dataValue.from, valueformat);
        if (!isValid) {
          return setErrors({
            ...errors,
            to: 'End time must greater than Start time',
          });
        } else {
          setErrors({ ...errors, to: '', from: '' });
        }
      } else if (name === 'from' && dataValue.to.length) {
        let isValid = checkDate(valueformat, dataValue.to);
        if (!isValid) {
          return setErrors({
            ...errors,
            from: 'Start time must less than End time',
          });
        } else {
          setErrors({ ...errors, from: '', to: '' });
        }
      }
    }
    setDataValue({ ...dataValue, [name]: valueformat });
    // setPayload({ ...payload, [name]: value });
  };

  /******* add New mealtype */
  const handleMealType = async (mealData) => {
    // setDummy(false);
    try {
      let mealToAdd;
      await useFetchingLoader(async () => {
        mealToAdd = await http.post(`mealTypes/create`, {
          ...mealData,
        });
      });
      // setDataValue(initialState);
      setTimeout(() => {
        Dialog({
          title: t('addedSuccessfully'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: async () => {
            setDataValue(initialState);
            setTranslations({});
            setIsAdd(false);
            handleMeal();
            await useFetchingLoader(async () => {
              await getMealTypes();
            });
            setDummy(!dummy);
          },
        });
      }, 150);
    } catch (err) {
      if (err === 'error') {
        setTimeout(() => Alert({ error: t('orderIndexerror') }), 150);
        return err;
      } else if (err === 'error1') {
        setTimeout(() => Alert({ error: t('orderZeroError') }), 150);
        return err;
      } else {
        setTimeout(() => Alert({ error: err }), 150);
        return err;
      }
    }
  };
  const handleEdit = (id) => {
    // setIsEdit(id);
    // setIsAdd(null);
    // setDummy(false);
    // setTranslations({});
    // setErrors('');
    let dd = allData.filter((el) => el.id === id);

    setIsEdit(dd[0]);
    setEditMeal(!editMeal);
    setAddMeal(!addMeal);
  };

  const handleAddClick = () => {
    setTranslations({});
    setIsAdd(true);
    setDataValue(initialState);
    setErrors('');
  };

  const handleDelete = (id) => {
    Dialog({
      title: t('deleteMealTypeConfirmation'),
      type: 'success',
      buttons: {
        showCancelButton: true,
        confirmButtonText: t('confirm'),
      },
      onConfirm: async () => {
        try {
          await useFetchingLoader(async () => {
            await http.put(`mealTypes/deleteMealType/${id}`);
          });
          setDeletedElemId(id);
          setTimeout(() => {
            Dialog({
              title: t('deletedSuccessfully'),
              type: 'success',
              buttons: {
                showCancelButton: false,
                confirmButtonText: t('confirm'),
              },
              onConfirm: async () => {
                await useFetchingLoader(async () => {
                  await getMealTypes();
                });
                setDummy(!dummy);
              },
            });
          }, 150);
        } catch (err) {
          setTimeout(() => Alert({ error: err }), 150);
        }
      },
    });
  };

  const handleMealstype = async (data, e) => {
    if (e) {
      e.preventDefault();
    }
    setMealTypeSlot(data);
    let getAllSlottype;
    let filter = {
      mealTypeId: data.id,
    };
    await useFetchingLoader(async () => {
      getAllSlottype = await http.get(`mealTypesSlots/getAll`, { filter });
    });
    setAllMealtypeSlot(getAllSlottype);
    setMealSlot(true);
  };

  /******* add New mealslot */
  const handleMealslot = async (userData) => {
    let mealslotAdd;
    setDummy(false);
    try {
      const newData = await useFetchingLoader(async () => {
        mealslotAdd = await http.post(`mealTypesSlots/create`, {
          ...userData,
        });
      });
      setTimeout(() => {
        Dialog({
          title: t('addedSuccessfully'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setPayload(initialValues);
            setDummy(true);
          },
        });
      }, 150);
      setDummy(false);
      setAllMealtypeSlot((prev) => {
        const prevArr = [...prev.data];
        prevArr.push({ ...userData, id: mealslotAdd[0].id });
        return { ...prev, data: [...prevArr] };
      });
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const arrr = allData[0]?.translations;
  let reducedTranslations1 =
    (arrr &&
      arrr.reduce(
        (acc, translation) => ({
          ...acc,
          [translation.languageId]: { ...translation, value: '' },
        }),
        {}
      )) ||
    {};
  let reducedTranslations =
    arrr &&
    arrr.reduce(
      (pre, next) =>
        parseFloat(pre || 0) +
        parseFloat(next.languageId ? next.languageId : 0),
      0
    );
  const [translations, setTranslations] = useState(reducedTranslations1);
  const [translationsForReports, setTranslationsForReports] =
    useState(reducedTranslations1);
  const [toggleValueMultiDish, setToggleValueMultiDish] = useState(0);
  const [toggleValueMultiReservations, setToggleValueMultiReservations] =
    useState(0);
  const [toggleValueIsPickup, setToggleValueIsPickup] = useState(0);
  const [toggleValueSalaryFile, settoggleValueSalaryFile] = useState(0);
  const [toggleValueCalculatePrice, settoggleValueCalculatePrice] = useState(0);

  const [toggleValueAddMultiDish, setToggleValueAddMultiDish] = useState(0);
  const [toggleValueAddMultiReservation, setToggleValueAddMultiReservation] =
    useState(0);
  useEffect(() => {}, [translations]);

  useEffect(() => {
    if (isEdit) {
      let editData = allData && allData.filter((el) => el.id === isEdit.id);
      setToggleValueMultiDish(editData[0].multipleDish);
      setToggleValueMultiReservations(editData[0].multipleReservations);
      setToggleValueIsPickup(editData[0].isPickup ? 1 : 0);
      settoggleValueSalaryFile(editData[0].includeSalary);
      settoggleValueCalculatePrice(editData[0].calculateDishPrice);
    }
  }, [allData, isEdit]);

  const handleMeal = () => {
    setIsEdit('');
    setAddMeal(!addMeal);
    setTranslations({});
  };

  const handleMoreDetails = (id) => {
    setMoreDetails(!moreDetails);
    let alldata = allData.filter((el) => el.id === id);
    setMoreDetailsData(alldata[0]);
  };

  return (
    <div className="kitchen-management meal-type-management">
      <div className="container">
        <div className={'add-mealtype'}>
          <WEBackButton text={'Back'} redirectHistory={backLink} />
          <Button onClick={handleMeal}>
            <i class="las la-plus"></i> {t('add')}
          </Button>
          {/* handleAddClick */}
        </div>
        <div className={'kitchens'}>
          {isAdd && (
            <MealType
              key={'meal-add'}
              isActive={isActive}
              Languages={Languages}
              t={t}
              MealTypes={MealTypes}
              handleChange={handleChange}
              dataValue={dataValue}
              handleMealType={handleMealType}
              kitchenId={kitchenvalue}
              allData={allData}
              CurrentLanguage={CurrentLanguage}
              setIsAdd={setIsAdd}
              toggleBtn={toggleBtn}
              toggleValueAddMultiDish={toggleValueAddMultiDish}
              toggleValueAddMultiReservation={toggleValueAddMultiReservation}
              setToggleValueAddMultiDish={setToggleValueAddMultiDish}
              setToggleValueAddMultiReservation={
                setToggleValueAddMultiReservation
              }
              errors={errors}
              setErrors={setErrors}
              translations={translations}
              setTranslations={setTranslations}
              data={{
                value: '',
                translations: Object.values(Languages).map((language) => ({
                  value: '',
                  languageId: language.id,
                })),
              }}
            />
          )}
          <div className="mealtype-table">
            <div className="table-header">
              <h6>{t('orderIndex')}</h6>
              <h6>{t('mealName')}</h6>
              <h6>{t('startTime')}</h6>
              <h6>{t('endTime')}</h6>
              <h6>{t('extendalId')}</h6>
              <h6>{t('creditPoints')}</h6>
              <h6>{t('cancellationTime')}</h6>
              <h6>{t('multipleDish')}</h6>
              <h6>{t('multipleReservations')}</h6>
              <h6>{t('price')}</h6>

              {paymentMethodsID == 4 ? (
                <h6>{t('is_include_sallary_file')}</h6>
              ) : (
                ''
              )}
              <h6>{t('is_calculate_Price')}</h6>
              <h6></h6>
              <h6 className="mob-view"></h6>
            </div>
            {allData.length > 0 ? (
              allData.map((el, i) => (
                <MealTypeListing
                  key={el.id}
                  isActive={isActive}
                  Languages={Languages}
                  t={t}
                  MealTypes={MealTypes}
                  handleChange={handleChange}
                  dataValue={dataValue}
                  handleMealType={handleMealType}
                  kitchenId={kitchenvalue}
                  data={el}
                  handleEdit={handleEdit}
                  isEdit={isEdit}
                  handleDelete={handleDelete}
                  handleMealstype={handleMealstype}
                  allData={allData}
                  CurrentLanguage={CurrentLanguage}
                  setDummy={setDummy}
                  dummy={dummy}
                  translations={translations}
                  setTranslations={setTranslations}
                  transData={transData}
                  setTransData={setTransData}
                  reducedTranslations1={reducedTranslations1}
                  setErrors={setErrors}
                  errors={errors}
                  handleMoreDetails={handleMoreDetails}
                  paymentMethodsID={paymentMethodsID}
                  setIsDisplayMealTypeReminder={setIsDisplayMealTypeReminder}
                  setMealTypeId={setMealTypeId}
                  setIsEditReminder={setIsEditReminder}
                  setReminderValues={setReminderValues}
                />
              ))
            ) : (
              <div className="no-mealtype">{t('UnavailableMealTypes')}</div>
            )}
          </div>
        </div>
        {/* {mealSlot && ( */}

        <MealSlotPopup
          useFetchingLoader={useFetchingLoader}
          allMealtypeSlot={allMealtypeSlot}
          open={mealSlot}
          handleClose={handleMealstype}
          setMealSlot={setMealSlot}
          mealSlot={mealSlot}
          t={t}
          mealTypeSlot={mealTypeSlot}
          handleMealslot={handleMealslot}
          setAllMealtypeSlot={setAllMealtypeSlot}
          payload={payload}
          setPayload={setPayload}
          paymentMethodsID={paymentMethodsID}
        />
        <MealTypeReminder
          useFetchingLoader={useFetchingLoader}
          mealTypeReminderValues={reminderValues}
          open={isDisplayMealTypeReminder}
          mealTypeId={mealTypeId}
          isEditReminder={isEditReminder}
          setDummy={setDummy}
          handleClose={() => {
            setIsDisplayMealTypeReminder(false);
            setIsEditReminder(false);
            setReminderValues(mealTypeReminderInit);
          }}
          t={t}
        />
        {/* )} */}
        {addMeal && (
          <AddMealType
            show={addMeal}
            handleClose={handleMeal}
            t={t}
            key={'meal-add'}
            isActive={isActive}
            resetDataValue={resetDataValue}
            Languages={Languages}
            MealTypes={MealTypes}
            handleChange={handleChange}
            dataValue={dataValue}
            handleMealType={handleMealType}
            kitchenId={kitchenvalue}
            allData={allData}
            CurrentLanguage={CurrentLanguage}
            setIsAdd={setIsAdd}
            errors={errors}
            setErrors={setErrors}
            translations={translations}
            setTranslations={setTranslations}
            useFetchingLoader={useFetchingLoader}
            data={{
              value: '',
              translations: Object.values(Languages).map((language) => ({
                value: '',
                languageId: language.id,
              })),
            }}
            setDummy={setDummy}
            isEdit={isEdit}
            Kitchens={Kitchens}
            setTransData={setTransData}
            setEditMeal={setEditMeal}
            toggleValueMultiDish={toggleValueMultiDish}
            toggleValueMultiReservations={toggleValueMultiReservations}
            setToggleValueMultiDish={setToggleValueMultiDish}
            setToggleValueMultiReservations={setToggleValueMultiReservations}
            setToggleValueIsPickup={setToggleValueIsPickup}
            toggleValueIsPickup={toggleValueIsPickup}
            toggleValueSalaryFile={toggleValueSalaryFile}
            settoggleValueSalaryFile={settoggleValueSalaryFile}
            toggleValueCalculatePrice={toggleValueCalculatePrice}
            settoggleValueCalculatePrice={settoggleValueCalculatePrice}
            editMeal={editMeal}
            paymentMethodsID={paymentMethodsID}
          />
        )}
        {moreDetailsdata && (
          <ShowMoreDetails
            handleClose={handleMoreDetails}
            show={moreDetails}
            moreDetailsdata={moreDetailsdata}
            setTranslations={setTranslations}
            CurrentLanguage={CurrentLanguage}
            handleMealstype={handleMealstype}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            t={t}
            paymentMethodsID={paymentMethodsID}
            setMealTypeId={setMealTypeId}
            setReminderValues={setReminderValues}
            setIsEditReminder={setIsEditReminder}
            setIsDisplayMealTypeReminder={setIsDisplayMealTypeReminder}
          />
        )}
      </div>
    </div>
  );
};

const MealTypeListing = ({
  isActive,
  Languages,
  t,
  MealTypes,
  handleChange,
  dataValue,
  handleMealType,
  kitchenId,
  data,
  handleEdit,
  isEdit,
  handleDelete,
  handleMealstype,
  allData,
  CurrentLanguage,
  dummy,
  setDummy,
  translations,
  setTranslations,
  reducedTranslations1,
  setTransData,
  transData,
  setErrors,
  errors,
  handleMoreDetails,
  toggleBtn,
  setDataValue,
  toggleValueMultiDish,
  toggleValueMultiReservations,
  setToggleValueMultiDish,
  setToggleValueMultiReservations,
  paymentMethodsID,
  setIsDisplayMealTypeReminder,
  setMealTypeId,
  setIsEditReminder,
  setReminderValues,
}) => {
  const [value, setvalue] = useState('');
  const [allValues, setAllValues] = useState(data);
  useEffect(() => {
    setAllValues(data);
    if (isEdit === data.id) {
      let dummyData = {};
      data.translations.forEach((el) => (dummyData[el.languageId] = el));
      setTranslations((prev) => {
        return { ...prev, ...dummyData };
      });
    } else {
      let dummyData = data.translations.filter(
        (el) => el.languageId === CurrentLanguage.id
      );
      setTransData((prev) => {
        return { ...prev, [data.id]: dummyData[0]?.value };
      });
      setvalue(dummyData[0]?.value);
    }
  }, [isEdit, data]);
  return (
    <div className={`item blocked-listing`}>
      <form className={`add-mealtypes`}>
        <div className="language-input order-index">
          <p>{allValues.orderIndex}</p>
        </div>
        <div className="language-input">
          <p>{value}</p>
        </div>
        <div className="language-input">
          <p>{allValues.from}</p>
        </div>
        <div className="language-input">
          <p>{allValues.to}</p>
        </div>
        <div className="language-input">
          <p>{allValues.externalId}</p>
        </div>
        <div className="language-input">
          <p>{allValues.creditPoints}</p>
        </div>
        <div className="language-input">
          <p>{allValues.cancellationTime}</p>
        </div>
        <div className="language-input toggle-mealtype">
          <button
            type="button"
            className={`toggle-btn ${
              allValues.multipleDish === 1 ? 'toggle-btn-on' : 'toggle-btn-off'
            }`}
          >
            <span></span>
          </button>
        </div>
        <div className="language-input toggle-mealtype">
          <button
            type="button"
            className={`toggle-btn ${
              allValues.multipleReservations === 1
                ? 'toggle-btn-on'
                : 'toggle-btn-off'
            }`}
          >
            <span></span>
          </button>
        </div>
        <div className="language-input">
          <p>{allValues.price}</p>
        </div>

        {paymentMethodsID == 4 ? (
          <div className="language-input toggle-mealtype">
            <button
              type="button"
              className={`toggle-btn ${
                allValues.includeSalary == '1'
                  ? 'toggle-btn-on'
                  : 'toggle-btn-off'
              }`}
            >
              <span></span>
            </button>
          </div>
        ) : (
          ''
        )}
        <div className="language-input toggle-mealtype">
          <button
            type="button"
            className={`toggle-btn ${
              allValues.calculateDishPrice == '1'
                ? 'toggle-btn-on'
                : 'toggle-btn-off'
            }`}
          >
            <span></span>
          </button>
        </div>
        <section className={`add-btn`}>
          <div
            className={`icon-wrapper`}
            onClick={(e) => {
              setMealTypeId(allValues.id);
              if (allValues?.mealTypesReminders?.id) {
                setReminderValues(allValues.mealTypesReminders);
                setIsEditReminder(true);
              }
              setIsDisplayMealTypeReminder(true);
            }}
          >
            <i class="las la-bell"></i>
          </div>
          <div
            className={`icon-wrapper`}
            onClick={(e) => handleMealstype(allValues, e)}
          >
            <i class="las la-plus"></i>
          </div>
          <i
            class="las la-pencil-alt"
            onClick={() => handleEdit(allValues.id)}
          ></i>
          <i
            class="las la-trash"
            onClick={() => handleDelete(allValues.id)}
          ></i>
        </section>
        <section className="mob-view">
          <i
            class="las la-angle-right"
            onClick={() => handleMoreDetails(allValues.id)}
          ></i>
        </section>
      </form>
      {/* {value === 'Cafeteria' && (
        <div className="cafe-img">
          <img src={cafe} alt="cafe" />
        </div>
      )} */}
    </div>
  );
};

const MealType = ({
  isActive,
  Languages,
  t,
  MealTypes,
  handleChange,
  dataValue,
  handleMealType,
  kitchenId,
  allData,
  CurrentLanguage,
  data,
  setIsAdd,
  errors,
  setErrors,
  translations,
  setTranslations,
  toggleBtn,
  toggleValueAddMultiDish,
  setToggleValueAddMultiReservation,
  setToggleValueAddMultiDish,
}) => {
  useEffect(() => {
    let dummyData = {};
    Languages.forEach((el) => (dummyData[el.id] = el));
    setTranslations(dummyData);
  }, []);

  const handleSubmitToggleBtn = (data) => {
    let value = 0;
    if (data === 0) {
      value = 1;
    }
    // editData[0].multipleDish = value;
    setToggleValueAddMultiDish(value);
    // dataValue.multipleDish = value;
  };

  const handleSubmitToggleBtnRes = (data) => {
    let value = 0;
    if (data === 0) {
      value = 1;
    }
    setToggleValueAddMultiReservation(value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      value: translations[CurrentLanguage.id].value,
      from: dataValue.from,
      to: dataValue.to,
      kitchenId: kitchenId,
      translations: Object.values(translations),
      externalId: dataValue.externalId,
      orderIndex: dataValue.orderIndex,
      creditPoints: dataValue.creditPoints,
      cancellationTime: dataValue.cancellationTime,
      multipleDish: toggleValueAddMultiDish,
      multipleReservations: toggleValueAddMultiReservation,
    };

    const { isValid, errors } = validateMealType(details);
    setErrors(errors);
    if (isValid) {
      await handleMealType({ ...details });
      setTranslations({});
      setIsAdd(false);
    }
  };
  return (
    <div className={`item ${isActive ? '' : 'blocked'} addmeal-listing`}>
      <form className={`add-mealtypes`}>
        <div className="listing-section">
          <div className="language-input order-index">
            <GetInputField
              placeholder={t('orderIndex')}
              label={t('orderIndex')}
              name="orderIndex"
              type="number"
              // value={}
              cb={(value) => {
                handleChange('orderIndex', value);
              }}
              required
            />
            {errors && <p className="error-msg">{errors.orderIndex}</p>}
          </div>
          {Languages.map((lang) => (
            <div className="language-input" key={lang.id}>
              <GetInputField
                placeholder={`${t('mealName')} (${lang.shortCode})`}
                label={`${t('mealName')} (${lang.shortCode})`}
                name={`name_${lang.id}`}
                type="text"
                value={translations[lang.id]?.value || ''}
                cb={(value) =>
                  setTranslations((state) => ({
                    ...state,
                    [lang.id]: {
                      languageId: lang.id,
                      value: value,
                      display: value,
                    },
                  }))
                }
              />
              {errors && !translations[lang.id]?.value && (
                <p className="error-msg">{errors.translations}</p>
              )}
            </div>
          ))}
          <div className="language-input">
            <label>{t('startTime')}</label>

            <TimePicker
              defaultValue={moment()}
              showSecond={false}
              minuteStep={15}
              name="from"
              onChange={(val) => {
                handleChange('from', val);
              }}
            />
            {errors && <p className="error-msg">{errors.from}</p>}
          </div>
          <div className="language-input">
            <label>{t('endTime')}</label>

            <TimePicker
              defaultValue={moment()}
              showSecond={false}
              minuteStep={15}
              name="to"
              onChange={(val) => {
                handleChange('to', val);
              }}
            />
            {errors && <p className="error-msg">{errors.to}</p>}
          </div>
          <div className="language-input">
            <GetInputField
              placeholder={t('extendalId')}
              label={t('extendalId')}
              name="extenralID"
              type="text"
              // value={}
              cb={(value) => {
                handleChange('externalId', value);
              }}
              required
            />
            {errors && <p className="error-msg">{errors.externalId}</p>}
          </div>

          <div className="language-input">
            <GetInputField
              placeholder={t('Credit Points')}
              label={t('Credit Points')}
              name="creditPoints"
              type="number"
              // value={}
              cb={(value) => {
                handleChange('creditPoints', value);
              }}
            />
          </div>
          <div className="language-input">
            <GetInputField
              placeholder={t('cancellationTime')}
              label={t('cancellationTime')}
              name="cancellationTime"
              type="text"
              // value={}
              cb={(value) => {
                handleChange('cancellationTime', value);
              }}
            />
          </div>
          <div className="language-input toggle-mealtype">
            <span>{t('multipleReservations')}</span>
            <button
              type="button"
              className={`toggle-btn ${
                toggleValueAddMultiReservation === 1
                  ? 'toggle-btn-on'
                  : 'toggle-btn-off'
              }`}
              onClick={() =>
                handleSubmitToggleBtnRes(toggleValueAddMultiReservation)
              }
            >
              <span></span>
            </button>
          </div>
        </div>

        <section className={`add-btn`} onClick={handleFormSubmit}>
          <ActiveButton label={t('add')} arrow={true} />
        </section>
      </form>
    </div>
  );
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      {/* <label>{label}</label> */}
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        type={type}
      />
    </>
  );
};

const ShowMoreDetails = ({
  show,
  handleClose,
  moreDetailsdata,
  setTranslations,
  CurrentLanguage,
  handleMealstype,
  handleEdit,
  handleDelete,
  t,
  setMealTypeId,
  setReminderValues,
  setIsEditReminder,
  setIsDisplayMealTypeReminder,
}) => {
  let displayName = moreDetailsdata?.translations?.filter(
    (el) => el.languageId == CurrentLanguage.id
  );
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-mealtypes-popup details-meal-typ-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Meal Type Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className={`add-mealtypes`}>
          <div className="more-details-popup">
            <div className="list-details">
              <h6>{t('orderIndex')}</h6>
              <p>{moreDetailsdata?.orderIndex}</p>
            </div>
            <div className="list-details">
              <h6>{t('name')}:</h6>
              <p>{displayName[0]?.value}</p>
            </div>
            <div className="list-details">
              <h6>{t('startTime')}:</h6>
              <p>{moreDetailsdata?.from}</p>
            </div>
            <div className="list-details">
              <h6>{t('endTime')}:</h6>
              <p>{moreDetailsdata?.to}</p>
            </div>
            <div className="list-details">
              <h6>{t('extendalId')}:</h6>
              <p>{moreDetailsdata?.externalId}</p>
            </div>
            <div className="list-details">
              <h6>{t('creditPoints')}:</h6>
              <p>{moreDetailsdata?.creditPoints}</p>
            </div>
            <div className="list-details">
              <h6>{t('cancellationTime')}:</h6>
              <p>{moreDetailsdata?.cancellationTime}</p>
            </div>
            <div className="list-details toggle-mealtype">
              <h6>{t('multipleDish')}:</h6>
              <p>
                <button
                  type="button"
                  className={`toggle-btn ${
                    moreDetailsdata.multipleDish === 1
                      ? 'toggle-btn-on'
                      : 'toggle-btn-off'
                  }`}
                >
                  <span></span>
                </button>
              </p>
            </div>
            <div className="list-details toggle-mealtype">
              <h6>{t('multipleReservations')}:</h6>
              <p>
                <button
                  type="button"
                  className={`toggle-btn ${
                    moreDetailsdata.multipleReservations === 1
                      ? 'toggle-btn-on'
                      : 'toggle-btn-off'
                  }`}
                >
                  <span></span>
                </button>
              </p>
            </div>
            <div className="list-details toggle-mealtype">
              <h6>{t('is_calculate_Price')}:</h6>
              <p>
                <button
                  type="button"
                  className={`toggle-btn ${
                    moreDetailsdata.calculateDishPrice === 1
                      ? 'toggle-btn-on'
                      : 'toggle-btn-off'
                  }`}
                >
                  <span></span>
                </button>
              </p>
            </div>
            <div className="list-details toggle-mealtype">
              <h6>{t('is_include_sallary_file')}:</h6>
              <p>
                <button
                  type="button"
                  className={`toggle-btn ${
                    moreDetailsdata.includeSalary === 1
                      ? 'toggle-btn-on'
                      : 'toggle-btn-off'
                  }`}
                >
                  <span></span>
                </button>
              </p>
            </div>
            {/* <div className="list-details">
              <h6>Action:</h6>
              <p>
                <section className={`actions`}>
                  <div
                    className={`icon-wrapper`}
                    onClick={(e) => handleMealstype(moreDetailsdata, e)}
                  >
                    <i class="las la-plus"></i>
                  </div>
                  <i
                    class="las la-pencil-alt"
                    onClick={() => handleEdit(moreDetailsdata.id)}
                  ></i>
                  <i
                    class="las la-trash"
                    onClick={() => handleDelete(moreDetailsdata.id)}
                  ></i>
                </section>
              </p>
            </div> */}
          </div>
          <div className="modal-footer-btn">
            <Button onClick={(e) => handleMealstype(moreDetailsdata, e)}>
              <i class="las la-plus"></i>
              {t('add')}
            </Button>
            <Button onClick={() => handleEdit(moreDetailsdata.id)}>
              <i class="las la-pen" title="edit"></i>
              {t('edit')}
            </Button>
            <Button onClick={() => handleDelete(moreDetailsdata.id)}>
              <i class="las la-trash" title="delete"></i>
              {t('delete')}
            </Button>
            <Button onClick={() => handleDelete(moreDetailsdata.id)}>
              <i
                class="las la-bell"
                title="reminder"
                onClick={(e) => {
                  setMealTypeId(allValues.id);
                  if (allValues?.mealTypesReminders?.id) {
                    setReminderValues(allValues.mealTypesReminders);
                    setIsEditReminder(true);
                  }
                  setIsDisplayMealTypeReminder(true);
                }}
              ></i>
              {t('createReminder')}
            </Button>
          </div>

          {/* <div className="add-btn">
            <Button onClick={handleClose}>Close</Button>
          </div> */}
        </form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  KitchenStatuses: state.app.enums.KitchenStatus,
  Kitchens: state.kitchens.options,
  CompanyId: state.user.companyId,
  Languages: state.languages.options,
  CurrentLanguage: state.languages.current,
  MealTypes: state.app.enums.MealType,
});

const mapDispatchToProps = {
  useLoader,
  useFetchingLoader,
  getMealTypes,
  showAlert,
  setLayoutOptions,
  getPaymentPreferences,
};
export default connect(mapStateToProps, mapDispatchToProps)(MealTypes);
