import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import {
  validateEmployee,
  validateEmployeenew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import http from '../../services/http';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  borderRadius: '5px',
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
  editMode = true,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(event) => cb(event.target.value)}
        required
        disabled={!editMode}
        type={type}
      />
    </>
  );
};

const AddContractorEmployeePopup = ({
  open,
  handleClose,
  handleAddNewUser,
  Roles,
  Kitchens,
  UserStatuses,
  setPayload,
  payload,
  handleUpdateUser,
  setIsEdit,
  isUpdate,
  userData,
  setKitchensIds,
  kitchensIds,
  t,
  i18n,
  errors,
  setErrors,
  currentUserKitchens,
  user,
  contractorList,
  cardGenerationUrlForUser,
  cardNumberLengthForUser,
  employeeNumberLengthForUser,
  isStrippedCard,
  cibusEnabled,
  fieldNamesSettings,
}) => {
  const [cardGenerationFunc, setCardGenerationFunc] = useState();
  useEffect(() => {
    if (cardGenerationUrlForUser && open) {
      const fetch = async () => {
        const response = await http.get(cardGenerationUrlForUser);
        const func = new Function(`return ${response.cardGenerationWD}`);
        setCardGenerationFunc(func);
      };
      fetch();
    }
  }, [open]);
  const KitchensMap = Kitchens.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const RolesMap_ValueId = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const ContractorMap_ValueId = contractorList?.reduce((acc, contractor) => {
    return { ...acc, [contractor.id]: contractor.name };
  }, {});
  const StatusMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.display };
  }, {});
  useEffect(() => {
    setPayload({
      ...payload,
      contractorsIds: payload?.contractors?.map((contractor) => contractor.id),
    });
  }, [payload?.contractors]);

  useEffect(() => {
    if (
      payload?.allContractors &&
      Array.isArray(payload?.allContractors) &&
      (!payload?.contractorsIds || payload?.contractorsIds.length === 0) &&
      payload?.allContractors.length === 1
    ) {
      const elem = payload?.allContractors[0];
      // setSelectContractor([elem?.id]);
      setPayload((prev) => {
        return {
          ...prev,
          contractorsIds: [elem?.id],
          magneticStripe: elem?.magneticStripe ?? '',
        };
      });
    }

    if (
      currentUserKitchens &&
      Array.isArray(currentUserKitchens) &&
      (!payload?.kitchensIds || payload?.kitchensIds.length === 0) &&
      currentUserKitchens.length === 1
    ) {
      setKitchensIds([currentUserKitchens[0].id]);
      setPayload((prev) => {
        return {
          ...prev,
          kitchensIds: [currentUserKitchens[0].id],
        };
      });
    }
  }, [payload?.allContractors, currentUserKitchens, open]);

  const [role] = useState(4);
  const [pError, setPError] = useState(false);
  const [status, setStatus] = useState({ value: 3, label: 'Active' });

  const handleChange = (name, value) => {
    if (cardGenerationUrlForUser && cardGenerationFunc) {
      if (name === 'employeeId' && value) {
        const result = cardGenerationFunc(value);
        setPayload((prev) => ({ ...prev, card: result }));
      }
      if (name === 'employeeId' && !value) {
        setPayload((prev) => ({ ...prev, card: '' }));
      }
    }

    const { isValid, error } = validateEmployee(
      value,
      name,
      cardNumberLengthForUser,
      employeeNumberLengthForUser
    );
    setErrors({ ...errors, ...error });
    setPayload((prev) => ({ ...prev, [name]: value }));
  };
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      backgroundColor: 'transparent',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
      borderRadius: '5px',
    }),
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      firstName: payload.fName,
      lastName: payload.lName,
      email: payload.email,
      phone: payload.phone,
      // userStatusId:userData.find(el => el.id === payload.userId)?.userStatusId,
      userStatusId: { value: 3, label: 'Active' },
      // kitchensIds: userData
      //   .find((el) => el.id === payload.userId)
      //   ?.kitchens.map((ele) => ele.id),
      password: payload.password === '' ? undefined : payload.password,
      cardId:
        !payload.card || payload.card === '' || payload.card === '--'
          ? null
          : isStrippedCard
            ? payload.card.replace(/^0+/, '')
            : payload.card,
      employeeId: payload.employeeId,
      roleId: RolesMap_ValueId.contractorEmployee,
      contractorsIds: payload.contractorsIds,
      magneticStripe: payload.magneticStripe,
      kitchensIds,

      // value: status,
    };

    const { isValid, errors } = validateEmployeenew(
      details,
      cardNumberLengthForUser,
      employeeNumberLengthForUser
    );
    setErrors(errors);
    if (isValid) {
      await handleAddNewUser({ ...details });
      // handleClose();
    }
  };

  const CheckPassword = (value) => {
    if (
      value.label === 'Active' &&
      (payload.password === '' || !payload.password)
    ) {
      /*setPError(true);
      // setErrors({ ...errors, password: 'Password is required' });*/
      setPayload({ ...payload, status: 'Active' });
    } else {
      setPError(false);
      setStatus(value);
      setPayload({ ...payload, status: value.label });
      setErrors({ ...errors, password: '' });
    }
  };
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div>
          <div className="close-btn">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            className="create-cont-emp-title"
          >
            {isUpdate
              ? t('updateContractorEmployee')
              : t('addContractorEmployee')}
          </Typography>
        </div>
        <Box
          id="modal-modal-description"
          className="width-add-user add-user-max-height"
        >
          <form className="add-user-form">
            <div className="user-create-form item user-create-form-adaptivity">
              <div className="user-create-block-wrapper">
                <p className="user-create-title">{t('mainInfo')}</p>
                <div className="user-create-block">
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('firstName')}
                      label={t('firstName') + ' *'}
                      name="fName"
                      type="text"
                      value={payload['fName']}
                      cb={(value) => {
                        handleChange('fName', value);
                      }}
                    />
                    {errors && (
                      <p className="error-msg">
                        {errors.fName || errors.firstName}
                      </p>
                    )}
                  </div>
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('lastName')}
                      label={t('lastName') + ' *'}
                      name="lName"
                      type="text"
                      value={payload['lName']}
                      cb={(value) => {
                        handleChange('lName', value);
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">
                        {errors.lName || errors.lastName}
                      </p>
                    )}
                  </div>
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('phone')}
                      label={t('phone')}
                      name="phone"
                      type="number"
                      value={payload['phone']}
                      cb={(value) => {
                        handleChange('phone', value);
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">
                        {errors.phone || errors.phone}
                      </p>
                    )}
                  </div>

                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('email')}
                      label={t('email') + ' *'}
                      name="email"
                      type="email"
                      value={payload['email']}
                      cb={(value) => {
                        handleChange('email', value);
                      }}
                      required
                    />
                    {errors && <p className="error-msg">{errors.email}</p>}
                  </div>
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('password')}
                      label={t('password')}
                      name="password"
                      type="password"
                      value={payload['password']}
                      cb={(value) => {
                        handleChange('password', value);
                      }}
                      required
                    />
                    {pError && (
                      <p className="error-msg">Password is required</p>
                    )}
                    {errors && <p className="error-msg">{errors.password}</p>}
                  </div>
                </div>
              </div>
              <div className="line"></div>
              <div className="user-create-block-wrapper">
                <p className="user-create-title">{t('Settings')}</p>
                <div className="user-create-block">
                  <div className="input-field w-75">
                    <label>{t('kitchenname') + ' *'}</label>
                    <Select
                      isMulti
                      name={'kitchensIds'}
                      value={kitchensIds?.map((kitchenId) => {
                        return {
                          value: kitchenId,
                          label: KitchensMap[kitchenId],
                        };
                      })}
                      onChange={(newData) => {
                        setKitchensIds(newData.map((k) => k.value));
                        setPayload({
                          ...payload,
                          kitchensIds: newData.map((k) => k.value),
                        });
                      }}
                      options={currentUserKitchens.map((kitchen) => {
                        return { value: kitchen.id, label: kitchen.name };
                      })}
                      placeholder={t('kitchenname')}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
                        ...SelectStyles,
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">{errors.kitchensIds}</p>
                    )}
                  </div>
                  <div className="input-field w-75">
                    <label>{t('contractor') + ' *'}</label>
                    <Select
                      name={'contractorsIds'}
                      value={
                        payload.contractorsIds &&
                        payload.contractorsIds.length !== 0
                          ? payload.contractorsIds.map((elem) => {
                              return {
                                value: elem,
                                label: ContractorMap_ValueId[elem],
                                disableDelete:
                                  payload?.allContractors &&
                                  payload?.contractors &&
                                  !Boolean(
                                    payload?.allContractors.find(
                                      (el) => el.id === elem
                                    ) &&
                                      payload?.contractors.find(
                                        (el) => el.id === elem
                                      )
                                  ),
                              };
                            })
                          : payload.contractors &&
                              payload.contractors.length !== 0
                            ? payload.contractors.map((elem) => {
                                return {
                                  value: elem.id,
                                  label: ContractorMap_ValueId[elem.id],
                                  disableDelete:
                                    payload?.allContractors &&
                                    payload?.contractors &&
                                    !Boolean(
                                      payload?.allContractors.find(
                                        (el) => el.id === elem
                                      ) &&
                                        payload?.contractors.find(
                                          (el) => el.id === elem
                                        )
                                    ),
                                };
                              })
                            : []
                      }
                      onChange={(newData, triggeredAction) => {
                        if (
                          triggeredAction.action === 'remove-value' &&
                          triggeredAction.removedValue.disableDelete
                        ) {
                          Dialog({
                            title: t('removeForeignContractor'),
                            type: 'error',
                            buttons: {
                              showCancelButton: false,
                              confirmButtonText: t('confirm'),
                            },
                          });
                          return;
                        } else {
                          setPayload({
                            ...payload,
                            contractorsIds: [newData.value],
                            magneticStripe:
                              contractorList.find(
                                (el) => el.id === newData.value
                              )?.magneticStripe ?? '',
                          });
                        }
                      }}
                      options={
                        payload?.allContractors
                          ? payload?.allContractors?.map((contractors) => {
                              return {
                                value: contractors.id,
                                label: [contractors.name],
                                disableDelete: payload?.contractors
                                  ? payload?.contractors.length === 0
                                    ? false
                                    : !payload?.contractors.find(
                                        (el) =>
                                          el.label ===
                                          ContractorMap_ValueId[contractors.id]
                                      )
                                  : false,
                              };
                            })
                          : user.contractors?.map((contractors) => {
                              return {
                                value: contractors.id,
                                label: [contractors.name],
                                disableDelete: payload?.contractors
                                  ? payload?.contractors.length === 0
                                    ? false
                                    : !payload?.contractors.find(
                                        (el) =>
                                          el.label ===
                                          ContractorMap_ValueId[contractors.id]
                                      )
                                  : false,
                              };
                            })
                      }
                      placeholder={t('contractor')}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
                        ...SelectStyles,
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">{errors.contractorsIds}</p>
                    )}
                  </div>
                  {/* <div className="input-field w-75">
                <label>{t('contractor')}</label>
                <Select
                  // isMulti
                  name={'contractorId'}
                  defaultValue={{
                    value: payload?.contractor,
                    label:
                      ContractorMap_ValueId &&
                      ContractorMap_ValueId[payload?.contractor],
                  }}
                  onChange={(newData) => {
                    // setRolesIds(newData.value);
                    setPayload({ ...payload, contractorId: newData.value });
                  }}
                  options={contractorList?.map((contractors) => {
                    return {
                      value: contractors.id,
                      label: [contractors.name],
                    };
                  })}
                  placeholder={t('contractor')}
                  styles={SelectStyles}
                  required
                /> */}
                  {/* {errors.role && <p className="error-msg">Role is required</p>} */}
                  {/* </div> */}

                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={
                        fieldNamesSettings?.find(
                          (el) => el.value === 'employeeId'
                        )
                          ? fieldNamesSettings
                              ?.find((el) => el.value === 'employeeId')
                              ?.translations.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('employeeNo')
                      }
                      label={
                        fieldNamesSettings?.find(
                          (el) => el.value === 'employeeId'
                        )
                          ? fieldNamesSettings
                              ?.find((el) => el.value === 'employeeId')
                              ?.translations.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('employeeNo')
                      }
                      name="employee"
                      type="number"
                      value={payload['employeeId']}
                      cb={(value) => {
                        handleChange('employeeId', value);
                      }}
                      required
                    />
                    {errors && <p className="error-msg">{errors.employeeNo}</p>}
                  </div>
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={
                        fieldNamesSettings?.find((el) => el.value === 'cardId')
                          ? fieldNamesSettings
                              ?.find((el) => el.value === 'cardId')
                              ?.translations.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('cardNo')
                      }
                      label={
                        fieldNamesSettings?.find((el) => el.value === 'cardId')
                          ? fieldNamesSettings
                              ?.find((el) => el.value === 'cardId')
                              ?.translations.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('cardNo')
                      }
                      name="card"
                      type="number"
                      value={payload['card']}
                      cb={(value) => {
                        handleChange('card', value);
                      }}
                      editMode={cardGenerationUrlForUser ? false : true}
                      required
                    />
                    {errors && <p className="error-msg">{errors.card}</p>}
                  </div>
                  {user.roleId === 1 || isUpdate ? (
                    <div className="input-field w-75">
                      <label>{t('status')}</label>
                      {/* <Select
                name={'status'}
                value={ { value: 1, label: StatusMap[1] }}
                placeholder={t('status')}
                styles={SelectStyles}
                isMulti
                required
              /> */}
                      <Select
                        name={'status'}
                        defaultValue={
                          UserStatuses.find(
                            (el) => el.display === payload.status
                          )
                            ? {
                                value: UserStatuses.find(
                                  (el) => el.display === payload.status
                                )?.id,
                                label: UserStatuses.find(
                                  (el) => el.display === payload.status
                                )?.display,
                              }
                            : { value: 1, label: StatusMap[1] }
                        }
                        onChange={(newData) => {
                          CheckPassword(newData);
                        }}
                        options={UserStatuses.map((kitchen) => {
                          return { value: kitchen.id, label: kitchen.display };
                        })}
                        placeholder={t('status')}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 2147483647,
                          }),
                          ...SelectStyles,
                        }}
                        // isMulti
                        required
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {cibusEnabled ? (
                    <div className="input-field w-75">
                      <GetInputField
                        placeholder={
                          fieldNamesSettings?.find(
                            (el) => el.value === 'magneticStripe'
                          )
                            ? fieldNamesSettings
                                ?.find((el) => el.value === 'magneticStripe')
                                ?.translations.find(
                                  (el) => el.language.shortCode == i18n.language
                                )?.text
                            : t('magneticStripe')
                        }
                        label={
                          fieldNamesSettings?.find(
                            (el) => el.value === 'magneticStripe'
                          )
                            ? fieldNamesSettings
                                ?.find((el) => el.value === 'magneticStripe')
                                ?.translations.find(
                                  (el) => el.language.shortCode == i18n.language
                                )?.text
                            : t('magneticStripe')
                        }
                        name="magneticStripe"
                        type="number"
                        value={payload['magneticStripe']}
                        cb={(value) => {
                          handleChange('magneticStripe', value);
                        }}
                        editMode={true}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </form>
          {isUpdate ? (
            <div className="add-employee-btn add-cont-emp-btn">
              <div onClick={handleUpdateUser}>
                <ActiveButton label={t('update')} arrow={false} />
              </div>
            </div>
          ) : (
            <div className="add-employee-btn add-cont-emp-btn">
              <div onClick={handleFormSubmit}>
                <ActiveButton label={t('add')} arrow={false} />
              </div>
            </div>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
export default AddContractorEmployeePopup;
