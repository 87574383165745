import React, { useState, useEffect, useCallback } from 'react';
import backarrow from '../../assets/newimages/backarrow.svg';
import menu1 from '../../assets/newimages/menu1.svg';
import menu2 from '../../assets/newimages/menu2.svg';
import menu3 from '../../assets/newimages/menu3.svg';
import menu4 from '../../assets/newimages/menu4.svg';
import menu5 from '../../assets/newimages/menu5.svg';
import menu6 from '../../assets/newimages/menu6.svg';
import menu7 from '../../assets/newimages/menu7.svg';
import menu8 from '../../assets/newimages/menu8.svg';
import category1 from '../../assets/newimages/category1.svg';
import category2 from '../../assets/newimages/category2.svg';
import category3 from '../../assets/newimages/category3.svg';
import category4 from '../../assets/newimages/category4.svg';
import order1 from '../../assets/newimages/order-img-1.png';
import order2 from '../../assets/newimages/order-img-2.png';
import order3 from '../../assets/newimages/order-img-3.png';
import order4 from '../../assets/newimages/order-img-4.png';
import plusIcon from '../../assets/newimages/plus-icon.svg';
import minusIcon from '../../assets/newimages/minus-icon.svg';
import arrowRight from '../../assets/newimages/arrow-right-white.svg';
import star from '../../assets/newimages/star1.svg';
import startrans from '../../assets/newimages/Star2.svg';
import chefDishImg from '../../assets/images/chef_mealDish.png';
import '../../style/yourdish.css';
import { Button, Modal } from 'react-bootstrap';
import AddExtrasPopup from './AddExtrasPopup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { DynamicStars } from '../../components/RatingStars';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import { generateMealDishData } from '../../components/DishComponents/Dish';
import { Box } from '@material-ui/core';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import moment from 'moment';
import defaultImage from '../../assets/images/defaultImage.png';
import MyOrderItem from './MyOrderItem';
import {
  getPaymentPreferences,
  getEmployeeLobbyData,
} from '../../store/kitchens/actions';
import OrderPlacedPopup from './OrderPlacedPopup';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import Swal from 'sweetalert2';
import { DisabledButton } from '../../components/WE-Button.jsx';
import dates from '../../services/dates';
import banner from '../../assets/newimages/banner.svg';
import PaymentMethod from '../../components/OrderComponents/paymentMethod';
import { frontendUrl } from '../../services/utils';
import DishPopup from './DishPopup';
import enums from '../../services/enums';
import { Helmet } from 'react-helmet';
import BackLink from '../../components/BackLink';
import Select from 'react-select';
import ReactDOM from 'react-dom';
import zIndex from '@material-ui/core/styles/zIndex';

const YourDish = ({
  useFetchingLoader,
  kitchen,
  getPaymentPreferences,
  user,
  MealTypes,
  currentLanguage,
  getEmployeeLobbyData,
  emplobbydata,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = useLocation();
  const [mealDetails, setMealDetails] = useState(null);
  const [mealData, setMealData] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [extrasMeal, setExtrasMeal] = useState(0);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [extrasData, setExtrasData] = useState();
  const [extrasPrice, setExtrasPrice] = useState();
  const [addedExtras, setAddedExtras] = useState([]);
  const [isSkip, setIsSkip] = useState(false);
  const [value, setValue] = useState();
  const [paymenttypeData, setPaymentTypeData] = useState();
  const [paymentType, setPaymentType] = useState();
  const [details, setDetails] = useState(null);
  const [mealDishId, setMealDishId] = useState(null);
  const [cancellationTime, setCancellationTime] = useState();
  const [mealExtra, setMealExtra] = useState();
  const [multipleDish, setMultipleDish] = useState(0);
  const [multipleReservation, setMultipleReservation] = useState(0);
  const [checkCreditPoints, setCheckCreditPoints] = useState();
  const [mealDishArray, setMealDishArray] = useState([]);
  const [myOrders, setMyOrders] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const handleMyOrders = () => {
    setMyOrders(!myOrders);
  };
  const [cafeteriaiMealDish, setCafeteriaiMealDish] = useState([]);
  const [kitchenName, setKitchenName] = useState();
  const [update, setUpdate] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(0);
  const [mealSlotId, setMealSlotId] = useState(null);
  const [mealIdForMultiple, setMealIdForMulitple] = useState();
  const [showBalance, setShowBalance] = useState();
  const [removeDishIds, setRemoveDishIds] = useState();
  const [currentPaymentId, setPaymentId] = useState();
  const [currentOrder, setCurrentOrder] = useState();
  const [paymentKitchenId, setPaymentKitchenId] = useState();
  const { backText, backLink } = location.state ?? {};
  const [ordersPopup, setMyOrdersPopup] = useState(false);
  const [filterMealState, setFilterMealState] = useState();
  // Dish Popup
  const [dishPopup, setDishPopup] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [orderedMeals, setOrderedMeals] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [editPerm, setEditPerm] = useState(false);
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '45px',
      minHeight: '45px',
      color: '#656565',
      backgroundColor: 'transparent',
      border: '1px solid #9dc79d',
      boxShadow: 'none',
      borderRadius: '5px',
      zIndex: 9999999,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#4b4b4b',
    }),
    menu: (provided, state) => ({
      ...provided,
      color: '#4b4b4b',
      zIndex: 9999999,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#4b4b4b',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: '#4b4b4b',
      padding: '0 10px',
    }),
    dropdownIndicator: (styles, { data }) => ({
      ...styles,
      color: '#00D100',
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: '#4b4b4b',
    }),
    noOptionsMessage: (styles, { data }) => ({
      ...styles,
      color: '#4b4b4b',
    }),
  };
  const SelectStylesSmall = {
    control: (provided, state) => ({
      ...provided,
      height: '45px',
      minHeight: '45px',
      color: '#656565',
      backgroundColor: 'transparent',
      border: '1px solid #9dc79d',
      boxShadow: 'none',
      borderRadius: '5px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#4b4b4b',
    }),
    menu: (provided, state) => ({
      ...provided,
      color: '#4b4b4b',
      zIndex: 9999999,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#4b4b4b',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: '#4b4b4b',
      padding: '0 7px',
    }),
    dropdownIndicator: (styles, { data }) => ({
      ...styles,
      color: '#00D100',
      padding: '3px',
      ':active': {
        padding: '3px',
      },
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: '#4b4b4b',
    }),
    noOptionsMessage: (styles, { data }) => ({
      ...styles,
      color: '#4b4b4b',
    }),
  };
  const handleDishPopup = () => {
    if (multipleDish || (user.roleId === 8 && user.isMultiOrders)) {
      setDishPopup(!dishPopup);
    } else {
      setOrderData([]);
      setDishPopup(true);
      setOrderDetails([]);
      setDishPopup(!dishPopup);
    }
  };
  useEffect(() => {
    const fetchData1 = async () => {
      if (mealDetails?.orderId && orderData) {
        const order = await http.get(`orders/${mealDetails?.orderId}`);
        setEditPerm(order.orderMealDishes.length !== 0);
        setOrderedMeals(order.orderMealDishes.map((el) => el.mealDishId));
      }
    };
    fetchData1();
  }, [mealDetails]);
  const history = useHistory();
  useEffect(() => {
    setRemoveDishIds(mealDetails?.dishIds);
  }, [mealDetails?.dishIds]);
  useEffect(() => {
    const fetchData1 = async () => {
      const walletData = await http.get(`users/getUserWallet/${user.id}`);
      setShowBalance(walletData?.amount);
    };
    fetchData1();
  }, [user]);
  let extraItemTotal = 0;
  useEffect(() => {
    const getData = async () => {
      const data = await getPaymentPreferences(kitchen?.id, '');
      setPaymentKitchenId(kitchen?.id);
      // if (user.roleId === 8) {
      //   setPaymentId(4);
      //   setPaymentType('');
      //   setPaymentTypeData('');
      // } else {
      setPaymentId(data.selectedPaymentOptions);
      setPaymentType(data.paymentType);
      setPaymentTypeData(data);
      //}
    };
    getData();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      let payment = await http.get(`users/paymentMethods`);

      setPaymentMethods(payment);
      setDefaultPaymentMethod(
        (await http.get('users/defaultPaymentMethod'))?.id
      );
      let url = window.location.href;
      let split = url?.split('?')[1];
      if (split) {
        const order = await http.get(`orders/${split}`);
        if (order.id == split) {
          const data = await http.get(
            `mealExtras/getMealExtra/${split}/${user?.id}`,
            {
              filter: {
                orderStatusId: 1,
                date: mealDetails?.date,
                mealTypeId: mealDetails?.mealType,
              },
            }
          );
          setMealExtra(data);
          setOrderPlaced(true);
          await getEmployeeLobbyData(kitchen?.id);

          if (
            order.mealDish[0].meal.mealType.multipleDish &&
            user.isMultiOrders
          ) {
            setCafeteriaiMealDish(order.mealDish);
            const { data } = await useFetchingLoader(
              async () =>
                await http.get(`mealsDishes`, {
                  filter: {
                    date: new Date(mealDetails?.date),
                    mealTypeId: mealDetails?.mealType,
                    kitchenId: kitchen?.id,
                    mealDishStatusId: 1,
                  },
                })
            );
            let filterMeal1 = data.map((el) => {
              let d = mealDetails?.dishIds?.find((ele) => el.dishId === ele);
              if (d) {
                return { ...el, selected: true };
              } else {
                return { ...el, selected: false };
              }
            });
            setMealData(filterMeal1);
          } else {
            const mealDishData = await useFetchingLoader(
              async () =>
                await http.get(`mealsDishes/${order?.mealDish[0]?.id}`)
            );
            const getMealId = mealDishData ? mealDishData.meal.id : '';
            const mealDishDetails = generateMealDishData(
              MealTypesMap_Id,
              mealDishData
            );
            setDetails(mealDishDetails);
          }
        }
      }
    };
    if (kitchen?.id && !location) {
      fetchData1();
    }
  }, [kitchen]);
  useEffect(() => {
    if (location?.state) {
      localStorage.setItem('userData', JSON.stringify(location?.state));
      setMealDetails(location?.state);
    } else {
      let data = localStorage.getItem('userData');
      setMealDetails(JSON.parse(data));
    }
  }, [location]);

  useEffect(() => {
    const fetchData1 = async () => {
      if (mealDetails) {
        const { data } = await useFetchingLoader(
          async () =>
            await http.get(`mealsDishes`, {
              filter: {
                date: new Date(mealDetails?.date),
                mealTypeId: mealDetails?.mealType,
                kitchenId: kitchen?.id,
                mealDishStatusId: 1,
              },
            })
        );
        if (mealDetails?.orderId) {
          const order = await http.get(`orders/${mealDetails?.orderId}`);
          let a = [];

          const ordera = order.mealDish.map((ele) => {
            a.push({
              mealDishId: ele.id,
              quantity: ele.quantity,
              price: ele.dish.price,
            });
          });
          if (mealDetails?.mealType) {
            const getMultiPleDish = await http.get(
              `mealTypes/${mealDetails?.mealType}`
            );
            setCheckCreditPoints(getMultiPleDish?.creditPoints);
            if (getMultiPleDish?.multipleDish && user.isMultiOrders) {
              setMealDishArray(a);
            }
          }
          let filterMeal = data.filter((el) =>
            (mealDetails?.dishIds).includes(el.dishId)
          );
          setFilterMealState(filterMeal);
          if (filterMeal?.length > 0) {
            let sum = filterMeal?.reduce((x, y) => x + y?.dish?.price, 0);

            setTotalPrice(sum);
          }
          setOrderData(order.mealDish);
        }
        setKitchenName(data[0]?.dish?.kitchen?.name);
        let filterMeal1 = data.map((el) => {
          let d = mealDetails?.dishIds?.find((ele) => el.dishId === ele);
          if (d) {
            return { ...el, selected: true };
          } else {
            return { ...el, selected: false };
          }
        });
        setMealData(filterMeal1);
        if (mealDetails?.mealType) {
          const getMultiPleDish = await http.get(
            `mealTypes/${mealDetails?.mealType}`
          );
          setCheckCreditPoints(getMultiPleDish?.creditPoints);
          setMultipleDish(getMultiPleDish?.multipleDish && user?.isMultiOrders);
          setMultipleReservation(
            getMultiPleDish?.multipleReservations && user?.isMultiOrders
          );
        }
      }
    };
    if (mealDetails && kitchen.id) {
      fetchData1();
    }
  }, [mealDetails, kitchen]);
  useEffect(() => {
    const fetchData1 = async () => {
      setUpdate(false);
      if (orderData.length > 0 && mealDishArray.length) {
        let array = [];
        let priceArray = [];
        let orderPrice = 0;
        const ans = orderData.map((ele) => {
          const checkMeal = mealDishArray.find(
            (el) => el.mealDishId === ele.id
          );
          if (checkMeal) {
            return { ...ele, quantity: checkMeal.quantity };
          } else {
            return { ...ele, quantity: 1 };
          }
        });

        mealDishArray.forEach((el) =>
          priceArray.push({ price: el.price, quantity: el.quantity })
        );

        for (let i = 0; i < priceArray.length; i += 1) {
          orderPrice += priceArray[i].price * priceArray[i].quantity;
        }

        setTotalPrice(orderPrice);
      }
    };
    fetchData1();
  }, [orderData, mealDishArray, update]);

  const addToOrder = async (el) => {
    let filterData = mealData.filter((ele) => ele.id === el.id);
    setDishPopup(true);
    setOrderDetails(filterData[0]);
  };
  const myOrder = async (dishId, mealId, mealdishIdd, price, quantity = 1) => {
    if (multipleDish && user.isMultiOrders) {
      setMealIdForMulitple(mealId);
      const mealDishIndex = orderData.findIndex((el) => el.id === mealdishIdd);
      if (mealDishIndex === -1) {
        const mealIndex = mealData.findIndex((el) => el.id === mealdishIdd);
        if (mealIndex !== -1) {
          setMealDishArray((oldArray) => {
            let dd = oldArray.findIndex((el) => el.mealDishId === mealdishIdd);
            if (dd !== -1) {
              return oldArray;
            } else
              return [
                ...oldArray,
                { mealDishId: mealdishIdd, quantity: quantity, price: price },
              ];
          });
          setOrderData([...orderData, { ...mealData[mealIndex] }]);
        }
      } else {
        const prev = [...orderData];
        prev.splice(mealDishIndex, 1);
        setMealDishArray((oldArray) =>
          oldArray.filter((el) => el.mealDishId !== mealdishIdd)
        );
        setOrderData([...prev]);
      }

      if (mealId) {
        const data = await useFetchingLoader(async () => {
          return await http.get(`mealExtras/getMealExtrasMeal/${mealId}`);
        });
        setExtrasData(data);
      }
    } else {
      setMealDishId(mealdishIdd);
      let filterMeal = mealData?.filter((el) => el.dishId == dishId);
      setOrderData(filterMeal);

      if (mealId) {
        const data = await useFetchingLoader(async () => {
          return await http.get(`mealExtras/getMealExtrasMeal/${mealId}`);
        });

        setExtrasData(data);
        if (data?.length > 0) {
          handleExtrasMealProducts(data, filterMeal);
        } else {
          MakeOrderwithoutExtrasProducts(filterMeal);
        }
      }
    }

    setDishPopup(false);
  };

  const handleExtrasMealProducts = async (extrasData, filterMeal) => {
    const totalPrice = filterMeal?.reduce((x, y) => x + y?.dish?.price, 0);

    setTotalPrice(totalPrice);

    setExtrasData(extrasData);

    if (extrasData?.length) {
      setExtrasMeal(true);
    } else {
      setOrderPlaced(true);

      setExtrasMeal(false);
      await getEmployeeLobbyData(kitchen?.id);
    }
    setMyOrders(false);
    // handleMyOrders();
  };

  const handleExtrasMeal = async () => {
    if (extrasData?.length) {
      setExtrasMeal(true);
    } else {
      setOrderPlaced(true);
      setExtrasMeal(false);
      await getEmployeeLobbyData(kitchen?.id);
    }
    setMyOrders(false);
    // handleMyOrders();
  };
  const handleOrderPlaced = () => {
    setOrderPlaced(!orderPlaced);
    history.push('/myMeals');
  };
  const removeOrder = (e) => {
    let getDishId = mealData.filter((ele) => ele.id === e);
    let array = removeDishIds;
    const index = array?.indexOf(getDishId[0].dishId);
    setOrderData((prev) => prev.filter((el) => el.id !== e));
    setOrderedMeals((prev) => prev.filter((el) => el !== e));
    if (index > -1) {
      // only splice array when item is found
      array?.splice(index, 1); // 2nd parameter means remove one item only
    }
    setRemoveDishIds(array);
    let filterMeal1 = mealData.map((el) => {
      let d = array?.find((ele) => el.dishId === ele);
      if (d) {
        return { ...el, selected: true };
      } else {
        return { ...el, selected: false };
      }
    });
    setMealData(filterMeal1);

    let filter = orderData.filter((el) => el.id !== e);
    let mealArray = mealDishArray.filter((el) => el.mealDishId !== e);
    setMealDishArray(mealArray);
    let priceArray = [];
    let orderPrice = 0;
    const ans = filter.map((ele) => {
      const checkMeal = mealArray.find((el) => el.mealDishId === ele.id);
      if (checkMeal) {
        return { ...ele, quantity: checkMeal.quantity };
      } else {
        return { ...ele, quantity: 1 };
      }
    });

    mealArray.forEach((el) =>
      priceArray.push({ price: el.price, quantity: el.quantity })
    );

    for (let i = 0; i < priceArray.length; i += 1) {
      orderPrice += priceArray[i].price * priceArray[i].quantity;
    }

    setTotalPrice(orderPrice);
    setOrderData(filter);
  };
  const skipAddOrder = async () => {
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId === 4) {
      setExtrasMeal(false);
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: details?.date,
            mealTypeId: details?.mealTypeId,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderExistsTitle'),
            text: `${t('changeOrderText')} ${order.mealDish.map(
              (el) => el.mealDish.dish.name
            )} ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.meal.date
            } ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.dish.kitchen.name
            } ${t('changeOrderTextto')}  ${orderData.map(
              (ell) => ell?.dish?.name
            )}`,
            onConfirm: () => replaceOrderNew(order.id),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          if (data.length > 0) {
            let order = data[0];
            replaceOrderNew(order.id);
          } else {
            setPaymentPopup(true);
          }
        }
      } catch (err) {
        Alert({ error: err });
      }
    } else {
      setExtrasMeal(false);
      makeOrder();
    }
  };
  const replaceOrderProduct = async (orderIdToCancel, filterMeal) => {
    try {
      await useFetchingLoader(
        async () => await http.put(`orders/${orderIdToCancel}/cancel`)
      );
      const data = await http.get(
        `mealExtras/getMealExtra/${orderIdToCancel}/${user.id}`,
        {
          filter: {
            orderStatusId: 1,
            date: moment(mealDetails?.date).format('YYYY-MM-DD'),
            mealTypeId: mealDetails?.mealType,
          },
        }
      );
      setMealExtra(data);
      //await addOrder();
      await addOrderProducts(filterMeal, true);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };
  const replaceOrder = async (orderIdToCancel) => {
    try {
      await useFetchingLoader(
        async () => await http.put(`orders/${orderIdToCancel}/cancel`)
      );
      const data = await http.get(
        `mealExtras/getMealExtra/${orderIdToCancel}/${user.id}`,
        {
          filter: {
            orderStatusId: 1,
            date: moment(mealDetails?.date).format('YYYY-MM-DD'),
            mealTypeId: mealDetails?.mealType,
          },
        }
      );
      setMealExtra(data);
      await addOrder(true);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const replaceOrderNew = async (orderIdToCancel) => {
    try {
      await useFetchingLoader(
        async () => await http.put(`orders/${orderIdToCancel}/cancel`)
      );
      const data = await http.get(
        `mealExtras/getMealExtra/${orderIdToCancel}/${user.id}`,
        {
          filter: {
            orderStatusId: 1,
            date: moment(mealDetails?.date).format('YYYY-MM-DD'),
            mealTypeId: mealDetails?.mealType,
          },
        }
      );
      setMealExtra(data);
      setPaymentPopup(true);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const makeOrderProduct = async (filterMeal) => {
    // if (
    //   paymenttypeData?.selectedPaymentOptions === 5 &&
    //   totalPrice > showBalance
    // ) {
    //   SwalDialogs.Dialog({
    //     title: t('walletBalance'),
    //     text: t('insufficientWalletAlert'),
    //     buttons: {
    //       showCancelButton: false,
    //       confirmButtonText: 'Cancel',
    //       cancelButtonText: t('back'),
    //     },
    //   });
    // }
    // else {
    try {
      const { data } = await http.get('orders/getAllOrder', {
        filter: {
          orderStatusId: 1,
          date: moment(mealDetails?.date).format('YYYY-MM-DD'),
          mealTypeId: mealDetails?.mealType,
        },
      });

      //&& (multipleReservation !== 1 || multipleDish !== 1)
      if (data.length > 0) {
        let order = data[0];
        SwalDialogs.Dialog({
          title: t('orderDetails'),
          text: `${t('changeOrderText')} ${order.mealDish.map(
            (el) => el.mealDish.dish.name
          )} ${t('cancelOrderTextin')} ${
            order.mealDish[0].mealDish.meal.date
          } ${t('cancelOrderTextin')} ${
            order.mealDish[0].mealDish.dish.kitchen.name
          } ${t('changeOrderTextto')}  ${filterMeal.map(
            (ell) => ell?.dish?.name
          )}`,
          //onConfirm: () => replaceOrder(order.id),
          onConfirm: () => replaceOrderProduct(order.id, filterMeal),
          buttons: {
            showCancelButton: true,
            confirmButtonText: t('replaceOrderBtnLabel'),
            cancelButtonText: t('back'),
          },
        });
      } else {
        if (data.length > 0) {
          let order = data[0];
          replaceOrderProduct(order.id, filterMeal);
        } else {
          await addOrderProducts(filterMeal);
        }
      }
    } catch (err) {
      Alert({ error: err });
    }
    // }
  };

  const makeOrder = async () => {
    if (currentPaymentId === 5) {
      let showWalletBalance = 0;
      if (showBalance > 0) {
        showWalletBalance = showBalance;
      } else {
        showWalletBalance = 0;
      }
      //if (totalPrice > showBalance) {
      // if (totalPrice > showWalletBalance || showWalletBalance === 0) {
      //   SwalDialogs.Dialog({
      //     title: t('walletBalance'),
      //     text: t('insufficientWalletAlert'),
      //     buttons: {
      //       showCancelButton: false,
      //       confirmButtonText: 'Cancel',
      //       cancelButtonText: t('back'),
      //     },
      //     onConfirm: () => {
      //       if (multipleDish !== 1) {
      //         setOrderData([]);
      //         setOrderDetails([]);
      //       }
      //     },
      //   });
      // } else {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: moment(mealDetails?.date).format('YYYY-MM-DD'),
            mealTypeId: mealDetails?.mealType,
          },
        });

        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderDetails'),
            text: `${t('changeOrderText')} ${order.mealDish.map(
              (el) => el.mealDish.dish.name
            )} ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.meal.date
            } ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.dish.kitchen.name
            } ${t('changeOrderTextto')}  ${orderData.map(
              (ell) => ell?.dish?.name
            )}`,
            onConfirm: () => replaceOrder(order.id),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          if (data.length > 0) {
            let order = data[0];
            replaceOrder(order.id);
          } else {
            await addOrder();
          }
        }
      } catch (err) {
        Alert({ error: err });
      }
      //}
    } else {
      // if (
      //   paymenttypeData?.selectedPaymentOptions === 5 &&
      //   totalPrice > showBalance
      // ) {
      //   SwalDialogs.Dialog({
      //     title: t('walletBalance'),
      //     text: t('insufficientWalletAlert'),
      //     buttons: {
      //       showCancelButton: false,
      //       confirmButtonText: 'Cancel',
      //       cancelButtonText: t('back'),
      //     },
      //   });
      // } else {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: moment(mealDetails?.date).format('YYYY-MM-DD'),
            mealTypeId: mealDetails?.mealType,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderDetails'),
            text: `${t('changeOrderText')} ${order.mealDish.map(
              (el) => el.mealDish.dish.name
            )} ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.meal.date
            } ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.dish.kitchen.name
            } ${t('changeOrderTextto')}  ${orderData.map(
              (ell) => ell?.dish?.name
            )}`,
            onConfirm: () => replaceOrder(order.id),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          if (data.length > 0) {
            let order = data[0];
            replaceOrder(order.id);
          } else {
            await addOrder();
          }
        }
      } catch (err) {
        Alert({ error: err });
      }
      // }
    }
  };
  const AddOrderNew = async () => {
    setIsSkip(true);
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId === 4) {
      setExtrasMeal(false);
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: moment(mealDetails?.date).format('YYYY-MM-DD'),
            mealTypeId: mealDetails?.mealType,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderDetails'),
            text: `${t('changeOrderText')} ${order.mealDish.map(
              (el) => el.mealDish.dish.name
            )} ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.meal.date
            } ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.dish.kitchen.name
            } ${t('changeOrderTextto')}  ${orderData.map(
              (ell) => ell?.dish?.name
            )}`,
            onConfirm: () => replaceOrderNew(order.id),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          if (data.length > 0) {
            let order = data[0];
            replaceOrderNew(order.id);
          } else {
            setPaymentPopup(true);
          }
        }
      } catch (err) {
        Alert({ error: err });
      }
    } else {
      setExtrasMeal(false);
      makeOrder();
    }
  };

  const onCreateOrder = async (res, filterMeal = {}) => {
    let dropdownVal = '';
    setSelectedTime((prev) => {
      dropdownVal = prev;
      return prev;
    });
    console.log('dropdownVal', dropdownVal);

    if (
      res.KitchenId &&
      (res.roleId === 6 || res.roleId === 8 || res.roleId === 7)
    ) {
      try {
        let mealDishData = '';
        if (multipleDish && user.isMultiOrders) {
          if (
            !mealDishArray ||
            (mealDishArray &&
              Array.isArray(mealDishArray) &&
              mealDishArray.length === 0)
          ) {
            throw t('missingDish');
          }
          mealDishData = await useFetchingLoader(
            async () =>
              await http.post(`orders/create`, {
                orderMealDishData: mealDishArray,
                slotId: mealDetails?.slotId,
                mealExtraId: addedExtras,
                pickupTime: dropdownVal,
              })
          );
        } else {
          if (
            !filterMeal ||
            (filterMeal && Array.isArray(filterMeal) && filterMeal.length === 0)
          ) {
            throw t('missingDish');
          }
          mealDishData = await useFetchingLoader(
            async () =>
              await http.post(`orders/create`, {
                mealId: filterMeal[0]?.mealId,
                dishId: filterMeal[0]?.dishId,
                slotId: mealDetails?.slotId,
                mealExtraId: addedExtras,
                pickupTime: dropdownVal,
              })
          );

          if (mealDishData.id > 0) {
          } else {
            // SwalDialogs.Dialog({
            //   title: t('Alert'),
            //   text: t('confirmOrder'),
            //   type: error,
            //   buttons: {
            //     showCancelButton: false,
            //     confirmButtonText: 'Cancel',
            //     cancelButtonText: t('back'),
            //   },
            //   onConfirm: async () => {},
            // });
            Alert({ error: t('alertOrderMsg') });
          }
        }
        if (mealDishData?.id) {
          setCurrentOrder(mealDishData);
          setOrderPlaced(true);
          await getEmployeeLobbyData(kitchen?.id);
        }
        if (mealDishData.error === 'mealDishNoInventory') {
          if (currentLanguage.shortCode == 'en') {
            Alert({
              error:
                'This dish is no longer available. Please choose another dish',
            });
          }
          if (currentLanguage.shortCode == 'he') {
            Alert({ error: 'המנה אזלה, יש לבחור מנה אחרת' });
          }
          if (currentLanguage.shortCode == 'ru') {
            Alert({
              error:
                'Это блюдо больше не доступно. Пожалуйста, выберите другое блюдо',
            });
          }
        }
        setExtrasMeal(false);
      } catch (err) {
        setTimeout(() => {
          Alert({ error: err });
        }, 250);
      }
    } else if (res.roleId === 4) {
      try {
        let mealDishData = '';
        if (multipleDish && user.isMultiOrders) {
          if (
            !mealDishArray ||
            (mealDishArray &&
              Array.isArray(mealDishArray) &&
              mealDishArray.length === 0)
          ) {
            throw t('missingDish');
          }
          mealDishData = await useFetchingLoader(
            async () =>
              await http.post(`orders/create`, {
                orderMealDishData: mealDishArray && mealDishArray,
                slotId: mealDetails?.slotId,
                mealExtraId: addedExtras,
                paymentType: currentPaymentId,
                pickupTime: dropdownVal,
              })
          );
        } else {
          if (
            !filterMeal ||
            (filterMeal && Array.isArray(filterMeal) && filterMeal.length === 0)
          ) {
            throw t('missingDish');
          }
          mealDishData = await useFetchingLoader(
            async () =>
              await http.post(`orders/create`, {
                mealId: filterMeal[0]?.mealId,
                dishId: filterMeal[0]?.dishId,
                slotId: mealDetails?.slotId,
                mealExtraId: addedExtras,
                paymentType: currentPaymentId,
                pickupTime: dropdownVal,
              })
          );
        }
        if (mealDishData?.id) {
          setCurrentOrder(mealDishData);
          setOrderPlaced(true);
          await getEmployeeLobbyData(kitchen?.id);
        }

        if (mealDishData.error === 'mealDishNoInventory') {
          if (currentLanguage.shortCode == 'en') {
            Alert({
              error:
                'This dish is no longer available. Please choose another dish',
            });
          }
          if (currentLanguage.shortCode == 'he') {
            Alert({ error: 'המנה אזלה, יש לבחור מנה אחרת' });
          }
          if (currentLanguage.shortCode == 'ru') {
            Alert({
              error:
                'Это блюдо больше не доступно. Пожалуйста, выберите другое блюдо',
            });
          }
        }

        setExtrasMeal(false);
      } catch (err) {
        setTimeout(() => Alert({ error: err }), 250);
        return err;
      }
    } else {
      setTimeout(() => {
        Alert({ error: t('alertForContractor') });
      }, 250);
    }
  };
  const showDialog = (
    title,
    desc,
    dropdownValues,
    onConfirm,
    onCancel,
    mealDetails
  ) => {
    const dropdownContainer = document.createElement('div');
    let dropdownValue = '';

    Swal.fire({
      title: title,
      html: dropdownContainer,
      showCancelButton: true,
      confirmButtonText: t('confirm'),
      cancelButtonText: t('back'),
      confirmButtonColor: '#14CD31',
      customClass: {
        content: 'your-dish-swal-content',
        actions: 'your-dish-swal-actions',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      } else {
        onCancel();
      }
    });

    ReactDOM.render(
      <div>
        <p>{desc}</p>
        {dropdownValues &&
        Array.isArray(dropdownValues) &&
        dropdownValues.filter((el) =>
          moment(mealDetails.date + ' ' + el, 'YYYY-MM-DD HH:mm').isAfter(
            moment()
          )
        ).length > 0 ? (
          <div
            className="client-lobby-filter-block-datepicker"
            style={{ zIndex: 9999999, alignItems: 'center' }}
          >
            <Select
              name={'Status'}
              placeholder={t('pickupTime')}
              className="client-lobby-filter-block-dropdown green-background"
              styles={
                window.innerWidth < 901
                  ? { ...SelectStylesSmall }
                  : { ...SelectStyles }
              }
              options={dropdownValues
                .filter((el) =>
                  moment(
                    mealDetails.date + ' ' + el,
                    'YYYY-MM-DD HH:mm'
                  ).isAfter(moment())
                )
                .map((el) => ({
                  value: el,
                  label: el,
                }))}
              onChange={(newData) => {
                setSelectedTime(newData.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                zIndex: 99999,
                colors: {
                  ...theme.colors,
                  primary25: 'rgba(157, 199, 157, 0.3)',
                  primary: '#00D100',
                  neutral20: '#00D100',
                  neutral30: '#656565',
                  neutral40: '#00D100',
                  neutral60: '#00D100',
                  neutral80: '#656565',
                  neutral90: '#9DC79D',
                },
              })}
            />
          </div>
        ) : (
          ''
        )}
      </div>,
      dropdownContainer
    );
  };

  const addOrderProducts = async (filterMeal, isSkipPopup) => {
    if (isSkip === true) {
      extraItemTotal =
        addedExtras.length > 0
          ? addedExtras
              .map((el) => {
                const findExtraDish = extrasData.find((elem) => elem.id === el);
                if (findExtraDish) {
                  return findExtraDish?.price;
                }
              })
              .reduce((prev, cur) => prev + cur, 0)
          : 0;
    } else {
      extraItemTotal = 0;
    }
    const res = await http.get(`users/getUserKitchen/${user.id}`);
    if (isSkipPopup) {
      onCreateOrder(res, filterMeal);
    } else {
      console.log('filterMeal', filterMeal);

      showDialog(
        t('orderDetails'),
        `${t('confirmOrder')} ${filterMeal.map((el) => el.dish.name)} ?`,
        filterMeal[0].meal.mealType?.pickupTimes
          ? orderData[0].meal.mealType.pickupTimes.split(',')
          : [],
        async () => onCreateOrder(res, filterMeal),
        () => {
          if (!multipleDish) {
            setOrderData([]);
            setDishPopup(true);
            setOrderDetails([]);
            setDishPopup(!dishPopup);
          }
        },
        mealDetails
      );
    }
  };

  const addOrder = async (isSkipPopup) => {
    if (isSkip === true) {
      extraItemTotal =
        addedExtras.length > 0
          ? addedExtras
              .map((el) => {
                const findExtraDish = extrasData.find((elem) => elem.id === el);
                if (findExtraDish) {
                  return findExtraDish?.price;
                }
              })
              .reduce((prev, cur) => prev + cur, 0)
          : 0;
    } else {
      extraItemTotal = 0;
    }
    const res = await http.get(`users/getUserKitchen/${user.id}`);
    if (isSkipPopup) {
      if (
        res.KitchenId &&
        (res.roleId === 6 || res.roleId === 8 || res.roleId === 7)
      ) {
        try {
          let mealDishData = '';

          if (multipleDish && user.isMultiOrders) {
            if (
              !mealDishArray ||
              (mealDishArray &&
                Array.isArray(mealDishArray) &&
                mealDishArray.length === 0)
            ) {
              throw t('missingDish');
            }
            mealDishData = await useFetchingLoader(
              async () =>
                await http.post(`orders/create`, {
                  orderMealDishData: mealDishArray && mealDishArray,
                  slotId: mealDetails?.slotId,
                  mealExtraId: addedExtras,
                })
            );
          } else {
            if (
              !orderData ||
              (orderData && Array.isArray(orderData) && orderData.length === 0)
            ) {
              throw t('missingDish');
            }
            mealDishData = await useFetchingLoader(
              async () =>
                await http.post(`orders/create`, {
                  mealId: orderData[0]?.mealId,
                  dishId: orderData[0]?.dishId,
                  slotId: mealDetails?.slotId,
                  mealExtraId: addedExtras,
                })
            );
          }

          if (mealDishData?.id) {
            setCurrentOrder(mealDishData);
            setOrderPlaced(true);
            await getEmployeeLobbyData(kitchen?.id);
          }
          if (mealDishData.error === 'mealDishNoInventory') {
            if (currentLanguage.shortCode == 'en') {
              Alert({
                error:
                  'This dish is no longer available. Please choose another dish',
              });
            }
            if (currentLanguage.shortCode == 'he') {
              Alert({ error: 'המנה אזלה, יש לבחור מנה אחרת' });
            }
            if (currentLanguage.shortCode == 'ru') {
              Alert({
                error:
                  'Это блюдо больше не доступно. Пожалуйста, выберите другое блюдо',
              });
            }
          }
          setExtrasMeal(false);
        } catch (err) {
          setTimeout(() => Alert({ error: err }), 250);
          return err;
        }
      } else if (res.roleId === 4) {
        try {
          let mealDishData = '';

          if (multipleDish && user.isMultiOrders) {
            if (
              !mealDishArray ||
              (mealDishArray &&
                Array.isArray(mealDishArray) &&
                mealDishArray.length === 0)
            ) {
              throw t('missingDish');
            }
            mealDishData = await useFetchingLoader(
              async () =>
                await http.post(`orders/create`, {
                  orderMealDishData: mealDishArray && mealDishArray,
                  slotId: mealDetails?.slotId,
                  mealExtraId: addedExtras,
                  paymentType: currentPaymentId,
                })
            );
            if (mealDishData.id > 0) {
            } else {
              Alert({ error: t('alertOrderMsg') });
            }
          } else {
            if (
              !orderData ||
              (orderData && Array.isArray(orderData) && orderData.length === 0)
            ) {
              throw t('missingDish');
            }
            mealDishData = await useFetchingLoader(
              async () =>
                await http.post(`orders/create`, {
                  mealId: orderData[0]?.mealId,
                  dishId: orderData[0]?.dishId,
                  slotId: mealDetails?.slotId,
                  mealExtraId: addedExtras,
                  paymentType: currentPaymentId,
                })
            );
          }
          if (mealDishData?.id) {
            setCurrentOrder(mealDishData);
            setOrderPlaced(true);
            await getEmployeeLobbyData(kitchen?.id);
          }

          if (mealDishData.error === 'mealDishNoInventory') {
            if (currentLanguage.shortCode == 'en') {
              Alert({
                error:
                  'This dish is no longer available. Please choose another dish',
              });
            }
            if (currentLanguage.shortCode == 'he') {
              Alert({ error: 'המנה אזלה, יש לבחור מנה אחרת' });
            }
            if (currentLanguage.shortCode == 'ru') {
              Alert({
                error:
                  'Это блюдо больше не доступно. Пожалуйста, выберите другое блюдо',
              });
            }
          }

          setExtrasMeal(false);
        } catch (err) {
          setTimeout(() => Alert({ error: err }), 250);
          return err;
        }
      } else {
        setTimeout(() => {
          Alert({ error: t('alertForContractor') });
        }, 250);
      }
    } else {
      const confirmFunc = async () => {
        let dropdownVal = '';
        setSelectedTime((prev) => {
          dropdownVal = prev;
          return prev;
        });
        console.log('dropdownVal', dropdownVal);
        if (
          res.KitchenId &&
          (res.roleId === 6 || res.roleId === 8 || res.roleId === 7)
        ) {
          try {
            let mealDishData = '';

            if (multipleDish && user.isMultiOrders) {
              if (
                !mealDishArray ||
                (mealDishArray &&
                  Array.isArray(mealDishArray) &&
                  mealDishArray.length === 0)
              ) {
                throw t('missingDish');
              }
              mealDishData = await useFetchingLoader(
                async () =>
                  await http.post(`orders/create`, {
                    orderMealDishData: mealDishArray && mealDishArray,
                    slotId: mealDetails?.slotId,
                    mealExtraId: addedExtras,
                    pickupTime: dropdownVal,
                  })
              );
            } else {
              if (
                !orderData ||
                (orderData &&
                  Array.isArray(orderData) &&
                  orderData.length === 0)
              ) {
                throw t('missingDish');
              }
              mealDishData = await useFetchingLoader(
                async () =>
                  await http.post(`orders/create`, {
                    mealId: orderData[0]?.mealId,
                    dishId: orderData[0]?.dishId,
                    slotId: mealDetails?.slotId,
                    mealExtraId: addedExtras,
                    pickupTime: dropdownVal,
                  })
              );
            }

            if (mealDishData?.id) {
              setCurrentOrder(mealDishData);
              setOrderPlaced(true);
              await getEmployeeLobbyData(kitchen?.id);
            }
            if (mealDishData.error === 'mealDishNoInventory') {
              if (currentLanguage.shortCode == 'en') {
                Alert({
                  error:
                    'This dish is no longer available. Please choose another dish',
                });
              }
              if (currentLanguage.shortCode == 'he') {
                Alert({ error: 'המנה אזלה, יש לבחור מנה אחרת' });
              }
              if (currentLanguage.shortCode == 'ru') {
                Alert({
                  error:
                    'Это блюдо больше не доступно. Пожалуйста, выберите другое блюдо',
                });
              }
            }
            setExtrasMeal(false);
          } catch (err) {
            setTimeout(() => Alert({ error: err }), 250);
            return err;
          }
        } else if (res.roleId === 4) {
          try {
            let mealDishData = '';
            if (multipleDish && user.isMultiOrders) {
              if (
                !mealDishArray ||
                (mealDishArray &&
                  Array.isArray(mealDishArray) &&
                  mealDishArray.length === 0)
              ) {
                throw t('missingDish');
              }
              mealDishData = await useFetchingLoader(
                async () =>
                  await http.post(`orders/create`, {
                    orderMealDishData: mealDishArray && mealDishArray,
                    slotId: mealDetails?.slotId,
                    mealExtraId: addedExtras,
                    paymentType: currentPaymentId,
                    pickupTime: dropdownVal,
                  })
              );
              if (mealDishData.id > 0) {
              } else {
                Alert({ error: t('alertOrderMsg') });
              }
            } else {
              if (
                !orderData ||
                (orderData &&
                  Array.isArray(orderData) &&
                  orderData.length === 0)
              ) {
                throw t('missingDish');
              }
              mealDishData = await useFetchingLoader(
                async () =>
                  await http.post(`orders/create`, {
                    mealId: orderData[0]?.mealId,
                    dishId: orderData[0]?.dishId,
                    slotId: mealDetails?.slotId,
                    mealExtraId: addedExtras,
                    paymentType: currentPaymentId,
                    pickupTime: dropdownVal,
                  })
              );
            }
            if (mealDishData?.id) {
              setCurrentOrder(mealDishData);
              setOrderPlaced(true);
              await getEmployeeLobbyData(kitchen?.id);
            }

            if (mealDishData.error === 'mealDishNoInventory') {
              if (currentLanguage.shortCode == 'en') {
                Alert({
                  error:
                    'This dish is no longer available. Please choose another dish',
                });
              }
              if (currentLanguage.shortCode == 'he') {
                Alert({ error: 'המנה אזלה, יש לבחור מנה אחרת' });
              }
              if (currentLanguage.shortCode == 'ru') {
                Alert({
                  error:
                    'Это блюдо больше не доступно. Пожалуйста, выберите другое блюдо',
                });
              }
            }

            setExtrasMeal(false);
          } catch (err) {
            setTimeout(() => Alert({ error: err }), 250);
            return err;
          }
        } else {
          setTimeout(() => {
            Alert({ error: t('alertForContractor') });
          }, 250);
        }
      };
      const cancelFunc = (multipleDish, setOrderData, setOrderDetails) => {
        if (!multipleDish) {
          setOrderData([]);
          setOrderDetails([]);
        }
      };
      console.log('orderData', orderData);
      showDialog(
        t('orderDetails'),
        `${t('confirmOrder')} ${orderData.map((el) => el.dish.name)} ?`,
        orderData[0].meal.mealType?.pickupTimes
          ? orderData[0].meal.mealType.pickupTimes.split(',')
          : [],
        () =>
          confirmFunc(
            res,
            multipleDish,
            mealDishArray,
            mealDetails,
            addedExtras,
            orderData,
            kitchen,
            multipleDish,
            user,
            currentPaymentId,
            currentLanguage,
            setExtrasMeal
          ),
        () => cancelFunc(multipleDish, setOrderData, setOrderDetails),
        mealDetails
      );
    }
  };

  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});

  useEffect(() => {
    const fetchData1 = async () => {
      if (mealDishId) {
        const mealDishData = await useFetchingLoader(
          async () => await http.get(`mealsDishes/${mealDishId}`)
        );
        let now = mealDishData?.meal?.mealType?.to;
        let hour = now.split(':')[0];
        let minute = now.split(':')[1];
        let dt = new Date(null, null, null, hour, minute);
        if (mealDishData?.meal?.mealType?.cancellationTime) {
          dt.setMinutes(
            dt.getMinutes() - mealDishData?.meal?.mealType?.cancellationTime
          );
          setCancellationTime(moment(dt).format('HH:mm'));
        } else {
          setCancellationTime(mealDishData?.meal?.mealType?.cancellationTime);
        }
        const getMealId = mealDishData ? mealDishData.meal.id : '';
        const mealDishDetails = generateMealDishData(
          MealTypesMap_Id,
          mealDishData
        );
        setDetails(mealDishDetails);
      }
    };
    fetchData1();
  }, [mealDishId]);

  useEffect(() => {
    const fetchData1 = async () => {
      if (mealDishArray) {
        let array = [];
        mealDishArray &&
          mealDishArray.map(async (el, i) => {
            const mealDishData = await useFetchingLoader(
              async () => await http.get(`mealsDishes/${el.mealDishId}`)
            );
            mealDishData['quantity'] = el.quantity;

            array.push(mealDishData);
            setCafeteriaiMealDish(array);
          });
      }
    };
    fetchData1();
  }, [mealDishArray]);

  const MakeOrderwithoutExtrasProducts = async (filterMeal) => {
    setMyOrders(false);
    //if (filterMeal?.length > 0) {
    const totalPrice = filterMeal?.reduce((x, y) => x + y?.dish?.price, 0);

    setTotalPrice(totalPrice);
    // }
    let showWalletBalance = 0;
    if (showBalance > 0) {
      showWalletBalance = showBalance;
    } else {
      showWalletBalance = 0;
    }

    //if (totalPrice > showBalance) {
    // if (
    //   paymenttypeData?.selectedPaymentOptions === 5 &&
    //   totalPrice > showWalletBalance
    // ) {
    //   SwalDialogs.Dialog({
    //     title: t('walletBalance'),
    //     text: t('insufficientWalletAlert'),
    //     buttons: {
    //       showCancelButton: false,
    //       confirmButtonText: 'Cancel',
    //       cancelButtonText: t('back'),
    //     },
    //     onConfirm: () => {
    //       if (multipleDish !== 1) {
    //         setOrderData([]);
    //         setOrderDetails([]);
    //       }
    //     },
    //     onCancel: () => {
    //       if (multipleDish !== 1) {
    //         setOrderData([]);
    //         setOrderDetails([]);
    //       }
    //     },
    //   });
    // } else {
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId === 4) {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: mealDetails?.date,
            mealTypeId: mealDetails?.mealType,
            // orderId: orderId,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderDetails'),
            text: `${t('changeOrderText')} ${order.mealDish.map(
              (el) => el.mealDish.dish.name
            )} ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.meal.date
            } ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.dish.kitchen.name
            } ${t('changeOrderTextto')}  ${filterMeal.map(
              (ell) => ell?.dish?.name
            )}`,
            onConfirm: () => replaceOrderNew(order.id),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          if (data.length > 0) {
            let order = data[0];
            replaceOrderNew(order.id);
          } else {
            setPaymentPopup(true);
          }
        }
      } catch (err) {
        Alert({ error: err });
      }
    } else {
      makeOrderProduct(filterMeal);
    }
    //  }
    // handleMyOrders();
  };

  const MakeOrderwithoutExtras = async () => {
    setMyOrders(false);
    // if (
    //   paymenttypeData?.selectedPaymentOptions === 5 &&
    //   totalPrice > showBalance
    // ) {
    //   SwalDialogs.Dialog({
    //     title: t('walletBalance'),
    //     text: t('insufficientWalletAlert'),
    //     buttons: {
    //       showCancelButton: false,
    //       confirmButtonText: 'Cancel',
    //       cancelButtonText: t('back'),
    //     },
    //   });
    // } else {
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId === 4) {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: mealDetails?.date,
            mealTypeId: mealDetails?.mealType,
            // orderId: orderId,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderDetails'),
            text: `${t('changeOrderText')} ${order.mealDish.map(
              (el) => el.mealDish.dish.name
            )} ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.meal.date
            } ${t('cancelOrderTextin')} ${
              order.mealDish[0].mealDish.dish.kitchen.name
            } ${t('changeOrderTextto')}  ${orderData.map(
              (ell) => ell?.dish?.name
            )}`,
            onConfirm: () => replaceOrderNew(order.id),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          if (data.length > 0) {
            let order = data[0];
            replaceOrderNew(order.id);
          } else {
            setPaymentPopup(true);
          }
        }
      } catch (err) {
        Alert({ error: err });
      }
    } else {
      makeOrder();
    }
    //  }
    // handleMyOrders();
  };

  const skipAddOrderPayment = () => {
    makeOrder();
    setPaymentPopup(false);
  };

  const handlePayment = async () => {
    if (multipleDish && user.isMultiOrders) {
      handleMultipleOrder();
    } else {
      handleSingleOrder();
    }
  };

  const handleMultipleOrder = async () => {
    let url = window.location.href;
    let orders = url.split('#');
    const res = await http.post('users/paymentUrl', {
      url_redirect: frontendUrl('successfullySavedPaymentMethod'),
      paymentType: paymentType,
      currentUrl: orders[1],
      mealDishId: mealDishArray && mealDishArray,
      orderPrice: parseFloat(totalPrice) + parseFloat(extraItemTotal),
      slotId: mealDetails.slotId,
      mealExtraId: addedExtras,
      mealId: mealIdForMultiple && mealIdForMultiple,
      dishId: details?.dishId,
      kitchenId: user?.lastUsedKitchenId,
    });

    window.location.href = res.url;
  };

  const handleSingleOrder = async () => {
    let url = window.location.href;
    let orders = url.split('#');
    const res = await http.post('users/paymentUrl', {
      url_redirect: frontendUrl('successfullySavedPaymentMethod'),
      paymentType: paymentType,
      currentUrl: orders[1],
      mealId: details?.mealId,
      dishId: details?.dishId,
      orderPrice: details?.dishPrice + extraItemTotal,
      slotId: mealSlotId,
      mealExtraId: addedExtras,
      kitchenId: user?.lastUsedKitchenId,
    });
    window.location.href = res.url;
  };
  const handleClose = () => {
    setPaymentPopup(false);
  };
  // const cibusPayment = () => {
  //   SwalDialogs.Dialog({
  //     title: t('cibusPayment'),
  //     text: t('cibusPaymentAlert'),
  //     buttons: {
  //       confirmButtonText: t('confirm'),
  //       cancelButtonText: t('back'),
  //     },
  //     closeBtnStyle: {},
  //     onConfirm: handleCibusPayment,
  //   });
  // };

  // const handleCibusPayment = async () => {
  //   const res = await http.post(`users/userPaymentByCibus`, {
  //     orderId: order.id,
  //     price: order.orderPrice,
  //     paymentOptionId: paymentMethod,
  //   });
  //   window.location.href = res.url;
  // };
  return (
    <>
      <Helmet>
        <html className="you-dish-page" lang="en" />
      </Helmet>
      <div className="your-dish-content">
        <div className="container">
          <div className="your-dish-head">
            <div className="back">
              {/* <a href="/#/mealTime">
                <img src={backarrow} alt="" />
                {t('back')}
              </a> */}
              <BackLink
                text={window.innerWidth <= 401 ? ' ' : backText}
                redirectHistory={backLink}
              />
            </div>
            <div className="your-dish-heading">
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                <span>
                  {dates.format(mealDetails?.date)} |{' '}
                  {MealTypesMap_Id[mealDetails?.mealType]} |{' '}
                  {kitchenName && kitchenName}
                </span>

                {mealData && Array.isArray(mealData) && mealData.length > 0 ? (
                  mealData[0]?.meal?.title && mealData[0]?.meal?.description ? (
                    <span>
                      {mealData[0]?.meal?.title} |{' '}
                      {mealData[0]?.meal?.description}
                    </span>
                  ) : (
                    <span>
                      {mealData[0]?.meal?.title ||
                        mealData[0]?.meal?.description}
                    </span>
                  )
                ) : (
                  ''
                )}
              </Box>
              <h1>{t('chooseYourDish')}</h1>
            </div>
          </div>

          <div
            className={`menu-category ${
              orderData &&
              mealData &&
              orderData.filter((obj1) =>
                mealData.some((obj2) => obj2.id === obj1.id)
              ).length !== 0 &&
              multipleDish !== 0 &&
              user.isMultiOrders
                ? ''
                : 'single-dish-area'
            }`}
          >
            {/* Menu Category */}

            <div className="menu-category-content">
              {/* <div className="menu-category-heading">
                <h1>
                  <span>{t('menu')}</span> {t('Category')}
                </h1>
              </div>
              <div className="menu-card-box">
                <div className="menu-card">
                  <img src={menu1} alt="" />
                  <p>Salad</p>
                </div>
                <div className="menu-card">
                  <img src={menu2} alt="" />
                  <p>Pizza</p>
                </div>
                <div className="menu-card">
                  <img src={menu3} alt="" />
                  <p>Meat</p>
                </div>
                <div className="menu-card">
                  <img src={menu4} alt="" />
                  <p>Bread</p>
                </div>
                <div className="menu-card">
                  <img src={menu5} alt="" />
                  <p>Fish</p>
                </div>
                <div className="menu-card">
                  <img src={menu6} alt="" />
                  <p>Chicken</p>
                </div>{' '}
                <div className="menu-card">
                  <img src={menu7} alt="" />
                  <p>Braverages</p>
                </div>{' '}
                <div className="menu-card">
                  <img src={menu8} alt="" />
                  <p>Dessert</p>
                </div>
              </div> */}
              <div className="menu-category-meat">
                {mealData
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((el) => {
                    return (
                      <>
                        <div
                          className={`menu-category-meat-box ${
                            el.ordered < el.inventory ? 'pointer' : ''
                          } ${
                            el.selected == true ||
                            (orderedMeals.filter((obj1) => obj1 === el.id)
                              .length !== 0 &&
                              multipleDish !== 0 &&
                              user.isMultiOrders) ||
                            (orderData.filter((obj1) => obj1.id === el.id)
                              .length !== 0 &&
                              multipleDish !== 0 &&
                              user.isMultiOrders)
                              ? 'disable-dish'
                              : ''
                          } ${
                            el.selected == true ||
                            (orderedMeals.filter((obj1) => obj1 === el.id)
                              .length !== 0 &&
                              multipleDish !== 0 &&
                              user.isMultiOrders) ||
                            (orderData.filter((obj1) => obj1.id === el.id)
                              .length !== 0 &&
                              multipleDish !== 0 &&
                              user.isMultiOrders)
                              ? //orderData[0]?.dishId === el.dishId
                                'selected-dish'
                              : ''
                          } `}
                          onClick={() => addToOrder(el)}
                        >
                          <div
                            className={`meat-box-img ${
                              el.ordered < el.inventory ? '' : 'selected-img'
                            } ${
                              (el.selected == true ||
                                (orderedMeals.filter((obj1) => obj1 === el.id)
                                  .length !== 0 &&
                                  multipleDish !== 0 &&
                                  user.isMultiOrders)) &&
                              'selected-img'
                            }`}
                          >
                            <img
                              src={
                                el?.dish?.image
                                  ? el?.dish?.image == 'h' ||
                                    el?.dish?.image === 'null'
                                    ? defaultImage
                                    : el?.dish?.image
                                  : defaultImage
                              }
                              alt=""
                            />
                            {el?.isChefMealDish ? (
                              <img
                                src={chefDishImg ? chefDishImg : ''}
                                alt=""
                                className="chef-img"
                              />
                            ) : (
                              ''
                            )}

                            {el.ordered < el.inventory ? (
                              ''
                            ) : (
                              <div className="seleted-dish">
                                <div className="selected-text">
                                  <img src={banner} alt="" />
                                  <p>{t('runOut')}</p>
                                </div>
                              </div>
                            )}

                            {(el.selected == true ||
                              orderedMeals.filter((obj1) => obj1 === el.id)
                                .length !== 0) && (
                              <div className="seleted-dish">
                                <div className="selected-text">
                                  <img src={banner} alt="" />
                                  <p>{t('dishIsOrdered')}</p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="meat-box-detail">
                            <div className="meat-box-text">
                              <div className="meat-box-name">
                                <h3>{el.dish.name}</h3>
                              </div>
                            </div>
                            <div className="meat-box-price">
                              <div className="dishInfo-inner">
                                {/* <div className="meat-category">
                            <p>{t('category')}</p>
                          </div> */}
                                <div className="meat-meat">
                                  <span>
                                    {enums.display(
                                      'DishCategory',
                                      el.dish.dishCategoryId
                                    )}
                                  </span>
                                </div>
                                {(user.roleId === 4 ||
                                  user.roleId === 6 ||
                                  user.roleId === 8) &&
                                  (el.dish.price !== 0 ||
                                    el?.meal?.mealType?.price !== 0) &&
                                  (paymenttypeData?.selectedPaymentOptions ==
                                    4 ||
                                  kitchen?.kitchenPayment?.paymentOptionId ===
                                    4 ? (
                                    ''
                                  ) : (
                                    <div className="meat-prices">
                                      <span>
                                        {paymenttypeData?.selectedPaymentOptions ==
                                        5
                                          ? ''
                                          : el?.meal?.mealType
                                                ?.calculateDishPrice == 1
                                            ? el.dish.price
                                              ? '₪'
                                              : ''
                                            : el?.meal?.mealType?.price
                                              ? '₪'
                                              : ''}
                                        {el?.meal?.mealType
                                          ?.calculateDishPrice == 1
                                          ? el.dish.price
                                            ? el.dish.price
                                            : ''
                                          : el?.meal?.mealType?.price
                                            ? el?.meal?.mealType?.price
                                            : ''}{' '}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                              <div className="meat-box-ratings">
                                <div className="meat-box-rating">
                                  <DynamicStars
                                    value={el.dish.rating ? el.dish.rating : 0}
                                  />
                                </div>
                                <div className="meat-box-raters">
                                  <span>
                                    ({t('raters')}{' '}
                                    {el.dish.numOfRaters
                                      ? el.dish.numOfRaters
                                      : 0}
                                    )
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>

            {/* My Order */}

            {orderData?.length > 0 ??
              orderData?.map((el, index) => (
                <MyOrderItem
                  key={el.id}
                  data={el}
                  img={el?.dish?.image}
                  title={el?.dish?.name}
                  subText={el?.dish?.description}
                  price={el?.dish?.price}
                  quant={el?.quantity}
                  category={enums.display(
                    'DishCategory',
                    el?.dish?.dishCategoryId
                  )}
                  multipleDish={multipleDish && user.isMultiOrders}
                  index={index}
                  mealDishArray={mealDishArray && mealDishArray}
                  setMealDishArray={setMealDishArray}
                  setUpdate={setUpdate}
                  orderId={mealDetails?.orderId}
                  removeOrder={removeOrder}
                  paymentOption={paymenttypeData?.selectedPaymentOptions}
                  setCafeteriaiMealDish={setCafeteriaiMealDish}
                  t={t}
                  setMyOrders={setMyOrders}
                  setMyOrdersPopup={setMyOrdersPopup}
                  user={user}
                />
              ))}

            {multipleDish !== 0 &&
              user.isMultiOrders &&
              orderData?.length > 0 && (
                <div className="myorder-section desktop-comp">
                  <div className="menu-category-heading">
                    <h1>
                      <span>{t('my')}</span> {t('order')}
                    </h1>
                  </div>
                  <div className="myorder-items-info">
                    <div className="order-items-list">
                      {orderData?.length ? (
                        orderData?.map((el, index) => (
                          <MyOrderItem
                            key={el.id}
                            data={el}
                            img={el?.dish?.image}
                            title={el?.dish?.name}
                            subText={el?.dish?.description}
                            price={el?.dish?.price}
                            quant={el?.quantity}
                            category={enums.display(
                              'DishCategory',
                              el?.dish?.dishCategoryId
                            )}
                            update={update}
                            multipleDish={multipleDish && user.isMultiOrders}
                            index={index}
                            mealDishArray={mealDishArray && mealDishArray}
                            setMealDishArray={setMealDishArray}
                            setUpdate={setUpdate}
                            orderId={mealDetails?.orderId}
                            removeOrder={removeOrder}
                            paymentOption={
                              paymenttypeData?.selectedPaymentOptions
                            }
                            setCafeteriaiMealDish={setCafeteriaiMealDish}
                            t={t}
                            setMyOrders={setMyOrders}
                            setMyOrdersPopup={setMyOrdersPopup}
                            user={user}
                          />
                        ))
                      ) : (
                        <div className="no-dishes">
                          <h6>{t('chooseADishToContinue')}</h6>
                        </div>
                      )}
                    </div>
                    {(user.roleId === 4 ||
                      user.roleId === 6 ||
                      user.roleId === 8) &&
                      totalPrice !== 0 && (
                        <div className="myorder-amt-section">
                          <p>{t('totalAmount')}</p>
                          <h5>
                            {paymenttypeData?.selectedPaymentOptions == 5
                              ? // &&
                                // checkCreditPoints === 0
                                ''
                              : '₪'}
                            {totalPrice ? totalPrice : 0}
                          </h5>
                        </div>
                      )}
                  </div>
                  <div
                    className={`order-btn ${
                      orderData?.length ? 'enable-btn' : 'disable-btn'
                    }`}
                  >
                    <Button
                      className={`continue-btn`}
                      onClick={
                        extrasData?.length > 0
                          ? handleExtrasMeal
                          : MakeOrderwithoutExtras
                      }
                    >
                      {t('continue')} <img src={arrowRight} alt="icon" />
                    </Button>
                  </div>
                </div>
              )}
            {multipleDish !== 0 && user.isMultiOrders && (
              <div className="view-order-btn" onClick={handleMyOrders}>
                <h5>
                  <span>{orderData?.length}</span>
                  {t('viewOrder')}
                </h5>
                <div className="myorderBtn-total">
                  {paymenttypeData?.selectedPaymentOptions == 5 || !totalPrice
                    ? // &&
                      // checkCreditPoints === 0
                      ''
                    : '₪'}
                  {totalPrice ? totalPrice : ''}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <AddExtrasPopup
        extrasMeal={extrasMeal}
        extrasData={extrasData && extrasData}
        totalPrice={totalPrice && totalPrice}
        extrasPrice={extrasPrice}
        setExtrasPrice={setExtrasPrice}
        handleExtrasMeal={handleExtrasMeal}
        setAddedExtras={setAddedExtras}
        addedExtras={addedExtras}
        setIsSkip={setIsSkip}
        setValue={setValue}
        value={value}
        setTotalPrice={setTotalPrice}
        skipAddOrder={skipAddOrder}
        isSkip={isSkip}
        AddOrderNew={AddOrderNew}
        t={t}
        paymentOption={paymenttypeData?.selectedPaymentOptions}
      />
      {mealData && Array.isArray(mealData) && mealData.length > 0 ? (
        <OrderPlacedPopup
          orderPlaced={orderPlaced}
          handleOrderPlaced={handleOrderPlaced}
          details={details}
          cafeteriaiMealDish={cafeteriaiMealDish}
          setCafeteriaiMealDish={setCafeteriaiMealDish}
          t={t}
          currentOrder={currentOrder}
          paymentOption={paymenttypeData?.selectedPaymentOptions}
          paymentKitchenId={paymentKitchenId}
          title={
            mealData && Array.isArray(mealData) && mealData.length > 0
              ? mealData[0]?.meal?.title
              : ''
          }
          description={
            mealData && Array.isArray(mealData) && mealData.length > 0
              ? mealData[0]?.meal?.description
              : ''
          }
          notes={
            mealData && Array.isArray(mealData) && mealData.length > 0
              ? mealData[0]?.meal?.notes
              : ''
          }
        />
      ) : (
        ''
      )}

      <MyOrdersMobilePopup
        myOrders={myOrders}
        orderData={orderData}
        handleMyOrders={handleMyOrders}
        multipleDish={multipleDish && user.isMultiOrders}
        mealDishArray={mealDishArray}
        setMealDishArray={setMealDishArray}
        totalPrice={totalPrice}
        handleExtrasMeal={handleExtrasMeal}
        MakeOrderwithoutExtras={MakeOrderwithoutExtras}
        extrasData={extrasData}
        t={t}
        paymenttypeData={paymenttypeData}
        checkCreditPoints={checkCreditPoints}
        setCafeteriaiMealDish={setCafeteriaiMealDish}
        setUpdate={setUpdate}
        orderId={mealDetails?.orderId}
        removeOrder={removeOrder}
        setMyOrders={setMyOrders}
        setMyOrdersPopup={setMyOrdersPopup}
        ordersPopup={ordersPopup}
        user={user}
      />

      {paymentPopup && (
        <PaymentMethod
          paymentPopup={paymentPopup}
          t={t}
          handleClose={handleClose}
          skipAddOrderPayment={skipAddOrderPayment}
          handlePayment={handlePayment}
          paymentMethods={paymentMethods}
          cancellationTime={cancellationTime}
        />
      )}
      <DishPopup
        dishPopup={dishPopup}
        handleDishPopup={handleDishPopup}
        orderDetails={orderDetails}
        myOrder={myOrder}
        t={t}
        removeOrder={removeOrder}
        multipleDish={multipleDish && user.isMultiOrders}
        setUpdate={setUpdate}
        mealDishArray={mealDishArray}
        setMealDishArray={setMealDishArray}
        setCafeteriaiMealDish={setCafeteriaiMealDish}
        orderData={orderData}
        paymentOption={paymenttypeData?.selectedPaymentOptions}
        user={user}
        update={update}
        onCreateOrder={
          extrasData?.length > 0 ? handleExtrasMeal : MakeOrderwithoutExtras
        }
        filterMeal={filterMealState}
        editPerm={editPerm}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  MealTypes: state.app.enums.MealType,
  currentLanguage: state.languages.current,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getPaymentPreferences,
  getEmployeeLobbyData,
};
export default connect(mapStateToProps, mapDispatchToProps)(YourDish);

const MyOrdersMobilePopup = ({
  myOrders,
  orderData,
  multipleDish,
  handleMyOrders,
  mealDishArray,
  setMealDishArray,
  totalPrice,
  handleExtrasMeal,
  MakeOrderwithoutExtras,
  extrasData,
  t,
  paymenttypeData,
  checkCreditPoints,
  setCafeteriaiMealDish,
  setUpdate,
  orderId,
  removeOrder,
  setMyOrders,
  ordersPopup,
  setMyOrdersPopup,
  user,
}) => {
  return (
    <>
      <Modal
        show={myOrders}
        onHide={handleMyOrders}
        className={`myOrders-popup ${ordersPopup ? 'hide-modal' : ''}`}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="myorder-section">
            <div className="menu-category-heading">
              <h1>
                <span>{t('my')}</span> {t('order')}
              </h1>
            </div>
            <div className="myorder-items-info">
              <div className="order-items-list">
                {orderData?.length ? (
                  orderData?.map((el, index) => (
                    <MyOrderItem
                      key={el.id}
                      data={el}
                      img={el.dish.image}
                      title={el.dish.name}
                      subText={el.dish.description}
                      price={el.dish.price}
                      multipleDish={multipleDish && user.isMultiOrders}
                      index={index}
                      mealDishArray={mealDishArray && mealDishArray}
                      setMealDishArray={setMealDishArray}
                      paymentOption={paymenttypeData?.selectedPaymentOptions}
                      setCafeteriaiMealDish={setCafeteriaiMealDish}
                      setUpdate={setUpdate}
                      orderId={orderId}
                      removeOrder={removeOrder}
                      t={t}
                      setMyOrdersPopup={setMyOrdersPopup}
                      user={user}
                    />
                  ))
                ) : (
                  <div className="no-dishes">
                    <h6>{t('chooseADishToContinue')}</h6>
                  </div>
                )}
              </div>
              {totalPrice ? (
                <div className="myorder-amt-section">
                  <p>{t('totalAmount')}</p>
                  <h5>
                    {paymenttypeData?.selectedPaymentOptions == 5
                      ? // &&
                        // checkCreditPoints === 0
                        ''
                      : '₪'}
                    {totalPrice ? totalPrice : 0}
                  </h5>
                </div>
              ) : (
                ''
              )}
            </div>
            <div
              className={`order-btn ${
                orderData?.length ? 'enable-btn' : 'disable-btn'
              }`}
            >
              <Button
                className={`continue-btn`}
                onClick={
                  extrasData?.length > 0
                    ? handleExtrasMeal
                    : MakeOrderwithoutExtras
                }
              >
                {t('continue')} <img src={arrowRight} alt="icon" />
              </Button>
            </div>
            {/* <Button
              className={`continue-btn`}
              onClick={
                extrasData?.length > 0
                  ? handleExtrasMeal
                  : MakeOrderwithoutExtras
              }
            >
              {t('continue')} <img src={arrowRight} alt="icon" />
            </Button> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
