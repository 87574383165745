import moment from 'moment';
import i18n from '../services/i18n';
import AppConfig from '../constants/Config';

const HebrewChars = new RegExp('^[\u0590-\u05FF ]+$');
const AlphaNumericChars = new RegExp('^[a-zA-Z0-9-]+$');
const EnglishChars = new RegExp('^[a-zA-Z-]+$');
const LegalChars = new RegExp('^[0-9-\u0590-\u05FF ]+$');
const IPV4Regex =
  /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm;

const isNumber = (value) => typeof value === 'number';
const isPositiveNumber = (value) => {
  return parseFloat(value) > 0 ? undefined : i18n.t('positiveNumber');
};

const isNotNegativeNumber = (value) => {
  if (parseFloat(value) === 0) return undefined;
  return isPositiveNumber(value);
};

const isIDNumber = (value) =>
  value && !/^[0-9]{9}$/i.test(value) ? i18n.t('invalidID') : undefined;
const isValidIsraeliID = (id) => {
  id = String(id).trim();
  if (id.length > 9 || id.length < 5 || isNaN(id))
    return 'מספר תעודת זהות לא תקין';
  // Pad string with zeros up to 9 digits
  id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
  return /\d{9}/.test(id) &&
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
      0
    ? undefined
    : 'תעודת זהות לא תקינה';
};
const isVerificationCode = (value) =>
  value && !/^[0-9]{6}$/i.test(value)
    ? i18n.t('isVerificationCode')
    : undefined;
const isFullName = (value) =>
  value && !/^([א-ת'-.]{2,}( {1}[א-ת'-.]{2,})+)$/i.test(value)
    ? 'שם לא תקין'
    : undefined;
const isPhoneNumber = (value) =>
  value && !/^([0-9]{3}-?[0-9]{3}-?[0-9]{4})$/i.test(value)
    ? i18n.t('invalidPhoneNumber')
    : undefined;
const isAlpha = (value) =>
  value && !HebrewChars.test(value) && !EnglishChars.test(value)
    ? i18n.t('enterNameInHebrew')
    : undefined;
const isAddress = (value) =>
  value && !LegalChars.test(value) ? 'השדה יכול להכיל רק אותיות' : undefined;
const required = (value) => (value ? undefined : i18n.t('requiredField'));
const checkAppointmentHour = (value, availableAppointments) => {
  if (required(value)) {
    return required(value);
  }
  if (!availableAppointments.includes(value.valueOf())) {
    return 'נא לבחור תאריך פנוי';
  }
  return undefined;
};
/*const checkAppointmentHour = (value, allValues, props) => {
    if (!props.availableHours.includeTimes.includes(value.valueOf())) {
        return 'נא לבחור תאריך פנוי'
    }
    return undefined
}*/
const multiRequired = (value) =>
  value
    ? value.length === 0
      ? 'לפחות בחירה אחת נדרשת'
      : undefined
    : 'שדה נדרש';
const yesOrNoRequired = (value) =>
  value === undefined ? 'אנא בחר שיטת תשלום' : undefined;
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? i18n.t('invalidEmail')
    : undefined;
const maxLength = (max) => (value) => {
  return value && value.length < max
    ? `מינימום סיסמה באורך 8 תווים`
    : undefined;
};

const checkCars = (value, allValues, isEdit) => {
  if ((isEdit && allValues.cars === undefined) || allValues.cars.length === 0) {
    return 'נא להזין לפחות רכב אחד';
  }
  return undefined;
};

const timeSpan = (value) => {
  if (value) {
    const { from, to } = value || {};
    if (
      !isNumber(from.hour) ||
      !isNumber(from.minutes) ||
      !isNumber(to.hour) ||
      !isNumber(to.minutes)
    ) {
      return 'Please fill all from and to fields';
    }
    const fromTime = moment()
      .add(from.hour, 'hour')
      .add(from.minutes, 'minutes');
    const toTime = moment().add(to.hour, 'hour').add(to.minutes, 'minutes');
    if (fromTime.isAfter(toTime)) {
      return 'Invalid time, open time must be before close time';
    }
    return undefined;
  }
};
const passwordValidator = (value) => {
  if (value && !AppConfig.passwordRegExp.test(value)) {
    // return `מינימום סיסמה באורך 8 תווים`;
    //  return i18n.t('passMinLength');
    return i18n.t('passwordSecurity');
  } else if (!value) {
    return '';
  }

  return undefined;
};

const passwordValidatorNew = (value) => {
  if (value && !AppConfig.passwordRegExp.test(value)) {
    // return `מינימום סיסמה באורך 8 תווים`;
    //  return i18n.t('passMinLength');
    return i18n.t('passwordSecurity');
  } else if (!value) {
    return '';
  }

  return undefined;
};

const updatePasswordValidator = (values) => {
  let errors = {};
  let isValid = true;
  if (
    !values.oldPassword ||
    !values.newPassword ||
    !values.newPasswordConfirm
  ) {
    isValid = false;
    errors = {
      oldPassword: !values.oldPassword && 'This field is required',
      newPassword: !values.newPassword && 'This field is required',
      newPasswordConfirm:
        !values.newPasswordConfirm && 'This field is required',
    };
  }
  if (
    //  passwordValidatorNew(values.oldPassword) ||
    passwordValidatorNew(values.newPassword) ||
    passwordValidatorNew(values.newPasswordConfirm)
  ) {
    isValid = false;
    errors = {
      oldPassword: errors?.oldPassword,
      //  passwordValidatorNew(values.oldPassword) || errors?.oldPassword,
      newPassword:
        passwordValidatorNew(values.newPassword) || errors?.newPassword,
      newPasswordConfirm:
        passwordValidatorNew(values.newPasswordConfirm) ||
        errors?.newPasswordConfirm,
    };
  }
  return { isValid, error: errors };
};

const passwordsMatch = (value, allValues) => {
  if (allValues.newPassword !== allValues.newPasswordConfirm) {
    return i18n.t('passwordsDontMatch');
  } else if (value === undefined || value === '') {
    return '';
  }
  return undefined;
};

const normalizeNumber = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const normalizeCar = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}`;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}-${onlyNums.slice(
      5,
      7
    )}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    8
  )}`;
};
const validatePhoneNum = (value) =>
  value && !/^(\+?[09][0-9,-]{8,12})$/.test(value)
    ? i18n.t('invalidPhoneNumber')
    : undefined;

const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return (
    onlyNums.slice(0, 3) +
    '-' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  );
};

const normalizeCode = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 6) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 6)}`;
};

const normalizeCreditCard = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 4) {
      return onlyNums + ' ';
    }
    if (onlyNums.length === 8) {
      return onlyNums.slice(0, 4) + ' ' + onlyNums.slice(4) + ' ';
    }
    if (onlyNums.length === 12) {
      return (
        onlyNums.slice(0, 4) +
        ' ' +
        onlyNums.slice(4, 8) +
        ' ' +
        onlyNums.slice(8) +
        ' '
      );
    }
  }

  if (onlyNums.length <= 4) {
    return onlyNums;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8
    )}`;
  }
  return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
    8,
    12
  )} ${onlyNums.slice(12, 16)}`;
};

const digitsOnly = (value) => value.replace(/[^\d]/g, '');

const cvvNumber = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3 || onlyNums.length === 4) {
      return onlyNums;
    }
  }
  if (onlyNums.length <= 4) {
    return onlyNums;
  }
  return onlyNums.slice(0, 4);
};

const citizenIdNumber = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 9) {
      return onlyNums;
    }
  }
  if (onlyNums.length <= 9) {
    return onlyNums;
  }
  return onlyNums.slice(0, 9);
};

const isPositiveNumberOrEmail = (value) => {
  const isPositive = isPositiveNumber(value);
  const isEmail = email(value);
  if (isPositive === undefined || isEmail === undefined) {
    return undefined;
  } else {
    return i18n.t('needValidEmployeeIdOrEmail');
  }
};

const validateGuestUser = (values, type) => {
  const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  let isValid = true;
  if (!values.firstName) {
    errors.firstName = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!values.lastName) {
    errors.lastName = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (values.email !== '' && values.email && !EmailRegex.test(values.email)) {
    errors.email = 'Invalid Email';
    isValid = false;
  }

  let today = new Date();
  let todatDate =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  /*if (!values.hireFrom) {
    errors.hireFrom = 'This field is required';
    isValid = false;
  }

  if (!values.hireTill) {
    errors.hireTill = 'This field is required';
    isValid = false;
  }*/
  if (!values.mealTypeId[0]) {
    errors.mealTypeId = i18n.t('fieldIsRequired');
    isValid = false;
  }

  if (!values.kitchensIds || !values.kitchensIds[0]) {
    errors.kitchensIds = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (values.phone) {
    const PhoneRegexp = /^(\+?[09][0-9,-]{8,12})$/;
    if (!PhoneRegexp.test(typeof values === 'number' ? values : values.phone)) {
      errors.phone = 'Invalid Phone Number';
      isValid = false;
    }
  }
  if (!values.phone) {
    errors.phone = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (
    values.password !== '' &&
    values.password &&
    !AppConfig.passwordRegExp.test(values.password)
  ) {
    let str = /^[A-Za-z0-9@$%*#?&^]*$/;
    var respas = values.password.split('');
    let error = '';
    respas.forEach((item) => {
      if (!str.test(item)) {
        error += item;
      }
    });

    errors.password =
      i18n.t('character') +
      ' ' +
      error +
      ' ' +
      i18n.t('isNotVaild') +
      ' ' +
      i18n.t('passwordSecurity');
    isValid = false;
  }
  if (!values.password) {
    errors.password = '';
    isValid = true;
  }
  return { isValid: isValid, errors: errors };
};

const validateEmployee = (values, type, empType = 'emp') => {
  const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  let isValid = true;
  if (
    values === '' &&
    (type === 'fName' ||
      type === 'lName' ||
      type === 'employee' ||
      type === 'firstName' ||
      (type === 'email' && empType === 'emp'))
  ) {
    errors[type] = i18n.t('fieldIsRequired');
    isValid = false;
  } else if (type === 'email' && values && !EmailRegex.test(values)) {
    errors.email = 'Invalid Email';
    isValid = false;
  } else if (type === 'phone' && values !== '') {
    const PhoneRegexp = /^(\+?[09][0-9,-]{8,12})$/;
    if (!PhoneRegexp.test(values)) {
      errors.phone = 'Invalid Phone Number';
      isValid = false;
    } else {
      errors.phone = '';
    }
  } else {
    errors[type] = '';
  }
  return { isValid: isValid, error: errors };
};

const validateUserWallet = (values, type) => {
  let errors = {};
  let isValid = true;
  if (values === '' && type === 'amount') {
    errors.amount = i18n.t('fieldIsRequired');
    isValid = false;
  } else {
    errors.amount = '';
  }
  return { isValid: isValid, error: errors };
};
const validateUserWalletNew = (values) => {
  let errors = {};
  let isValid = true;
  if (!values.amount) {
    errors.amount = i18n.t('fieldIsRequired');
    isValid = false;
  }
  return { isValid: isValid, error: errors };
};

const validateGuestEmployee = (values) => {
  const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  let isValid = true;
  if (!values.firstName) {
    errors.firstName = i18n.t('fieldIsRequired');
    isValid = false;
  }

  if (!values.lastName) {
    errors.lastName = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (values.email !== '' && values.email && !EmailRegex.test(values.email)) {
    errors.email = 'Invalid Email';
    isValid = false;
  }
  if (values.phone) {
    const PhoneRegexp = /^(\+?[09][0-9,-]{8,12})$/;
    if (!PhoneRegexp.test(typeof values === 'number' ? values : values.phone)) {
      errors.phone = 'Invalid Phone Number';
      isValid = false;
    }
  }

  // let today = new Date();
  // let todatDate =
  //   today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  // if (!values.hireFrom) {
  //   errors.hireFrom = 'This field is required';
  //   isValid = false;
  // }
  // if (values.hireFrom < todatDate) {
  //   errors.hireFrom = 'Please enter valid date';
  //   isValid = false;
  // }
  // if (!values.hireTill) {
  //   errors.hireTill = 'This field is required';
  //   isValid = false;
  // }
  // if (values.hireTill < todatDate) {
  //   errors.hireTill = 'Please enter valid date';
  //   isValid = false;
  // }
  // if (!values.mealTypeId[0]) {
  //   errors.mealTypeId = 'This field is required';
  //   isValid = false;
  // }

  // if (!values.kitchensIds[0]) {
  //   errors.kitchensIds = 'This field is required';
  //   isValid = false;
  // }

  return { isValid: isValid, errors: errors };
};

const validateGuestUsersNew = (values, isInvitation = false) => {
  const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  let isValid = true;
  if (!values.firstName) {
    errors.firstName = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!values.lastName) {
    errors.lastName = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (
    values.hireFrom &&
    values.hireTill &&
    moment(values.hireFrom).isAfter(moment(values.hireTill))
  ) {
    isValid = false;
    errors.hireTill = 'endLessStart';
  }

  const role = Array.isArray(values?.roleId)
    ? values?.roleId[0]
    : values?.roleId;
  if (role === 6 || role === 7 || role === 8) {
    if (!values.contractorsIds || values.contractorsIds.length === 0) {
      errors.contractorsIds = i18n.t('fieldIsRequired');
      isValid = false;
    }
  }
  if (values.email) {
    if (values.email !== '' && values.email && !EmailRegex.test(values.email)) {
      errors.email = 'Invalid Email';
      isValid = false;
    }
  }

  if (!values.hireFrom) {
    errors.hireFrom = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!values.hireTill) {
    errors.hireTill = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!isInvitation && !values.invitedBy) {
    errors.invitedBy = i18n.t('fieldIsRequired');
    isValid = false;
  }
  let today = new Date();
  let todatDate =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  // if (!values.hireFrom) {
  //   errors.hireFrom = 'This field is required';
  //   isValid = false;
  // }

  /*if (
    values.hireFrom &&
    new Date(values.hireFrom).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0)
  ) {
    errors.hireFrom = 'Please enter valid date';
    isValid = false;
  }*/

  // if (!values.hireTill) {
  //   errors.hireTill = 'This field is required';
  //   isValid = false;
  // }

  /*if (
    values.hireTil &&
    new Date(values.hireTill).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0)
  ) {
    errors.hireTill = 'Please enter valid date';
    isValid = false;
  }*/

  // if (!values.mealTypeId) {
  //   errors.mealTypeId = 'This field is required';
  //   isValid = false;
  // }
  // if (!values.mealTypeId?.[0]) {
  //   errors.mealTypeId = 'This field is required';
  //   isValid = false;
  // }

  if (!values.kitchensIds || !values.kitchensIds[0]) {
    errors.kitchensIds = i18n.t('fieldIsRequired');
    isValid = false;
  }
  console.log('values', values);

  if (!values.mealTypeId || !values.mealTypeId[0]) {
    errors.mealTypeIds = i18n.t('fieldIsRequired');
    isValid = false;
  }
  // if (!values.phone) {
  //   errors.phone = 'This field is required';
  //   isValid = false;
  // }
  if (values.phone) {
    const PhoneRegexp = /^(\+?[09][0-9,-]{8,12})$/;
    if (!PhoneRegexp.test(typeof values === 'number' ? values : values.phone)) {
      errors.phone = 'Invalid Phone Number';
      isValid = false;
    }
  }

  return { isValid: isValid, errors: errors };
};
const validateGuestnew = (values) => {
  const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  let isValid = true;
  if (!values.firstName) {
    errors.firstName = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!values.lastName) {
    errors.lastName = i18n.t('fieldIsRequired');
    isValid = false;
  }

  if (!values.contractorsIds || values.contractorsIds.length === 0) {
    errors.contractorsIds = i18n.t('fieldIsRequired');
    isValid = false;
  }

  if (values.email) {
    if (values.email !== '' && values.email && !EmailRegex.test(values.email)) {
      errors.email = 'Invalid Email';
      isValid = false;
    }
  }
  if (values.phone) {
    const PhoneRegexp = /^(\+?[09][0-9,-]{8,12})$/;
    if (!PhoneRegexp.test(typeof values === 'number' ? values : values.phone)) {
      errors.phone = 'Invalid Phone Number';
      isValid = false;
    }
  }
};
const validateEmployeenew = (
  values,
  cardNumberLengthForUser,
  employeeNumberLengthForUser,
  companyID
) => {
  let role;
  if ('roleId' in values) {
    role = Array.isArray(values?.roleId) ? values?.roleId[0] : values?.roleId;
  }
  const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  let isValid = true;
  if (!values.firstName) {
    errors.firstName = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!values.lastName) {
    errors.lastName = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (role === 6 || role === 7 || role === 8) {
    if (!values.contractorsIds || values.contractorsIds.length === 0) {
      errors.contractorsIds = i18n.t('fieldIsRequired');
      isValid = false;
    }
  }
  if (companyID == 5 && role === 4 && !values.employeeId) {
    errors.employeeNo = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!values.email) {
    errors.email = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (values.email && values.email !== '' && !EmailRegex.test(values.email)) {
    errors.email = 'Invalid Email';
    isValid = false;
  }
  if (values.phone) {
    const PhoneRegexp = /^(\+?[09][0-9,-]{8,12})$/;
    if (!PhoneRegexp.test(typeof values === 'number' ? values : values.phone)) {
      errors.phone = 'Invalid Phone Number';
      isValid = false;
    }
  }
  if (
    values.cardId &&
    cardNumberLengthForUser &&
    values.cardId.length > cardNumberLengthForUser
  ) {
    errors.card = 'Invalid card number';
    isValid = false;
  }
  if (
    values.employeeId &&
    employeeNumberLengthForUser &&
    values.employeeId.length > employeeNumberLengthForUser
  ) {
    errors.employeeNo = 'Invalid employee number';
    isValid = false;
  }
  // if (values.employeeId && values.employeeId.length != 5) {
  //   errors.employeeId = 'Employee number should be in 5 digits.';
  //   isValid = false;
  // }
  if (
    values.password !== '' &&
    values.password &&
    !AppConfig.passwordRegExp.test(values.password)
  ) {
    let str = /^[A-Za-z0-9@$%*#?&^]*$/;
    var respas = values.password.split('');
    let error = '';
    respas.forEach((item) => {
      if (!str.test(item)) {
        error += item;
      }
    });

    errors.password =
      i18n.t('character') +
      ' ' +
      error +
      ' ' +
      i18n.t('isNotVaild') +
      ' ' +
      i18n.t('passwordSecurity');
    isValid = false;
  }
  if (values.kitchensIds.length === 0) {
    errors.kitchensIds = 'Kitchen is required';
    isValid = false;
  }
  // if (!values.password) {
  //   errors.password = '';
  //   isValid = true;
  // }
  /*if (
    (values.password === '' || !values.password) &&
    values.userStatusId === 3
  ) {
    errors.password = 'This field is required';
    isValid = false;
  }*/
  return { isValid: isValid, errors: errors };
};

const validateUsers = (
  values,
  type,
  cardNumberLengthForUser,
  employeeNumberLengthForUser,
  userType
) => {
  const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  let isValid = true;
  if (
    values === '' &&
    (type === 'fName' ||
      type === 'lName' ||
      (type === 'email' && userType === 'emp'))
  ) {
    errors[type] = i18n.t('fieldIsRequired');
    isValid = false;
  } else if (type === 'email' && values !== '' && !EmailRegex.test(values)) {
    errors.email = 'Invalid Email';
    isValid = false;
  } else if (type === 'phone' && values !== '') {
    const PhoneRegexp = /^(\+?[09][0-9,-]{8,12})$/;
    if (!PhoneRegexp.test(values)) {
      errors.phone = 'Invalid Phone Number';
      isValid = false;
    } else if (
      type === 'card' &&
      cardNumberLengthForUser &&
      values.length > cardNumberLengthForUser
    ) {
      errors.card = 'Invalid card number';
      isValid = false;
    } else if (
      type === 'employeeId' &&
      employeeNumberLengthForUser &&
      values.length > employeeNumberLengthForUser
    ) {
      errors.employeeNo = 'Invalid employee number';
      isValid = false;
    } else {
      errors.phone = '';
    }
  }
  // else if (type === 'employeeId' && values !== '') {
  //   if (values.length != 5) {
  //     errors.employeeId = 'Employee number should be in 5 digits.';
  //     isValid = false;
  //   } else {
  //     errors.employeeId = '';
  //   }
  // }
  else {
    errors[type] = '';
  }
  return { isValid: isValid, error: errors };
};

const validateSlotType = (values) => {
  let errors = {};
  let isValid = true;
  if (!values.from) {
    errors.from = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!values.to) {
    errors.to = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!values.maxReservations) {
    errors.maxReservations = i18n.t('fieldIsRequired');
    isValid = false;
  }
  return { isValid: isValid, errors: errors };
};

const validateMealType = (details) => {
  let errors = {};
  let isValid = true;
  details?.translations?.map((el, i) => {
    if (!el.value) {
      errors.translations = i18n.t('fieldIsRequired');
      isValid = false;
    }
  });
  if (!details.from) {
    errors.from = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!details.to) {
    errors.to = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!details.externalId) {
    errors.externalId = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (!details.orderIndex) {
    errors.orderIndex = i18n.t('fieldIsRequired');
    isValid = false;
  }
  if (details.orderIndex >= 2147483647) {
    errors.orderIndex = 'This value is too big';
    isValid = false;
  }
  //  else if (details.to > details.from) {
  //   errors.to = 'End time must be greater than Start Time';
  // }
  return { isValid: isValid, errors: errors };
};

const checkDate = (beforeTime, afterTime) => {
  const beforeArr = beforeTime
    .trim()
    .split(':')
    .map((el) => el * 1);
  const afterArr = afterTime
    .trim()
    .split(':')
    .map((el) => el * 1);
  if (afterArr[0] > beforeArr[0]) {
    return true;
  } else if (afterArr[0] === beforeArr[0] && afterArr[1] > beforeArr[1]) {
    return true;
  } else if (afterArr[0] < beforeArr[0]) {
    return false;
  } else if (afterArr[1] > beforeArr[1]) {
    return false;
  } else if (afterArr[1] < beforeArr[1]) {
    return false;
  } else {
    return false;
  }
};

const validateMealslot = (values, type) => {
  let errors = {};
  let isValid = true;
  if (type == 'from' && values.from === '') {
    errors[type] = 'This field is required';
    isValid = false;
  } else if (type == 'to' && values.to === '') {
    errors[type] = 'This field is required';
    isValid = false;
  } else if (
    values.from !== '' &&
    values.to !== '' &&
    !checkDate(values.from, values.to)
  ) {
    errors.to = 'End time should be after start time.';
    isValid = false;
  } else if (values.from === '' || values.to === '') {
    isValid = false;
    errors = {
      ...errors,
      from: values.from === '' ? 'Start time is required' : '',
      to: values.to === '' ? 'End time is required' : '',
    };
  }

  return { isValid: isValid, error: errors };
};

const isIPv4 = (value) => {
  return !!value.match(IPV4Regex);
};

const validateContractor = (values) => {
  const EmailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errors = {};
  let isValid = true;
  if (!values.name) {
    errors.name = 'requiredField';
    isValid = false;
  }
  if (!values.contactPersonName) {
    errors.contactPersonName = 'requiredField';
    isValid = false;
  }
  if (!values.companyNumber) {
    errors.companyNumber = 'requiredField';
    isValid = false;
  }
  if (!values.contactEmail || values.contactEmail === '') {
    errors.contactEmail = 'requiredField';
    isValid = false;
  }
  if (!EmailRegex.test(values.contactEmail)) {
    errors.contactEmail = 'invalidEmail';
    isValid = false;
  }
  if (values.contactPhone) {
    const PhoneRegexp = /^(\+?[09][0-9,-]{8,12})$/;
    if (!PhoneRegexp.test(values.contactPhone)) {
      errors.contactPhone = 'invalidPhoneNumber';
      isValid = false;
    }
  }

  return { isValid: isValid, errors: errors };
};

const validateAddDish = (values) => {
  let errors = {};
  let isValid = true;
  let getValues = values?.translations?.slice(0, 2);

  if (
    !getValues?.every(
      (el) =>
        el?.name && el?.name !== '' && el?.description && el?.description !== ''
    )
  ) {
    getValues?.forEach((el, index) => {
      if (!el?.name) {
        errors[`translations[${index}].name`] = 'Name is required';
      }
      if (!el?.description) {
        errors[`translations[${index}].description`] =
          'Description is required';
      }
    });
    isValid = false;
  } else {
    isValid = true;
    errors = {};
  }
  return { isValid: isValid, errors: errors };
};

const validateEditDish = (translations) => {
  let errors = {};
  let isValid = true;

  if (
    !translations.every(
      (el) =>
        el?.name && el?.name !== '' && el?.description && el?.description !== ''
    )
  ) {
    translations.forEach((el, index) => {
      if (!el?.name) {
        errors[`translations[${index}].name`] = 'Name is required';
      }
      if (!el?.description) {
        errors[`translations[${index}].description`] =
          'Description is required';
      }
    });
    isValid = false;
  } else {
    isValid = true;
    errors = {};
  }
  return { isValid: isValid, errors: errors };
};

const validateKitchenDevice = (values, type) => {
  let errors = {};
  let isValid = true;
  if (values.displayName === '') {
    errors.displayName = 'This field is required';
    isValid = false;
  }
  if (values.deviceType === '') {
    errors.deviceType = 'This field is required';
    isValid = false;
  }
  if (values.location === '') {
    errors.location = 'This field is required';
    isValid = false;
  }
  if (values.kitchenId === '') {
    errors.kitchenId = 'This field is required';
    isValid = false;
  }
  return { isValid: isValid, errors: errors };
};

const validateLanguage = (values, type) => {
  let errors = {};
  let isValid = true;
  if (values.displayName === '') {
    errors.displayName = 'This field is required';
    isValid = false;
  }
  if (values.name === '') {
    errors.name = 'This field is required';
    isValid = false;
  }
  if (values.shortCode === '') {
    errors.shortCode = 'This field is required';
    isValid = false;
  }
  if (values.isRTL === '') {
    errors.isRTL = 'This field is required';
    isValid = false;
  }
  if (values.languageStatusId === '') {
    errors.languageStatusId = 'This field is required';
    isValid = false;
  }
  return { isValid: isValid, errors: errors };
};

export {
  isIDNumber,
  isValidIsraeliID,
  isVerificationCode,
  isPhoneNumber,
  isAlpha,
  isFullName,
  isAddress,
  isNumber,
  isPositiveNumber,
  required,
  checkAppointmentHour,
  multiRequired,
  email,
  maxLength,
  timeSpan,
  passwordsMatch,
  yesOrNoRequired,
  checkCars,
  passwordValidator,
  normalizeNumber,
  normalizeCar,
  normalizePhone,
  validatePhoneNum,
  normalizeCode,
  normalizeCreditCard,
  cvvNumber,
  citizenIdNumber,
  digitsOnly,
  isPositiveNumberOrEmail,
  isNotNegativeNumber,
  validateEmployee,
  validateGuestUser,
  validateUsers,
  validateEmployeenew,
  validateGuestUsersNew,
  isIPv4,
  validateMealType,
  validateSlotType,
  validateMealslot,
  checkDate,
  validateContractor,
  updatePasswordValidator,
  validateAddDish,
  validateEditDish,
  validateUserWallet,
  validateUserWalletNew,
  validateKitchenDevice,
  validateLanguage,
  validateGuestnew,
};
